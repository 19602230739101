import moment from 'moment';
import {EmployeesFilterValues} from '../components/EmployeesFilter';
import EmployeeService from '../services/Employee';
import EmployeeStatus from './EmployeeStatus';
import Group from './Group';
import Notification from './Notification';
import Role from './Role';
import Vacation from './Vacation';
import VacationRequest from './VacationRequest';


export default interface Employee {
    id: number;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    mobile_phone: string;
    employment_date: moment.Moment;
    status: EmployeeStatus;
    avatar?: string;
    groups_count: number;
    created_at: moment.Moment;
    updated_at: moment.Moment;
    roles_count: number;
}

export interface EmployeeComponentWrapperStateToProps {
    me?: Employee;
    employees: Employee[];
    employeesCount: number;
    employeeRoles: { [employeeId: number]: Role[] };
    employeeNotifications: Notification[];
    employeeVacationRequests: VacationRequest[];
    employeeGroups: { [employeeId: number]: Group[] };
    employeeVacations: { [employeeId: number]: Vacation[] };
}

export interface EmployeeComponentWrapperDispatchToProps {
    receiveMe: () => void;
    receiveEmployees: (limit?: number, offset?: number, search?: string, ordering?: string, filter?: EmployeesFilterValues) => void;
    receiveEmployeeRoles: (employeeId: number) => void;
    receiveEmployeeNotifications: () => void;
    receiveEmployeeVacationRequests: () => void;
    receiveEmployeeGroups: (employeeId: number) => void;
    receiveEmployeeVacations: (employeeId: number) => void;
}

export const employeeComponentWrapperMapStateToProps = (state): EmployeeComponentWrapperStateToProps => ({
    me: state.Employee.me,
    employees: state.Employee.employees,
    employeesCount: state.Employee.employeesCount,
    employeeRoles: state.Employee.roles,
    employeeNotifications: state.Employee.notifications,
    employeeVacationRequests: state.Employee.vacationRequests,
    employeeGroups: state.Employee.groups,
    employeeVacations: state.Employee.vacations,
});

export const employeeComponentWrapperMapDispatchToProps: EmployeeComponentWrapperDispatchToProps = {
    receiveMe: EmployeeService.receiveMe,
    receiveEmployees: EmployeeService.receive,
    receiveEmployeeGroups: EmployeeService.receiveGroups,
    receiveEmployeeNotifications: EmployeeService.receiveNotifications,
    receiveEmployeeRoles: EmployeeService.receiveRoles,
    receiveEmployeeVacationRequests: EmployeeService.receiveVacationRequests,
    receiveEmployeeVacations: EmployeeService.receiveVacations,
};

export interface EmployeeComponentWrapper {
    getEmployeeRoles: (employeeId: number) => Role[];
    getEmployeeNotifications: () => Notification[];
    getEmployeeVacationRequests: (employeeId: number) => VacationRequest[];
    getEmployeeGroups: (employeeId: number) => Group[];
    getEmployeeVacations: (employeeId: number) => Vacation[];
    isAdmin: (employeeId: number) => boolean;
}
