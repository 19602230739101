import {connect} from 'react-redux';
import ProfileDialog, {ProfileDialogDispatchToProps, ProfileDialogStateToProps} from '../components/ProfileDialog';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import {groupComponentWrapperMapDispatchToProps, groupComponentWrapperMapStateToProps} from '../models/Group';
import EmployeeService from '../services/Employee';


const mapStateToProps = (state): ProfileDialogStateToProps => ({
    ...employeeComponentWrapperMapStateToProps(state),
    ...groupComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: ProfileDialogDispatchToProps = {
    addEmployeeRole: EmployeeService.addRole,
    removeEmployeeRole: EmployeeService.removeRole,
    ...employeeComponentWrapperMapDispatchToProps,
    ...groupComponentWrapperMapDispatchToProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDialog);
