import {connect} from 'react-redux';
import Notifications, {NotificationsStateToProps} from '../components/Notifications';


const mapStateToProps = (state): NotificationsStateToProps => ({
    vacationRequests: state.VacationRequest.vacationRequests,
    notifications: state.Notification.notifications,
});

export default connect(mapStateToProps, null)(Notifications);
