import {connect} from 'react-redux';
import SettingsPage, {SettingsPageDispatchToProps, SettingsPageStateToProps} from '../components/SettingsPage';
import SettingsService from '../services/Settings';


const mapStateToProps = (state): SettingsPageStateToProps => ({
    settings: state.Settings.settings,
});

const mapDispatchToProps: SettingsPageDispatchToProps = {
    receiveSettings: SettingsService.receive,
    updateSettings: SettingsService.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
