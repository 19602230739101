import axios from 'axios';
import {Dispatch} from 'redux';
import {receiveRolesFinished, receiveRolesStarted} from '../actions/Role';
import Role from '../models/Role';
import Utils from '../utils/Utils';
import AuthenticationService from './Authentication';


class RoleService {
    public static receive = (): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveRolesStarted());

            axios.get(Utils.generateApiUrl('roles'), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response): void => {
                    dispatch(receiveRolesFinished(response.data as Role[]));
                })
                .catch(Utils.handleError);
        };
}

export default RoleService;
