import {Link} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import deepEqual from 'deep-equal';
import moment from 'moment';
import React from 'react';
import ProfileDialog from '../containers/ProfileDialog';
import VacationDialog from '../containers/VacationDialog';
import Employee from '../models/Employee';
import Group from '../models/Group';
import Vacation from '../models/Vacation';
import VacationReason from '../models/VacationReason';
import VacationRequestModel from '../models/VacationRequest';


export interface VacationRequestStateToProps {
    me: Employee;
    groups: Group[];
    vacationReasons: VacationReason[];
    employees: Employee[];
}

export interface VacationRequestDispatchToProps {
    receiveMe: () => void;
    approveVacationRequest: (vacationRequest) => void;
    rejectVacationRequest: (vacationRequest, reason) => void;
}

export type VacationRequestProps = VacationRequestStateToProps & VacationRequestDispatchToProps & {
    vacationRequest: VacationRequestModel;
};

export interface VacationRequestState {
    reason: string;
    manageEmployeeRoleDialog: {
        open: boolean;
    };
    vacationDialog: {
        open: boolean;
        vacation?: Vacation;
    };
    disabledApproveReject: boolean;
    anchorElementProfile: HTMLElement;
}

export default class VacationRequest extends React.Component<VacationRequestProps, VacationRequestState> {
    public constructor(props: VacationRequestProps) {
        super(props);

        this.state = {
            reason: '',
            manageEmployeeRoleDialog: {
                open: false,
            },
            vacationDialog: {
                open: false,
            },
            disabledApproveReject: false,
            anchorElementProfile: null,
        };
    }

    public componentDidUpdate(prevProps: Readonly<VacationRequestProps>): void {
        if (!deepEqual(prevProps.vacationRequest, this.props.vacationRequest)) {
            this.setState({
                disabledApproveReject: false,
            });
        }
    }

    public render(): React.ReactElement {
        const duration = moment(this.props.vacationRequest.vacation.end).diff(this.props.vacationRequest.vacation.start, 'days') + 1;
        return (
            <React.Fragment>
                <Card className="notification-card">
                    <CardContent>
                        <Link
                            variant="subtitle2"
                            style={{cursor: 'pointer'}}
                            color="inherit"
                            onClick={(): void => {
                                this.setState({
                                    vacationDialog: {
                                        open: true,
                                        vacation: this.props.vacationRequest.vacation,
                                    },
                                });
                            }}
                        >
                            {`${this.props.vacationRequest.vacation.reason.display_name} Request, duration: ${duration} ${duration === 1 ? 'day' : 'days'}`}
                        </Link>

                        <Typography variant="body2" component="div">
                            <Grid container>
                                <Grid item xs={6} className="notification-row-header">User</Grid>

                                <Grid item xs={6}>
                                    <Link
                                        style={{cursor: 'pointer', overflowWrap: 'break-word'}}
                                        variant="subtitle2"
                                        href={`/annual?employees=${this.props.vacationRequest.vacation.employee.id}&vacation_statuses=1,3&vacation_reasons=1`}
                                    >
                                        {`${this.props.vacationRequest.vacation.employee.first_name}
                                        ${this.props.vacationRequest.vacation.employee.last_name}`}
                                    </Link>
                                </Grid>

                                <Grid item xs={6} className="notification-row-header">Start</Grid>

                                <Grid item xs={6}>
                                    {moment(this.props.vacationRequest.vacation.start).format('YYYY/MM/DD')}
                                </Grid>

                                <Grid item xs={6} className="notification-row-header">End</Grid>

                                <Grid item xs={6}>
                                    {moment(this.props.vacationRequest.vacation.end).format('YYYY/MM/DD')}
                                </Grid>

                                {
                                    this.props.vacationRequest.vacation.comment && (
                                        <React.Fragment>
                                            <Grid
                                                item xs={6}
                                                className="notification-row-header"
                                            >Comment</Grid>
                                            <Grid style={{wordBreak: 'break-word'}} item xs={6}>
                                                {this.props.vacationRequest.vacation.comment}
                                            </Grid>
                                        </React.Fragment>
                                    )
                                }

                                {
                                    moment(this.props.vacationRequest.vacation.employee.employment_date)
                                        .add(6, 'month')
                                        .format('YYYY-MM-DD') >
                                    moment(this.props.vacationRequest.vacation.start).format('YYYY-MM-DD') &&
                                    this.props.vacationRequest.vacation.reason.short_name === 'vacation' && (
                                        <React.Fragment>
                                            <Grid
                                                item xs={6}
                                                className="notification-row-header"
                                            >Note</Grid>
                                            <Grid
                                                item
                                                style={{wordBreak: 'break-word', textDecoration: 'underline'}}
                                                xs={6}
                                            >
                                                {`6 months have not passed since the employment (${moment(this.props.vacationRequest.vacation.employee.employment_date).format('YYYY/MM/DD')})`}
                                            </Grid>
                                        </React.Fragment>
                                    )
                                }

                                <Grid
                                    item xs={6}
                                    className="notification-row-header"
                                >
                                    Created Date
                                </Grid>

                                <Grid item xs={6}>
                                    {moment(this.props.vacationRequest.created_at || this.props.vacationRequest.vacation.created_at).format('YYYY/MM/DD')}
                                </Grid>

                                <Grid item xs={12} style={{paddingTop: '10px'}}>
                                    <TextField
                                        fullWidth
                                        inputProps={{
                                            maxLength: 150,
                                        }}
                                        label="Reason for rejection"
                                        value={this.state.reason}
                                        onChange={(event): void => {
                                            let regex = new RegExp(/ +/, 'g');

                                            this.setState({
                                                reason: event.target.value
                                                    .replace(regex, ' ')
                                                    .trimLeft(),
                                            });
                                        }}
                                        helperText="Reason length should not exceed 150 characters"
                                    />
                                </Grid>
                            </Grid>
                        </Typography>
                    </CardContent>

                    <CardActions>
                        <Button
                            size="small"
                            disableFocusRipple
                            disabled={this.state.disabledApproveReject}
                            variant="contained"
                            color="primary"
                            className="accept-button"
                            fullWidth
                            onClick={
                                (): void => {
                                    this.props.approveVacationRequest(this.props.vacationRequest);

                                    this.setState({
                                        disabledApproveReject: true,
                                    });
                                }
                            }
                        >
                            Approve
                        </Button>

                        <Button
                            size="small"
                            disableFocusRipple
                            disabled={this.state.disabledApproveReject}
                            variant="contained"
                            color="primary"
                            className="reject-button"
                            fullWidth
                            onClick={
                                (): void => {
                                    this.props.rejectVacationRequest(
                                        this.props.vacationRequest,
                                        this.state.reason,
                                    );

                                    this.setState({
                                        disabledApproveReject: this.state.reason.length > 0,
                                    });
                                }
                            }
                        >
                            Reject
                        </Button>
                    </CardActions>
                </Card>

                <ProfileDialog
                    open={this.state.manageEmployeeRoleDialog.open}
                    employee={this.props.vacationRequest.vacation.employee}
                    anchorElement={this.state.anchorElementProfile}
                    onClose={(): void => this.setState({
                        manageEmployeeRoleDialog: {
                            open: false,
                        },
                    })}
                />

                {
                    this.state.vacationDialog.open &&
                    this.state.vacationDialog.vacation &&
                    <VacationDialog
                        {...this.props}
                        open={this.state.vacationDialog.open}
                        vacation={this.state.vacationDialog.vacation}
                        onClose={
                            (): void => {
                                this.setState({
                                    vacationDialog: {
                                        open: false,
                                    },
                                });
                            }
                        }
                    />
                }
            </React.Fragment>
        );
    }
}
