import {Action} from 'redux';
import {GroupFilterValues} from '../components/GroupFilter';
import Employee from '../models/Employee';
import Group from '../models/Group';


export type GroupActionTypes =
    'RECEIVE_GROUPS_STARTED' |
    'RECEIVE_GROUPS_FINISHED' |

    'RECEIVE_GROUP_MEMBERS_STARTED' |
    'RECEIVE_GROUP_MEMBERS_FINISHED' |

    'RECEIVE_FINISHED_WITH_ERROR';

// RECEIVE_GROUPS

export interface ReceiveGroupsStartedAction extends Action<GroupActionTypes> {
    limit?: number;
    offset?: number;
    search?: string;
    ordering?: string;
    filter?: GroupFilterValues;
}

export const receiveGroupsStarted = (limit?: number, offset?: number, search?: string, ordering?: string, filter?: GroupFilterValues): ReceiveGroupsStartedAction => ({
    type: 'RECEIVE_GROUPS_STARTED',
    limit,
    offset,
    search,
    ordering,
    filter,
});

export interface ReceiveGroupsFinishedAction extends Action<GroupActionTypes> {
    groups: Group[];
    count: number;
}

export const receiveGroupsFinished = (groups: Group[], count: number): ReceiveGroupsFinishedAction => ({
    type: 'RECEIVE_GROUPS_FINISHED',
    groups,
    count,
});

// RECEIVE_GROUP_MEMBERS

export interface ReceiveGroupMembersStartedAction extends Action<GroupActionTypes> {
    groupId: number;
}

export const receiveGroupMembersStarted = (groupId: number): ReceiveGroupMembersStartedAction => ({
    type: 'RECEIVE_GROUP_MEMBERS_STARTED',
    groupId,
});

export interface ReceiveGroupMembersFinishedAction extends Action<GroupActionTypes> {
    groupId: number;
    members: Employee[];
}

export const receiveGroupMembersFinished = (groupId: number, members: Employee[]): ReceiveGroupMembersFinishedAction => ({
    type: 'RECEIVE_GROUP_MEMBERS_FINISHED',
    groupId,
    members,
});

// RECEIVE_ERROR

export const receiveGroupsFinishedWithError = (): Action<GroupActionTypes> => ({
    type: 'RECEIVE_FINISHED_WITH_ERROR',
});

export type GroupAction =
    ReceiveGroupsStartedAction &
    ReceiveGroupsFinishedAction &

    ReceiveGroupMembersStartedAction &
    ReceiveGroupMembersFinishedAction;
