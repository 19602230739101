import {Action} from 'redux';
import VacationStatus from '../models/VacationStatus';


export type VacationStatusActionTypes =
    'RECEIVE_VACATION_STATUSES_STARTED' |
    'RECEIVE_VACATION_STATUSES_FINISHED';

export const receiveVacationStatusesStarted = (): Action<VacationStatusActionTypes> => ({
    type: 'RECEIVE_VACATION_STATUSES_STARTED',
});

export interface ReceiveVacationStatusesFinishedAction extends Action<VacationStatusActionTypes> {
    vacationStatuses: VacationStatus[];
}

export const receiveVacationStatusesFinished = (vacationStatuses: VacationStatus[]): ReceiveVacationStatusesFinishedAction => ({
    type: 'RECEIVE_VACATION_STATUSES_FINISHED',
    vacationStatuses,
});

export type VacationStatusAction =
    ReceiveVacationStatusesFinishedAction;
