import {Grid} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import '../assets/scss/home.scss';
import Header from '../containers/Header';
import Sidenav from '../containers/Sidenav';
import Notification from '../models/Notification';
import VacationRequest from '../models/VacationRequest';


export interface NotificationsStateToProps {
    vacationRequests: VacationRequest[];
    notifications: Notification[];
}

export type NotificationsProps = NotificationsStateToProps;

export interface NotificationsState {
    sidenav: {
        open: boolean;
    };
}

export default class Notifications extends React.Component<NotificationsProps, NotificationsState> {
    public constructor(props: NotificationsProps) {
        super(props);

        this.state = {
            sidenav: {
                open: false,
            },
        };
    }

    public render(): React.ReactElement {
        return (
            <Grid className="wrapper">
                <Header/>

                <Sidenav open={this.state.sidenav.open}>
                    <Grid className="sidenav-wrapper">
                        {this.props.vacationRequests && this.props.notifications
                        && this.props.vacationRequests.length === 0 && this.props.notifications.length === 0 && (
                            <Typography variant="h5">
                                No notifications
                            </Typography>
                        )}
                    </Grid>
                </Sidenav>
            </Grid>
        );
    }
}
