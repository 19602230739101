import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import {momentLocalizer} from 'react-big-calendar';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './containers/App';
import EmployeeService from './services/Employee';
import GroupService from './services/Group';
import LogService from './services/Log';
import VacationService from './services/Vacation';
import store from './store';


moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});

export const localizer = momentLocalizer(moment);

export interface WebSocketEvent {
    event: string;
    model: 'Vacation' | 'Log' | 'VacationRequest' | 'Employee' | 'Group' | 'Notification' | 'GroupMember' | 'EmployeeRole';
}

const runWebsocket = (): void => {
    // console.info("[SOCKET] (Re)Starting...");

    const websocket = new WebSocket(
        process.env.REACT_APP_STAGE === 'dev' ?
            `ws://${location.hostname}:8000/ws/` :
            `wss://${location.hostname}/ws/`,
    );

    websocket.onmessage = (message: MessageEvent): void => {
        try {
            const webSocketEvent = JSON.parse(message.data) as WebSocketEvent;

            switch (webSocketEvent.model) {
                case 'Vacation':
                    VacationService.receive(store.getState().Vacation.prevFilter)(store.dispatch);

                    break;
                case 'Log':
                    LogService.receive(
                        store.getState().Log.prevLimit,
                        store.getState().Log.prevOffset,
                        store.getState().Log.prevSearch,
                        store.getState().Log.prevOrdering,
                    )(store.dispatch);

                    break;
                case 'VacationRequest':
                    VacationService.receive(store.getState().Vacation.prevFilter)(store.dispatch);

                    Object.keys(store.getState().Vacation.requests).forEach(
                        (vacationId): void =>
                            VacationService.receiveRequests(parseInt(vacationId))(store.dispatch),
                    );

                    break;
                case 'Employee':
                case 'EmployeeRole':
                    EmployeeService.receive(
                        store.getState().Employee.prevLimit,
                        store.getState().Employee.prevOffset,
                        store.getState().Employee.prevSearch,
                        store.getState().Employee.prevOrdering,
                        store.getState().Employee.prevFilter,
                    )(store.dispatch);

                    EmployeeService.receiveMe()(store.dispatch);

                    Object.keys(store.getState().Employee.roles).forEach(
                        (roleId): void =>
                            EmployeeService.receiveRoles(parseInt(roleId))(store.dispatch),
                    );

                    break;
                case 'Group':
                case 'GroupMember':
                    GroupService.receive(
                        store.getState().Group.prevLimit,
                        store.getState().Group.prevOffset,
                        store.getState().Group.prevSearch,
                        store.getState().Group.prevOrdering,
                        store.getState().Group.prevFilter,
                    )(store.dispatch);

                    Object.keys(store.getState().Group.members).forEach(
                        (groupId): void =>
                            GroupService.receiveMembers(parseInt(groupId))(store.dispatch),
                    );
                    break;
                case 'Notification':
                    EmployeeService.receiveNotifications()(store.dispatch);
                    break;
            }

            EmployeeService.receiveNotifications()(store.dispatch);
            EmployeeService.receiveVacationRequests()(store.dispatch);
        } catch (e) {
            // pass
        }
    };

    websocket.onerror = (): void => {
        // console.warn("[SOCKET] Connection lost. Try to reconnect after 5 seconds...");
        setTimeout(runWebsocket, 5000);
        websocket.close();
    };
};

runWebsocket();

ReactDOM.render(
    <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider
                maxSnack={7}
                style={{wordBreak: 'break-word'}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                preventDuplicate={true}
                hideIconVariant={true}
            >
                <App/>
            </SnackbarProvider>
        </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById('root'),
);
