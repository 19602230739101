import {SettingsAction} from '../actions/Settings';
import Settings from '../models/Settings';


export type SettingsReducerReceivingTarget =
    'SETTINGS';

export interface SettingsReducerState {
    settings: Settings;
    receivingCounter: number;
}

const initialState: SettingsReducerState = {
    settings: [],
    receivingCounter: 0,
};

const SettingsReducer = (state: SettingsReducerState = initialState, action: SettingsAction): SettingsReducerState => {
    switch (action.type) {
        case 'RECEIVE_SETTINGS_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_SETTINGS_FINISHED':
            state.settings = action.settings;
            state.receivingCounter--;
            break;
    }

    return {...state};
};

export default SettingsReducer;
