import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import GroupsPage, {GroupsDispatchToProps, GroupsStateToProps} from '../components/GroupsPage';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import {groupComponentWrapperMapDispatchToProps, groupComponentWrapperMapStateToProps} from '../models/Group';
import GroupService from '../services/Group';
import GroupStatusService from '../services/GroupStatus';
import GroupTypeService from '../services/GroupType';


const mapStateToProps = (state): GroupsStateToProps => ({
    groupTypes: state.GroupType.groupTypes,
    groupStatuses: state.GroupStatus.groupStatuses,
    ...groupComponentWrapperMapStateToProps(state),
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: GroupsDispatchToProps = {
    addGroupMember: GroupService.addMember,
    createGroup: GroupService.create,
    receiveGroupStatuses: GroupStatusService.receive,
    receiveGroupTypes: GroupTypeService.receive,
    removeGroup: GroupService.remove,
    removeGroupMember: GroupService.removeMember,
    updateGroup: GroupService.update,
    ...groupComponentWrapperMapDispatchToProps,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(GroupsPage));
