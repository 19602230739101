import {connect} from 'react-redux';
import Notification, {NotificationDispatchToProps, NotificationStateToProps} from '../components/Notification';
import EmployeeService from '../services/Employee';


const mapStateToProps = (state): NotificationStateToProps => ({
    employees: state.Employee.employees,
});

const mapDispatchToProps: NotificationDispatchToProps = {
    hideNotification: EmployeeService.hideNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
