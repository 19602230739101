import {RoleAction} from '../actions/Role';
import Role from '../models/Role';


export type RoleReducerReceivingTarget =
    'ROLES';

export interface RoleReducerState {
    roles: Role[];
    receivingCounter: number;
}

const initialState: RoleReducerState = {
    roles: [],
    receivingCounter: 0,
};

const RoleReducer = (state: RoleReducerState = initialState, action: RoleAction): RoleReducerState => {
    switch (action.type) {
        case 'RECEIVE_ROLES_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_ROLES_FINISHED':
            state.roles = action.roles;
            state.receivingCounter--;
            break;
    }

    return {...state};
};

export default RoleReducer;
