import {
    Button,
    CircularProgress,
    Fade,
    IconButton,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/BorderColor';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import deepEqual from 'deep-equal';
import lodash from 'lodash';
import {WithSnackbarProps} from 'notistack';
import React from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import {BrowserView, isBrowser, isMobile, MobileView} from 'react-device-detect';
import '../assets/scss/home.scss';
import '../assets/scss/react-big-calendar.scss';
import '../assets/scss/react-yearly-calendar.scss';
import GroupDialog from '../containers/GroupDialog';
import GroupFilter from '../containers/GroupFilter';
import ManageGroupEmployeesDialog from '../containers/ManageGroupEmployeesDialog';
import Employee, {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group, {
    GroupComponentWrapper,
    GroupComponentWrapperDispatchToProps,
    GroupComponentWrapperStateToProps,
} from '../models/Group';
import GroupStatus from '../models/GroupStatus';
import GroupType from '../models/GroupType';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';
import {vacationPriorityList} from './GroupDialog';
import {GroupFilterValues} from './GroupFilter';


type OrderDirection = 'asc' | 'desc';
type OrderBy = 'display_name' | 'lead' | 'vacation_priority' | 'status' | 'type' | 'members_count';

export interface GroupsStateToProps extends GroupComponentWrapperStateToProps, EmployeeComponentWrapperStateToProps {
    groupTypes: GroupType[];
    groupStatuses: GroupStatus[];
}

export interface GroupsDispatchToProps extends GroupComponentWrapperDispatchToProps, EmployeeComponentWrapperDispatchToProps {
    receiveGroupTypes: () => void;
    receiveGroupStatuses: () => void;
    removeGroup: (group: Group) => void;
    updateGroup: (group: Group) => void;
    createGroup: (group: Group) => void;
    addGroupMember: (group: Group, member: Employee) => void;
    removeGroupMember: (group: Group, member: Employee) => void;
}

export type GroupsProps = GroupsStateToProps & GroupsDispatchToProps;

export interface GroupsState {
    groupEmployeesDialog: {
        open: boolean;
        group?: Group;
    };
    groupDialog: {
        open: boolean;
        group?: Group;
    };
    order: OrderDirection;
    orderBy: OrderBy;
    searchValue: string;
    inputValue: string;
    page: number;
    rowsPerPage: number;
    submitDialog: {
        title: string;
        open: boolean;
        onYesClicked: () => void;
        onNoClicked: () => void;
    };
    groupActions: {
        open: boolean;
        anchorEl?: HTMLElement;
        group?: Group;
    };
    groupFilterValues: GroupFilterValues;
}

export default class GroupsPage extends React.Component<GroupsProps & WithSnackbarProps, GroupsState> implements GroupComponentWrapper, EmployeeComponentWrapper {
    public headRows = [
        {id: 'display_name', numeric: false, disablePadding: true, label: 'Name'},
        {id: 'lead', numeric: false, disablePadding: false, label: 'Lead'},
        {id: 'type', numeric: false, disablePadding: false, label: 'Type', onlyForDesktop: true},
        {id: 'status', numeric: false, disablePadding: false, label: 'Status', onlyForDesktop: true},
        {
            id: 'vacation_priority',
            numeric: true,
            disablePadding: false,
            label: 'Vacation Priority',
            onlyForDesktop: true,
        },
        {id: 'members_count', numeric: true, disablePadding: false, label: 'Members', onlyForDesktop: true},
    ];

    public constructor(props: GroupsProps & WithSnackbarProps) {
        super(props);

        this.state = {
            groupEmployeesDialog: {
                open: false,
            },
            groupDialog: {
                open: false,
            },
            order: 'asc',
            orderBy: 'display_name',
            searchValue: '',
            inputValue: '',
            page: 0,
            rowsPerPage: 15,
            submitDialog: {
                title: '',
                open: false,
                onYesClicked: null,
                onNoClicked: null,
            },
            groupActions: {
                open: false,
            },
            groupFilterValues: {
                groupStatusesIds: [2],
            },
        };
    }

    public componentDidMount(): void {
        this.props.receiveGroupStatuses();
        this.props.receiveGroupTypes();
        this.props.receiveEmployees();
        this.props.receiveGroups(
            this.state.rowsPerPage,
            this.state.page * this.state.rowsPerPage,
            this.state.searchValue,
            `${this.state.order === 'asc' ? '' : '-'}${this.state.orderBy}`,
            this.state.groupFilterValues,
        );

        if (this.props.me) {
            this.props.receiveEmployeeRoles(this.props.me.id);
        }
    }

    public componentDidUpdate(prevProps: Readonly<GroupsProps & WithSnackbarProps>, prevState: Readonly<GroupsState>): void {
        if (
            this.state.page != prevState.page ||
            this.state.rowsPerPage != prevState.rowsPerPage ||
            this.state.searchValue != prevState.searchValue ||
            this.state.orderBy != prevState.orderBy ||
            this.state.order != prevState.order ||
            (this.state.groupFilterValues && this.state.groupFilterValues != prevState.groupFilterValues)
        ) {
            this.props.receiveGroups(
                this.state.rowsPerPage,
                this.state.page * this.state.rowsPerPage,
                this.state.searchValue,
                `${this.state.order === 'asc' ? '' : '-'}${this.state.orderBy}`,
                this.state.groupFilterValues,
            );
        }

        if (this.props.groups != prevProps.groups) {
            this.props.groups.forEach((group): void => this.props.receiveGroupMembers(group.id));
        }

        if (this.props.me != prevProps.me && this.props.me) {
            this.props.receiveEmployeeRoles(this.props.me.id);
        }

        if (this.props.groups && this.state.groupEmployeesDialog.group) {
            let updatedGroup = this.props.groups.find((group): boolean => group.id === this.state.groupEmployeesDialog.group.id);

            if (!deepEqual(this.state.groupEmployeesDialog.group, updatedGroup)) {
                this.setState({
                    groupEmployeesDialog: {
                        ...this.state.groupEmployeesDialog,
                        group: updatedGroup,
                    },
                });
            }
        }
    }

    public handleKeyDown = (event): void => {
        if (event.key === 'Enter') {
            this.setState({
                inputValue: event.target.value,
                searchValue: !event.target.value ? '' : this.state.inputValue,
                page: 0,
            });
        }
    };

    public renderTablePagination = (): React.ReactElement => {
        return <TablePagination
            rowsPerPageOptions={this.props.groupsCount !== 0 ? [5, 10, 15, 30, 45, 100] : []}
            component="div"
            labelRowsPerPage={!isMobile ? 'Rows per page:' : 'Rows:'}
            count={this.props.groupsCount}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={(event: object, newPage: number): void => this.setState({page: newPage})}
            onChangeRowsPerPage={
                (event): void => {
                    this.setState({
                        rowsPerPage: +event.target.value,
                        page: 0,
                    });
                }
            }
        />;
    };

    public render(): React.ReactElement {
        const dataWasLoaded =
            this.props.groups != undefined &&
            this.props.employees != undefined &&
            this.props.groupTypes != undefined &&
            this.props.groupStatuses != undefined &&
            this.props.me != undefined;

        const {order, orderBy} = this.state;

        return (
            <Grid className="wrapper">
                <Grid className="spinner">
                    <Fade
                        in={!dataWasLoaded}
                        unmountOnExit
                    >
                        <CircularProgress
                            color="inherit"
                            size={80}
                        />
                    </Fade>
                </Grid>

                <Grid className={!dataWasLoaded ? 'data-not-loaded' : 'data-loaded'}>
                    <Grid className="sidenav-wrapper" style={{overflow: 'auto'}}>
                        <Toolbar className="toolbar">
                            <Grid container direction="column">
                                <Grid item container direction="row" wrap="nowrap">
                                    <GroupFilter
                                        groupSearch
                                        groupLeadsFilter
                                        groupTypesFilter
                                        groupStatusesFilter
                                        defaultValue={this.state.groupFilterValues}
                                        onChange={
                                            (filter): void => {
                                                this.setState({
                                                    groupFilterValues: {
                                                        ...filter,
                                                    },
                                                });
                                            }
                                        }
                                    />
                                </Grid>

                                <Grid item container direction="row" justify="flex-end">
                                    {
                                        dataWasLoaded &&
                                        this.isAdmin(this.props.me.id) &&
                                        <Tooltip title={isBrowser ? 'Add Group' : ''}>
                                            <IconButton
                                                color="primary"
                                                onClick={(): void => {
                                                    this.setState({
                                                        groupDialog: {
                                                            open: true,
                                                        },
                                                    });
                                                }}
                                            >
                                                <GroupAddIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {this.renderTablePagination()}
                                </Grid>
                            </Grid>
                        </Toolbar>

                        {
                            dataWasLoaded &&
                            <React.Fragment>
                                {
                                    this.props.groups.length === 0 ?
                                        <Typography variant="subtitle1" style={{marginLeft: '25px'}}>
                                            No matches found
                                        </Typography> : (
                                            <React.Fragment>
                                                <Table>
                                                    <colgroup>
                                                        <col/>
                                                        <col/>
                                                        {isBrowser && <col style={{width: '1%'}}/>}
                                                        {isBrowser && <col style={{width: '1%'}}/>}
                                                        {isBrowser && <col style={{width: '1%'}}/>}
                                                        {isBrowser && <col style={{width: '1%'}}/>}
                                                        <col style={{width: '1%'}}/>
                                                    </colgroup>

                                                    <TableHead>
                                                        <TableRow>
                                                            {
                                                                this.headRows
                                                                    .filter((row): boolean => isBrowser || !row.onlyForDesktop)
                                                                    .map(
                                                                        (row): React.ReactElement => (
                                                                            <TableCell
                                                                                className={isMobile ? 'p-10' : ''}
                                                                                key={`row-${row.id}`}
                                                                                sortDirection={orderBy === row.id ? order : false}
                                                                            >
                                                                                <TableSortLabel
                                                                                    active={orderBy === row.id}
                                                                                    direction={order}
                                                                                    onClick={
                                                                                        (): void => this.setState({
                                                                                            order: this.state.orderBy === row.id ?
                                                                                                order === 'asc' ? 'desc' : 'asc'
                                                                                                : 'asc',
                                                                                            orderBy: row.id as OrderBy,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    {row.label}
                                                                                </TableSortLabel>
                                                                            </TableCell>
                                                                        ))
                                                            }
                                                            <TableCell/>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {
                                                            this.props.groups
                                                                .map((group): React.ReactElement => {
                                                                    return (
                                                                        <TableRow key={`group-${group.id}`}>
                                                                            <TableCell
                                                                                className={isMobile ? 'p-10' : ''}
                                                                            >
                                                                                {group.display_name}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                style={{
                                                                                    wordBreak: 'break-all',
                                                                                    textAlign: 'left',
                                                                                }}
                                                                                className={isMobile ? 'p-10' : ''}
                                                                            >
                                                                                {`${group.lead.last_name} ${group.lead.first_name}`}
                                                                            </TableCell>

                                                                            {
                                                                                isBrowser &&
                                                                                <TableCell>{group.type && group.type.display_name}</TableCell>
                                                                            }

                                                                            {
                                                                                isBrowser &&
                                                                                <TableCell>{group.status && group.status.display_name}</TableCell>
                                                                            }

                                                                            {
                                                                                isBrowser &&
                                                                                <TableCell>{vacationPriorityList[lodash.min([lodash.max([group.vacation_priority, 0]), 4])]}</TableCell>
                                                                            }

                                                                            {
                                                                                isBrowser &&
                                                                                <TableCell
                                                                                    style={
                                                                                        (
                                                                                            this.isAdmin(this.props.me.id) ||
                                                                                            this.props.me.id === group.lead.id
                                                                                        ) ? {cursor: 'pointer'} : {}
                                                                                    }
                                                                                    onClick={(): void => {
                                                                                        if (
                                                                                            this.isAdmin(this.props.me.id) ||
                                                                                            this.props.me.id === group.lead.id
                                                                                        ) {
                                                                                            this.setState({
                                                                                                groupEmployeesDialog: {
                                                                                                    open: true,
                                                                                                    group,
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                >{group.members_count}</TableCell>
                                                                            }

                                                                            <TableCell
                                                                                padding="checkbox"
                                                                                className={isMobile ? 'p-10' : ''}
                                                                            >
                                                                                <Grid
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                    }}
                                                                                >
                                                                                    <BrowserView>
                                                                                        <Grid
                                                                                            item
                                                                                            container
                                                                                            direction="row"
                                                                                            wrap="nowrap"
                                                                                        >
                                                                                            {
                                                                                                (
                                                                                                    this.isAdmin(this.props.me.id) ||
                                                                                                    this.props.me.id === group.lead.id
                                                                                                ) && (
                                                                                                    <>
                                                                                                        <Tooltip
                                                                                                            title={isBrowser ? 'Edit Group' : ''}
                                                                                                        >
                                                                                                            <IconButton
                                                                                                                disableFocusRipple
                                                                                                                style={{
                                                                                                                    whiteSpace: 'nowrap',
                                                                                                                    marginRight: '5px',
                                                                                                                }}
                                                                                                                onClick={(): void => {
                                                                                                                    this.setState({
                                                                                                                        groupDialog: {
                                                                                                                            open: true,
                                                                                                                            group,
                                                                                                                        },
                                                                                                                    });
                                                                                                                }}
                                                                                                            >
                                                                                                                <EditIcon/>
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </>
                                                                                                )
                                                                                            }

                                                                                            {
                                                                                                this.isAdmin(this.props.me.id) && (
                                                                                                    <Tooltip
                                                                                                        title={isBrowser ? 'Remove Group' : ''}
                                                                                                    >
                                                                                                        <IconButton
                                                                                                            color="secondary"
                                                                                                            disableFocusRipple
                                                                                                            style={{whiteSpace: 'nowrap'}}
                                                                                                            onClick={(): void => this.setState({
                                                                                                                submitDialog: {
                                                                                                                    ...this.state.submitDialog,
                                                                                                                    title: 'Are you sure you want to remove the group?',
                                                                                                                    open: true,
                                                                                                                    onYesClicked: (): void => {
                                                                                                                        this.props.removeGroup(group);

                                                                                                                        this.setState({
                                                                                                                            submitDialog: {
                                                                                                                                ...this.state.submitDialog,
                                                                                                                                open: false,
                                                                                                                            },
                                                                                                                        });
                                                                                                                    },
                                                                                                                    onNoClicked: (): void => this.setState({
                                                                                                                        submitDialog: {
                                                                                                                            ...this.state.submitDialog,
                                                                                                                            open: false,
                                                                                                                        },
                                                                                                                    }),
                                                                                                                },
                                                                                                            })}
                                                                                                        >
                                                                                                            <DeleteIcon/>
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                )
                                                                                            }
                                                                                        </Grid>
                                                                                    </BrowserView>

                                                                                    {(
                                                                                        this.isAdmin(this.props.me.id) ||
                                                                                        this.props.me.id === group.lead.id
                                                                                    ) && (
                                                                                        <MobileView>
                                                                                            <IconButton
                                                                                                onClick={(event): void => this.setState({
                                                                                                    groupActions: {
                                                                                                        open: true,
                                                                                                        anchorEl: event.target as HTMLElement,
                                                                                                        group,
                                                                                                    },
                                                                                                })}
                                                                                                style={
                                                                                                    isMobile ? {padding: '0'} : {padding: '12px'}
                                                                                                }
                                                                                            >
                                                                                                <MoreVertIcon/>
                                                                                            </IconButton>
                                                                                        </MobileView>
                                                                                    )}
                                                                                </Grid>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })
                                                        }
                                                    </TableBody>
                                                </Table>

                                                <MobileView>
                                                    {
                                                        this.state.groupActions.group &&
                                                        <Popover
                                                            open={this.state.groupActions.open}
                                                            onBackdropClick={(): void => this.setState({
                                                                groupActions: {
                                                                    open: false,
                                                                },
                                                            })}
                                                            onClick={(): void => this.setState({
                                                                groupActions: {
                                                                    open: false,
                                                                },
                                                            })}
                                                            anchorEl={this.state.groupActions.anchorEl}
                                                            anchorOrigin={{
                                                                horizontal: 'right',
                                                                vertical: 'top',
                                                            }}
                                                        >
                                                            <Grid
                                                                item
                                                                container
                                                                direction="row"
                                                                wrap="nowrap"
                                                            >
                                                                {
                                                                    (
                                                                        this.isAdmin(this.props.me.id) ||
                                                                        this.props.me.id === this.state.groupActions.group.lead.id
                                                                    ) && (
                                                                        <>
                                                                            <Tooltip
                                                                                title={isBrowser ? 'Manage Members' : ''}
                                                                            >
                                                                                <IconButton
                                                                                    disableFocusRipple
                                                                                    style={{
                                                                                        whiteSpace: 'nowrap',
                                                                                        marginRight: '5px',
                                                                                    }}
                                                                                    onClick={(): void => {
                                                                                        this.setState({
                                                                                            groupEmployeesDialog: {
                                                                                                open: true,
                                                                                                group: this.state.groupActions.group,
                                                                                            },
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <GroupWorkIcon/>
                                                                                </IconButton>
                                                                            </Tooltip>

                                                                            <Tooltip
                                                                                title={isBrowser ? 'Edit Group' : ''}
                                                                            >
                                                                                <IconButton
                                                                                    disableFocusRipple
                                                                                    style={{
                                                                                        whiteSpace: 'nowrap',
                                                                                        marginRight: '5px',
                                                                                    }}
                                                                                    onClick={(): void => {
                                                                                        this.setState({
                                                                                            groupDialog: {
                                                                                                open: true,
                                                                                                group: this.state.groupActions.group,
                                                                                            },
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <EditIcon/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>
                                                                    )
                                                                }

                                                                {
                                                                    this.isAdmin(this.props.me.id) && (
                                                                        <Tooltip
                                                                            title={isBrowser ? 'Remove Group' : ''}
                                                                        >
                                                                            <IconButton
                                                                                color="secondary"
                                                                                disableFocusRipple
                                                                                style={{whiteSpace: 'nowrap'}}
                                                                                onClick={(): void => this.setState({
                                                                                    submitDialog: {
                                                                                        ...this.state.submitDialog,
                                                                                        title: 'Are you sure you want to remove the group?',
                                                                                        open: true,
                                                                                        onYesClicked: (): void => {
                                                                                            this.props.removeGroup(this.state.groupActions.group);

                                                                                            this.setState({
                                                                                                submitDialog: {
                                                                                                    ...this.state.submitDialog,
                                                                                                    open: false,
                                                                                                },
                                                                                            });
                                                                                        },
                                                                                        onNoClicked: (): void => this.setState({
                                                                                            submitDialog: {
                                                                                                ...this.state.submitDialog,
                                                                                                open: false,
                                                                                            },
                                                                                        }),
                                                                                    },
                                                                                })}
                                                                            >
                                                                                <DeleteIcon/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )
                                                                }
                                                            </Grid>
                                                        </Popover>
                                                    }
                                                </MobileView>
                                            </React.Fragment>
                                        )
                                }
                            </React.Fragment>
                        }

                        {this.renderTablePagination()}

                        <ManageGroupEmployeesDialog
                            open={this.state.groupEmployeesDialog.open}
                            group={this.state.groupEmployeesDialog.group}
                            onClose={(): void => {
                                this.setState({
                                    groupEmployeesDialog: {
                                        open: false,
                                    },
                                });
                            }}
                        />

                        <GroupDialog
                            open={this.state.groupDialog.open}
                            group={this.state.groupDialog.group}
                            onClose={(): void => {
                                this.setState({
                                    groupDialog: {
                                        open: false,
                                    },
                                });
                            }}
                        />
                    </Grid>

                    <Dialog
                        open={this.state.submitDialog.open}
                        onClose={(): void => {
                            this.state.submitDialog.onNoClicked();
                        }}
                    >
                        <DialogTitle>{this.state.submitDialog.title}</DialogTitle>

                        <DialogActions>
                            <Button
                                fullWidth
                                disableFocusRipple
                                onClick={(): void => this.state.submitDialog.onYesClicked()}
                                color="primary"
                            >
                                Yes
                            </Button>

                            <Button
                                fullWidth
                                disableFocusRipple
                                onClick={(): void => this.state.submitDialog.onNoClicked()}
                                color="primary"
                            >
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        );
    }

    public getGroupMembers = (groupId: number): Employee[] =>
        this.props.groupMembers[groupId] || [];

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
