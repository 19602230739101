import {Action} from 'redux';
import Log from '../models/Log';


export type LogActionTypes =
    'RECEIVE_LOGS_STARTED' |
    'RECEIVE_LOGS_FINISHED';

export interface ReceiveLogsStartedAction extends Action<LogActionTypes> {
    limit?: number;
    offset?: number;
    search?: string;
    ordering?: string;
}

export const receiveLogsStarted = (limit?: number, offset?: number, search?: string, ordering?: string): ReceiveLogsStartedAction => ({
    type: 'RECEIVE_LOGS_STARTED',
    limit,
    offset,
    search,
    ordering,
});

export interface ReceiveLogsFinishedAction extends Action<LogActionTypes> {
    logs: Log[];
    count: number;
}

export const receiveLogsFinished = (logs: Log[], count: number): ReceiveLogsFinishedAction => ({
    type: 'RECEIVE_LOGS_FINISHED',
    logs,
    count,
});

export type LogAction =
    ReceiveLogsStartedAction &
    ReceiveLogsFinishedAction;
