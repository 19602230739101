import {Action} from 'redux';
import GroupType from '../models/GroupType';


export type GroupTypeActionTypes =
    'RECEIVE_GROUP_TYPES_STARTED' |
    'RECEIVE_GROUP_TYPES_FINISHED';

export const receiveGroupTypesStarted = (): Action<GroupTypeActionTypes> => ({
    type: 'RECEIVE_GROUP_TYPES_STARTED',
});

export interface ReceiveGroupTypesFinishedAction extends Action<GroupTypeActionTypes> {
    groupTypes: GroupType[];
}

export const receiveGroupTypesFinished = (groupTypes: GroupType[]): ReceiveGroupTypesFinishedAction => ({
    type: 'RECEIVE_GROUP_TYPES_FINISHED',
    groupTypes,
});

export type GroupTypeAction =
    ReceiveGroupTypesFinishedAction;

