import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {WithSnackbarProps} from 'notistack';
import React, {Component} from 'react';
import {Redirect, Route, RouteProps, Router, Switch} from 'react-router-dom';
import AnnualCalendar from '../containers/AnnualCalendarPage';
import ContentWrapper from '../containers/ContentWrapper';
import Employees from '../containers/Employees';
import Groups from '../containers/Groups';
import Login from '../containers/Login';
import Logs from '../containers/Logs';
import MonthlyCalendar from '../containers/MonthlyCalendar';
import Notifications from '../containers/Notifications';
import ProfilePage from '../containers/ProfilePage';
import SettingsPage from '../containers/SettingsPage';
import history from '../history';
import Alert from '../models/Alert';
import SystemNotification from '../models/SystemNotification';
import AuthenticationService from '../services/Authentication';


class PrivateRoute extends Route<RouteProps> {
    public render(): React.ReactNode {
        return AuthenticationService.isAuthenticated() ?
            (
                <ContentWrapper>
                    {super.render()}
                </ContentWrapper>
            ) :
            (<Redirect to="/login"/>);
    }
}

class PublicRoute extends Route<RouteProps> {
    public render(): React.ReactNode {
        return !AuthenticationService.isAuthenticated() ? super.render() : (<Redirect to="/home"/>);
    }
}

export interface AppStateToProps {
    systemNotification: SystemNotification;
    alert: Alert;
}

export interface AppDispatchToProps {
    clearSystemNotification: () => void;
    pushSystemNotification: (systemNotification: string) => void;
    hideAlert: () => void;
    showAlert: (alert: Alert) => void;
}

export type AppProps = AppStateToProps & AppDispatchToProps;

export default class App extends Component<AppProps & WithSnackbarProps> {
    public componentDidUpdate(): void {
        if (this.props.systemNotification != undefined) {
            let key = this.props.systemNotification.id;

            this.props.enqueueSnackbar(this.props.systemNotification.text, {
                variant: this.props.systemNotification.variant,
                key,
            });

            window.setTimeout((): void => {
                this.props.closeSnackbar(key);
            }, 5000);

            this.props.clearSystemNotification();
        }
    }

    public render(): React.ReactElement {
        return (
            <React.Fragment>
                {
                    this.props.alert && (
                        <Dialog open={!!this.props.alert} onClose={(): void => this.props.hideAlert()}>
                            <DialogTitle>{this.props.alert.title}</DialogTitle>

                            <DialogContent>
                                <DialogContentText>{this.props.alert.text}</DialogContentText>
                            </DialogContent>

                            <DialogActions>
                                <Button disableFocusRipple onClick={(): void => this.props.hideAlert()}>OK</Button>
                            </DialogActions>
                        </Dialog>
                    )
                }

                <Router history={history}>
                    <Switch>
                        <PublicRoute exact path="/login">
                            <Login/>
                        </PublicRoute>

                        <PrivateRoute exact path="/home" component={MonthlyCalendar}/>

                        <PrivateRoute exact path="/annual" component={AnnualCalendar}/>

                        <PrivateRoute exact path="/groups">
                            <Groups/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/notifications">
                            <Notifications/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/logs">
                            <Logs/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/users">
                            <Employees/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/settings">
                            <SettingsPage/>
                        </PrivateRoute>

                        <PrivateRoute
                            exact
                            path={`/profile-:id`}
                            component={ProfilePage}
                        />

                        <Route path="*">
                            <Redirect to="/home"/>
                        </Route>
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}
