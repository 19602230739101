import {VacationReasonAction} from '../actions/VacationReason';
import VacationReason from '../models/VacationReason';


export type VacationReasonReducerReceivingTarget =
    'VACATION_REASONS';

export interface VacationReasonReducerState {
    vacationReasons: VacationReason[];
    receivingCounter: number;
}

const initialState: VacationReasonReducerState = {
    vacationReasons: [],
    receivingCounter: 0,
};

const VacationReasonReducer = (state: VacationReasonReducerState = initialState, action: VacationReasonAction): VacationReasonReducerState => {
    switch (action.type) {
        case 'RECEIVE_VACATION_REASONS_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_VACATION_REASONS_FINISHED':
            state.vacationReasons = action.vacationReasons;
            state.receivingCounter--;
            break;
    }

    return {...state};
};

export default VacationReasonReducer;
