import {
    AppBar,
    Button,
    Dialog,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import React from 'react';
import {isBrowser} from 'react-device-detect';
import ManageGroupEmployeesDialog from '../containers/ManageGroupEmployeesDialog';
import Employee, {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group, {
    GroupComponentWrapper,
    GroupComponentWrapperDispatchToProps,
    GroupComponentWrapperStateToProps,
} from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';


export interface ManageEmployeeRolesDialogStateToProps extends GroupComponentWrapperStateToProps, EmployeeComponentWrapperStateToProps {
    roles: Role[];
}

export interface ManageEmployeeRolesDialogDispatchToProps extends GroupComponentWrapperDispatchToProps, EmployeeComponentWrapperDispatchToProps {
    addEmployeeRole: (employee, role) => void;
    removeEmployeeRole: (employee, role) => void;
    receiveRoles: () => void;
}

export type ManageEmployeeRolesDialogProps =
    ManageEmployeeRolesDialogStateToProps &
    ManageEmployeeRolesDialogDispatchToProps &
    {
        open: boolean;
        employee: Employee;
        onClose?: () => void;
    };

export interface EmployeeRolesState {
    submitDialog: {
        title: string;
        open: boolean;
        onYesClicked: () => void;
        onNoClicked: () => void;
    };
    groupEmployeesDialog: {
        open: boolean;
        group?: Group;
    };
}

export default class ManageEmployeeRolesDialog extends React.Component<ManageEmployeeRolesDialogProps, EmployeeRolesState> implements GroupComponentWrapper, EmployeeComponentWrapper {
    public constructor(props: ManageEmployeeRolesDialogProps) {
        super(props);

        this.state = {
            submitDialog: {
                title: '',
                open: false,
                onYesClicked: null,
                onNoClicked: null,
            },
            groupEmployeesDialog: {
                open: false,
            },
        };
    }

    public componentDidMount(): void {
        this.props.receiveRoles();
    }

    public componentDidUpdate(prevProps: Readonly<ManageEmployeeRolesDialogProps>): void {
        if (this.props.employee && this.props.employee != prevProps.employee) {
            this.props.receiveEmployeeRoles(this.props.employee.id);
            this.props.receiveEmployeeGroups(this.props.employee.id);
        }
    }

    public addRole = (role: Role): void => {
        this.props.addEmployeeRole(this.props.employee, role);
    };

    public removeRole = (role: Role): void => {
        this.props.removeEmployeeRole(this.props.employee, role);
    };

    public render(): React.ReactElement {
        if (!this.props.employee) {
            return <React.Fragment/>;
        }

        const roles =
            (this.props.roles || []).filter(
                (role): boolean =>
                    !(this.props.employeeRoles[this.props.employee.id] || []).some(
                        (employeeRole): boolean => role.id == employeeRole.id,
                    ),
            );

        const employeeGroups = this.props.employeeGroups[this.props.employee.id] || [];

        return (
            <Grid>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.onClose}
                    fullScreen
                >
                    <AppBar style={{position: 'relative'}}>
                        <Toolbar style={{minHeight: '52px'}}>
                            <IconButton color="inherit" aria-label="Close" onClick={this.props.onClose} edge="start">
                                <CloseIcon/>
                            </IconButton>

                            <Typography
                                variant="h6" color="inherit" style={{
                                marginLeft: '6px',
                                flex: '1',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                            >
                                {this.props.employee && `Roles of ${this.props.employee.last_name} ${this.props.employee.first_name}`}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    {
                        (this.props.employeeRoles[this.props.employee.id] || []).length > 0 &&
                        <React.Fragment>
                            <Toolbar>
                                <Typography variant="h6">
                                    User Roles
                                </Typography>
                            </Toolbar>

                            <Table>
                                <colgroup>
                                    <col/>
                                    <col style={{width: '1%'}}/>
                                </colgroup>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        this.getEmployeeRoles(this.props.employee.id).map((role): React.ReactElement => (
                                            <TableRow key={`role-${role.id}`}>
                                                <TableCell>{role.display_name}</TableCell>
                                                <TableCell padding="checkbox">
                                                    <Tooltip title={isBrowser ? 'Remove Role' : ''}>
                                                        <IconButton
                                                            disableFocusRipple
                                                            style={{whiteSpace: 'nowrap'}}
                                                            onClick={(): void => this.setState({
                                                                submitDialog: {
                                                                    ...this.state.submitDialog,
                                                                    title: 'Are you sure you want to remove the role from the user?',
                                                                    open: true,
                                                                    onYesClicked: (): void => {
                                                                        this.removeRole(role);

                                                                        this.setState({
                                                                            submitDialog: {
                                                                                ...this.state.submitDialog,
                                                                                open: false,
                                                                            },
                                                                        });
                                                                    },
                                                                    onNoClicked: (): void => this.setState({
                                                                        submitDialog: {
                                                                            ...this.state.submitDialog,
                                                                            open: false,
                                                                        },
                                                                    }),
                                                                },
                                                            })}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </React.Fragment>
                    }

                    {
                        roles.length > 0 &&
                        <React.Fragment>
                            <Toolbar>
                                <Typography variant="h6">
                                    Other Roles
                                </Typography>
                            </Toolbar>

                            <Table>
                                <colgroup>
                                    <col/>
                                    <col style={{width: '1%'}}/>
                                </colgroup>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        roles
                                            .map((role): React.ReactElement => (
                                                <TableRow key={`role-${role.id}`}>
                                                    <TableCell>{role.display_name}</TableCell>
                                                    <TableCell padding="checkbox">
                                                        <Tooltip title={isBrowser ? 'Add Role' : ''}>
                                                            <IconButton
                                                                disableFocusRipple
                                                                style={{whiteSpace: 'nowrap'}}
                                                                onClick={(): void => this.addRole(role)}
                                                            >
                                                                <GroupAddIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </React.Fragment>
                    }

                    {
                        employeeGroups.length > 0 &&
                        <React.Fragment>
                            <Toolbar>
                                <Typography variant="h6">
                                    Groups
                                </Typography>
                            </Toolbar>

                            <Table>
                                <colgroup>
                                    <col/>
                                    <col/>
                                    <col style={{width: '1%'}}/>
                                </colgroup>

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Group Lead</TableCell>
                                        <TableCell>Role</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        this.getEmployeeGroups(this.props.employee.id)
                                            .sort((a, b): number => a.display_name.localeCompare(b.display_name))
                                            .map((group): React.ReactElement => (
                                                <TableRow
                                                    key={`group-${group.id}`}
                                                    className={(this.isAdmin(this.props.me.id) || group.lead.id == this.props.me.id) ? 'pointer clickable-row' : ''}
                                                    onClick={(): void => {
                                                        if (this.isAdmin(this.props.me.id) || group.lead.id == this.props.me.id) {
                                                            this.setState({
                                                                groupEmployeesDialog: {
                                                                    open: true,
                                                                    group,
                                                                },
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <TableCell>{group.display_name}</TableCell>
                                                    <TableCell>{group.lead.last_name} {group.lead.first_name}</TableCell>
                                                    <TableCell>{group.lead.id === this.props.employee.id ? 'Lead' : 'Member'}</TableCell>
                                                </TableRow>
                                            ))
                                    }
                                </TableBody>
                            </Table>
                        </React.Fragment>
                    }
                </Dialog>

                <Dialog
                    open={this.state.submitDialog.open}
                    onClose={(): void => {
                        this.state.submitDialog.onNoClicked();
                    }}
                >
                    <DialogTitle>{this.state.submitDialog.title}</DialogTitle>

                    <DialogActions>
                        <Button
                            fullWidth
                            disableFocusRipple
                            onClick={(): void => this.state.submitDialog.onYesClicked()}
                            color="primary"
                        >
                            Yes
                        </Button>

                        <Button
                            fullWidth
                            disableFocusRipple
                            onClick={(): void => this.state.submitDialog.onNoClicked()}
                            color="primary"
                        >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                <ManageGroupEmployeesDialog
                    open={this.state.groupEmployeesDialog.open}
                    group={this.state.groupEmployeesDialog.group}
                    onClose={
                        (): void => {
                            this.setState({
                                groupEmployeesDialog: {
                                    open: false,
                                },
                            });
                        }
                    }
                />
            </Grid>
        );
    }

    public getGroupMembers = (groupId: number): Employee[] =>
        this.props.groupMembers[groupId] || [];

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
