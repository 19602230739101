import {Button, Grid} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {WithSnackbarProps} from 'notistack';
import React, {ChangeEvent, Component, FormEvent} from 'react';
import '../assets/scss/login.scss';


export interface LoginDispatchToProps {
    login: (username: string, password: string) => void;
}

export type LoginProps = LoginDispatchToProps;

export interface LoginState {
    username: string;
    password: string;
}

export default class Login extends Component<LoginProps & WithSnackbarProps, LoginState> {
    public constructor(props: LoginProps & WithSnackbarProps) {
        super(props);

        this.state = {
            username: '',
            password: '',
        };
    }

    public setUsername = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            username: event.target.value,
        });
    };

    public setPassword = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            password: event.target.value,
        });
    };

    public login = (event: FormEvent): void => {
        event.preventDefault();

        this.props.login(this.state.username, this.state.password);
    };

    public render(): React.ReactElement {
        return (
            <Grid className="limiter">
                <Grid className="container-login100">
                    <Grid className="wrap-login100 p-b-20">
                        <form
                            className="login100-form validate-form"
                            noValidate
                            onSubmit={this.login} autoComplete="off"
                        >
                            <span className="login100-form-title p-b-20">SQIL Office Management</span>

                            <TextField
                                id="username"
                                label="Username"
                                className="input100"
                                value={this.state.username}
                                onChange={this.setUsername}
                                margin="normal"
                            />

                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                className="input100"
                                value={this.state.password}
                                onChange={this.setPassword}
                                margin="normal"
                            />

                            <Grid className="container-login100-form-btn p-t-10">
                                <Button
                                    fullWidth
                                    disableFocusRipple
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                >
                                    Login
                                </Button>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
