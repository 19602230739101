import {connect} from 'react-redux';
import employeesFilter, {
    EmployeesFilterDispatchToProps,
    EmployeesFilterStateToProps,
} from '../components/EmployeesFilter';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import {groupComponentWrapperMapDispatchToProps, groupComponentWrapperMapStateToProps} from '../models/Group';
import EmployeeStatusService from '../services/EmployeeStatus';
import GroupService from '../services/Group';


const mapStateToProps = (state): EmployeesFilterStateToProps => ({
    groups: state.Group.groups,
    employeeStatuses: state.EmployeeStatus.employeeStatuses,
    ...groupComponentWrapperMapStateToProps(state),
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: EmployeesFilterDispatchToProps = {
    receiveGroups: GroupService.receive,
    receiveEmployeeStatuses: EmployeeStatusService.receive,
    ...groupComponentWrapperMapDispatchToProps,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(employeesFilter);
