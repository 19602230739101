import axios from 'axios';
import {Dispatch} from 'redux';
import {
    receiveGroupMembersFinished,
    receiveGroupMembersStarted,
    receiveGroupsFinished,
    receiveGroupsFinishedWithError,
    receiveGroupsStarted,
} from '../actions/Group';
import {GroupFilterValues} from '../components/GroupFilter';
import Employee from '../models/Employee';
import Group from '../models/Group';
import Utils from '../utils/Utils';
import AuthenticationService from './Authentication';
import SystemNotificationService from './SystemNotification';


class GroupService {
    public static receive = (limit?: number, offset?: number, search?: string, ordering?: string, filter?: GroupFilterValues): (dispatch) => void =>
        (dispatch: Dispatch): void => {
            let filterObj = {};

            if (filter) {
                if (filter.groupSearchStr) {
                    filterObj['search'] = filter.groupSearchStr;
                }

                if (filter.groupLeadsIds && filter.groupLeadsIds.length > 0) {
                    filterObj['lead__id__in'] =
                        filter.groupLeadsIds
                            .join(',');
                }

                if (filter.groupStatusesIds && filter.groupStatusesIds.length > 0) {
                    filterObj['status__id__in'] =
                        filter.groupStatusesIds
                            .join(',');
                }

                if (filter.groupTypesIds && filter.groupTypesIds.length > 0) {
                    filterObj['type__id__in'] =
                        filter.groupTypesIds
                            .join(',');
                }
            } else {
                filterObj = undefined;
            }

            dispatch(receiveGroupsStarted(limit, offset, search, ordering, filter));

            axios.get(Utils.generateApiUrl('groups', {
                limit,
                offset,
                search,
                ordering,
                ...filterObj,
            }), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response): void => {
                    if (response.data.results) {
                        dispatch(receiveGroupsFinished(response.data.results as Group[], response.data.count));
                    } else {
                        const groups = response.data as Group[];

                        dispatch(receiveGroupsFinished(groups, groups.length));
                    }
                })
                .catch((error): void => {
                    dispatch(receiveGroupsFinishedWithError());

                    Utils.handleError(error);
                });
        };

    public static addMember = (group: Group, member: Employee): (dispatch) => void =>
        (dispatch: Dispatch): void => {
            axios.post(Utils.generateApiUrl(`groups/${group.id}/members`), {
                id: member.id,
            }, {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((): void => {
                    SystemNotificationService.pushInfo(`${member.first_name} ${member.last_name} was successfully added to ${group.display_name} group`)(dispatch);
                })
                .catch(Utils.handleError);
        };

    public static removeMember = (group: Group, member: Employee): (dispatch) => void =>
        (dispatch: Dispatch): void => {
            axios.delete(Utils.generateApiUrl(`groups/${group.id}/members/${member.id}`), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((): void => {
                    SystemNotificationService.pushInfo(`${member.first_name} ${member.last_name} was successfully removed from ${group.display_name} group`)(dispatch);
                })
                .catch(Utils.handleError);
        };

    public static update = (group: Group): (dispatch) => void =>
        (dispatch: Dispatch): void => {
            axios.put(Utils.generateApiUrl(`groups/${group.id}`), {
                short_name: group.short_name,
                display_name: group.display_name,
                lead: group.lead.id,
                type: group.type.id,
                status: group.status.id,
                vacation_priority: group.vacation_priority,
            }, {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((): void => {
                    SystemNotificationService.pushInfo(`${group.display_name} group was successfully updated`)(dispatch);
                })
                .catch(Utils.handleError);
        };

    public static create = (group: Group): (dispatch) => void =>
        (dispatch: Dispatch): void => {
            axios.post(Utils.generateApiUrl('groups'), {
                short_name: group.short_name,
                display_name: group.display_name,
                lead: group.lead.id,
                type: group.type.id,
                status: group.status.id,
                vacation_priority: group.vacation_priority,
            }, {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((): void => {
                    SystemNotificationService.pushInfo(`${group.display_name} group was successfully created`)(dispatch);
                })
                .catch(Utils.handleError);
        };

    public static remove = (group: Group): (dispatch) => void =>
        (dispatch: Dispatch): void => {
            axios.delete(Utils.generateApiUrl(`groups/${group.id}`), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((): void => {
                    SystemNotificationService.pushInfo(`${group.display_name} group was successfully removed`)(dispatch);
                })
                .catch(Utils.handleError);
        };

    public static receiveMembers = (groupId: number): (dispatch) => void =>
        (dispatch: Dispatch): void => {
            dispatch(receiveGroupMembersStarted(groupId));

            axios.get(Utils.generateApiUrl(`groups/${groupId}/members`), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response): void => {
                    dispatch(receiveGroupMembersFinished(groupId, response.data as Employee[]));
                })
                .catch((error): void => {
                    dispatch(receiveGroupsFinishedWithError());

                    Utils.handleError(error);
                });
        };
}

export default GroupService;
