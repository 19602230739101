import {
    AppBar,
    BottomNavigation,
    BottomNavigationAction,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import {BrowserView, isMobile, MobileView} from 'react-device-detect';
import Vacation from '../models/Vacation';
import EmployeeItem from './EmployeeItem';


export interface VacationsListDialogProps {
    open: boolean;
    onClose: () => void;
    date: moment.Moment;
    vacations: Vacation[];
    onSelectVacation: (Vacation) => void;
}


export default class VacationsListDialog extends React.Component<VacationsListDialogProps> {
    public render(): React.ReactElement {
        return (
            <Dialog
                open={this.props.open}
                maxWidth="lg"
                fullScreen={isMobile}
                onClose={this.props.onClose}
            >
                <BrowserView>
                    <DialogTitle>
                        {`List of vacations including ${moment(this.props.date).format('YYYY/MM/DD')}`}
                    </DialogTitle>
                </BrowserView>

                <MobileView>
                    <AppBar position="static">
                        <Toolbar style={{minHeight: '52px'}}>
                            <Typography variant="h6" style={{marginLeft: '16px'}}>
                                {`${moment(this.props.date).format('YYYY/MM/DD')}: Vacations`}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </MobileView>

                <DialogContent style={isMobile ? {padding: '8px 0 0 0'} : {padding: 0}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                    Employee
                                </TableCell>

                                <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                    Reason
                                </TableCell>

                                <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                    Start
                                </TableCell>

                                <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                    End
                                </TableCell>

                                {
                                    !isMobile &&
                                    <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                        Comment
                                    </TableCell>
                                }

                                {
                                    !isMobile &&
                                    <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                        Status
                                    </TableCell>
                                }
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                this.props.vacations
                                    .filter(
                                        (vacation): boolean =>
                                            vacation.start <= this.props.date &&
                                            this.props.date <= vacation.end &&
                                            vacation.employee.status.short_name == 'active',
                                    )
                                    .map(
                                        (vacation): React.ReactElement =>
                                            <TableRow
                                                hover
                                                key={`vacation-${vacation.id}`}
                                                style={{cursor: 'pointer'}}
                                                onClick={(): void => this.props.onSelectVacation(vacation)}
                                            >
                                                <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                                    <EmployeeItem employee={vacation.employee}/>
                                                </TableCell>

                                                <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                                    {vacation.reason.display_name}
                                                </TableCell>

                                                <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                                    {moment(vacation.start).format('YYYY/MM/DD')}
                                                </TableCell>

                                                <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                                    {moment(vacation.end).format('YYYY/MM/DD')}
                                                </TableCell>

                                                {
                                                    !isMobile &&
                                                    <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                                        {vacation.comment}
                                                    </TableCell>
                                                }

                                                {
                                                    !isMobile &&
                                                    <TableCell className="fs-12 p-l-6 p-r-6 p-t-4 p-b-4">
                                                        {vacation.status.display_name}
                                                    </TableCell>
                                                }
                                            </TableRow>,
                                    )
                            }
                        </TableBody>
                    </Table>
                </DialogContent>

                <BrowserView>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={this.props.onClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </BrowserView>

                <MobileView>
                    <BottomNavigation showLabels>
                        <BottomNavigationAction
                            label="Close"
                            icon={<Icons.Cancel/>}
                            focusRipple={false}
                            onClick={this.props.onClose}
                        />
                    </BottomNavigation>
                </MobileView>
            </Dialog>
        );
    }
}
