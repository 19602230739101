import {
    CircularProgress,
    Fade,
    Grid,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import Select from "react-select";
import {Option} from "react-select/lib/filters";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import deepEqual from 'deep-equal';
import {WithSnackbarProps} from 'notistack';
import React from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import {isBrowser, isMobile} from 'react-device-detect';
import '../assets/scss/home.scss';
import '../assets/scss/react-big-calendar.scss';
import '../assets/scss/react-yearly-calendar.scss';
import EmployeesFilter from '../containers/EmployeesFilter';
import GroupsDialog from '../containers/GroupsDialog';
import ManageEmployeeRolesDialog from '../containers/ManageEmployeeRolesDialog';
import ManageGroupEmployeesDialog from '../containers/ManageGroupEmployeesDialog';
import history from '../history';
import Employee, {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';
import EmployeeStatus from "../models/EmployeeStatus";
import EmployeeAvatar from './EmployeeAvatar';
import {EmployeesFilterValues} from './EmployeesFilter';


type EmployeeComparator = (a: Employee, b: Employee) => number;
type OrderDirection = 'asc' | 'desc';
type OrderBy = 'full_name' | 'email' | 'mobile_phone' | 'employment_date' | 'status' | 'groups_count' | 'roles_count';

export interface EmployeesStateToProps extends EmployeeComponentWrapperStateToProps {
    employeeStatuses: EmployeeStatus[];
}

export interface EmployeesDispatchToProps extends EmployeeComponentWrapperDispatchToProps {
    updateEmployee: (employee: Employee, avatar?: File) => void;
    receiveEmployeeStatuses: () => void;
}

export type EmployeesProps = EmployeesStateToProps & EmployeesDispatchToProps;

export interface EmployeesState {
    employeeRolesDialog: {
        open: boolean;
        employee?: Employee;
    };
    order: OrderDirection;
    orderBy: OrderBy;
    searchValue: string;
    inputValue: string;
    page: number;
    rowsPerPage: number;
    employeeGroupsDialog: {
        open: boolean;
        groups?: Group[];
    };
    groupEmployeesDialog: {
        open: boolean;
        group?: Group;
    };
    employeesFilterValues: EmployeesFilterValues;
}

export default class EmployeesPage extends React.Component<EmployeesProps & WithSnackbarProps, EmployeesState> implements EmployeeComponentWrapper {
    public headRows = [
        {id: 'full_name', numeric: false, disablePadding: false, label: 'Full Name'},
        {id: 'email', numeric: false, disablePadding: false, label: 'Email', onlyForDesktop: true},
        {id: 'mobile_phone', numeric: true, disablePadding: false, label: 'Mobile', onlyForDesktop: true},
        {id: 'employment_date', numeric: true, disablePadding: false, label: 'Employment Date', onlyForDesktop: true},
        {id: 'status', numeric: false, disablePadding: false, label: 'Status', onlyForDesktop: true},
        {id: 'groups_count', numeric: true, disablePadding: false, label: 'Groups', onlyForDesktop: true},
        {id: 'roles_count', numeric: true, disablePadding: false, label: 'Roles', onlyForDesktop: true},
    ];

    public constructor(props: EmployeesProps & WithSnackbarProps) {
        super(props);

        this.state = {
            employeeRolesDialog: {
                open: false,
            },
            order: 'asc',
            orderBy: 'full_name',
            searchValue: '',
            inputValue: '',
            page: 0,
            rowsPerPage: 15,
            employeeGroupsDialog: {
                open: false,
            },
            groupEmployeesDialog: {
                open: false,
            },
            employeesFilterValues: {
                employeeStatusesIds: [1],
            },
        };
    }

    public componentDidMount(): void {
        this.props.receiveEmployeeStatuses();
        this.props.receiveEmployees(
            this.state.rowsPerPage,
            this.state.page * this.state.rowsPerPage,
            this.state.searchValue,
            `${this.state.order === 'asc' ? '' : '-'}${this.state.orderBy}`,
            this.state.employeesFilterValues,
        );
    }

    public componentDidUpdate(prevProps: Readonly<EmployeesProps & WithSnackbarProps>, prevState: Readonly<EmployeesState>): void {
        if (
            this.state.page != prevState.page ||
            this.state.rowsPerPage != prevState.rowsPerPage ||
            this.state.searchValue != prevState.searchValue ||
            this.state.orderBy != prevState.orderBy ||
            this.state.order != prevState.order ||
            (this.state.employeesFilterValues && this.state.employeesFilterValues != prevState.employeesFilterValues)
        ) {
            this.props.receiveEmployees(
                this.state.rowsPerPage,
                this.state.page * this.state.rowsPerPage,
                this.state.searchValue,
                `${this.state.order === 'asc' ? '' : '-'}${this.state.orderBy}`,
                this.state.employeesFilterValues,
            );
        }

        if (prevProps.employees != this.props.employees) {
            this.props.employees.forEach((employee: Employee): void => this.props.receiveEmployeeGroups(employee.id));
        }

        if (
            this.props.employees != undefined &&
            this.state.employeeRolesDialog.employee != undefined
        ) {
            let updatedEmployee = this.props.employees.find(
                (employee): boolean => employee.id === this.state.employeeRolesDialog.employee.id,
            );

            if (!deepEqual(this.state.employeeRolesDialog.employee, updatedEmployee)) {
                this.setState({
                    employeeRolesDialog: {
                        ...this.state.employeeRolesDialog,
                        employee: updatedEmployee,
                    },
                });
            }
        }
    }

    public handleKeyDown = (event): void => {
        if (event.key === 'Enter') {
            this.setState({
                inputValue: event.target.value,
                searchValue: event.target.value.length === 0 ? '' : this.state.inputValue,
                page: 0,
            });
        }
    };

    public renderTablePagination = (): React.ReactElement => {
        return <TablePagination
            rowsPerPageOptions={this.props.employees.length !== 0 ? [5, 10, 15, 30, 45, 100] : []}
            component="div"
            labelRowsPerPage={!isMobile ? 'Rows per page:' : 'Rows:'}
            count={this.props.employeesCount || 0}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={(event: object, newPage: number): void => this.setState({page: newPage})}
            onChangeRowsPerPage={
                (event): void => {
                    this.setState({
                        rowsPerPage: +event.target.value,
                        page: 0,
                    });
                }
            }
        />;
    };

    public render(): React.ReactElement {
        const dataWasLoaded =
            this.props.employees != undefined &&
            this.props.me != undefined;

        const {order, orderBy} = this.state;

        return (
            <Grid className="wrapper">
                <Grid className="spinner">
                    <Fade
                        in={!dataWasLoaded}
                        unmountOnExit
                    >
                        <CircularProgress
                            color="inherit"
                            size={80}
                        />
                    </Fade>
                </Grid>

                <Grid className={!dataWasLoaded ? 'data-not-loaded' : 'data-loaded'}>
                    <Grid className="sidenav-wrapper" style={{overflow: 'auto'}}>
                        <Toolbar className="toolbar">
                            <Grid container direction="column">
                                <Grid item container>
                                    <EmployeesFilter
                                        employeeSearch
                                        employeesStatusesFilter
                                        defaultValue={this.state.employeesFilterValues}
                                        onChange={
                                            (filter): void => {
                                                this.setState({
                                                    employeesFilterValues: {
                                                        ...filter,
                                                    },
                                                });
                                            }
                                        }
                                    />
                                </Grid>

                                {this.renderTablePagination()}
                            </Grid>
                        </Toolbar>
                        {
                            dataWasLoaded &&
                            <React.Fragment>
                                {
                                    this.props.employees.length === 0 ?
                                        <Typography variant="subtitle1" style={{marginLeft: '25px'}}>
                                            No matches found
                                        </Typography> : (
                                            <Table>
                                                <colgroup>
                                                    <col style={{width: '1%'}}/>
                                                    <col/>
                                                    {isBrowser && <col style={{width: '1%'}}/>}
                                                    {isBrowser && <col style={{width: '1%'}}/>}
                                                    {isBrowser && <col style={{width: '1%'}}/>}
                                                    {isBrowser && <col style={{width: '1%'}}/>}
                                                    {isBrowser && <col style={{width: '1%'}}/>}
                                                    {isBrowser && <col style={{width: '1%'}}/>}
                                                    <col style={{width: '1%'}}/>
                                                    {isMobile && <col style={{width: '1%'}}/>}
                                                </colgroup>

                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className={isMobile ? 'p-10' : ''}>Avatar</TableCell>
                                                        {
                                                            this.headRows
                                                                .filter((row): boolean => isBrowser || !row.onlyForDesktop)
                                                                .map(
                                                                    (row): React.ReactElement => (
                                                                        <TableCell
                                                                            key={row.id}
                                                                            sortDirection={orderBy === row.id ? order : false}
                                                                        >
                                                                            <TableSortLabel
                                                                                active={orderBy === row.id}
                                                                                direction={order}
                                                                                onClick={(): void => this.setState({
                                                                                    order: this.state.orderBy === row.id ?
                                                                                        order === 'asc' ? 'desc' : 'asc'
                                                                                        : 'asc',
                                                                                    orderBy: row.id as OrderBy,
                                                                                })}
                                                                            >
                                                                                {row.label}
                                                                            </TableSortLabel>
                                                                        </TableCell>
                                                                    ))
                                                        }
                                                        {isMobile && <TableCell/>}
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {
                                                        this.props.employees
                                                            .map((employee): React.ReactElement => {
                                                                return (
                                                                    <TableRow key={`employee-${employee.id}`}>
                                                                        <TableCell className={isMobile ? 'p-10' : ''}>
                                                                            <EmployeeAvatar
                                                                                employee={employee}
                                                                                onClick={(): void => history.push(`/profile-${employee.id}`)}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    width: '26px',
                                                                                    height: '26px',
                                                                                    fontSize: '12px',
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell className={isMobile ? 'p-10' : ''}>
                                                                            <Link
                                                                                component="button"
                                                                                color="inherit"
                                                                                variant="subtitle2"
                                                                                underline="none"
                                                                                disabled={
                                                                                    !(
                                                                                        this.props.me.id === employee.id ||
                                                                                        this.isAdmin(this.props.me.id) ||
                                                                                        (this.props.employeeGroups[employee.id] || []).some(
                                                                                            (group): boolean =>
                                                                                                group.lead.id === this.props.me.id,
                                                                                        )
                                                                                    )
                                                                                }
                                                                                onClick={(): void => history.push(`/profile-${employee.id}`)}
                                                                                style={{
                                                                                    wordBreak: 'break-all',
                                                                                    textAlign: 'left',
                                                                                }}
                                                                            >
                                                                                {employee.last_name} {employee.first_name}
                                                                            </Link>
                                                                        </TableCell>
                                                                        {
                                                                            isBrowser &&
                                                                            <TableCell>{employee.email}</TableCell>
                                                                        }
                                                                        {
                                                                            isBrowser &&
                                                                            <TableCell>{employee.mobile_phone}</TableCell>
                                                                        }
                                                                        {
                                                                            isBrowser &&
                                                                            <TableCell>{employee.employment_date}</TableCell>
                                                                        }
                                                                        {
                                                                            isBrowser &&
                                                                            <TableCell>
                                                                                {
                                                                                    this.isAdmin(this.props.me.id) &&
                                                                                    <div style={{minWidth: "100px"}}>
                                                                                        <Select
                                                                                            isClearable={false}
                                                                                            isSearchable={false}
                                                                                            name="user_status"
                                                                                            className="basic-single"
                                                                                            noOptionsMessage={(): string => 'No matches found'}
                                                                                            onChange={(selectedOptions): void => {
                                                                                                employee.status.id = selectedOptions.data.id;
                                                                                                this.props.updateEmployee(
                                                                                                    employee,
                                                                                                    undefined,
                                                                                                );
                                                                                            }}
                                                                                            value={
                                                                                                this.getEmployeesStatusesOptions([employee.status.id] ||
                                                                                                    [])
                                                                                            }
                                                                                            options={
                                                                                                this.getEmployeesStatusesOptions(
                                                                                                    this.props.employeeStatuses
                                                                                                        .sort(
                                                                                                            (a, b): number =>
                                                                                                                a.display_name.localeCompare(b.display_name),
                                                                                                        )
                                                                                                        .map((employeeStatus): number => employeeStatus.id),
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    !this.isAdmin(this.props.me.id) &&
                                                                                    <span>{employee.status.display_name}</span>
                                                                                }
                                                                            </TableCell>
                                                                        }
                                                                        {
                                                                            isBrowser &&
                                                                            <TableCell>
                                                                                <Typography
                                                                                    className={
                                                                                        (
                                                                                            this.isAdmin(this.props.me.id) ||
                                                                                            (this.props.employeeGroups[employee.id] || []).some(
                                                                                                (group): boolean =>
                                                                                                    group.lead.id === this.props.me.id,
                                                                                            )
                                                                                        ) ? 'pointer' : ''
                                                                                    }
                                                                                    style={{
                                                                                        whiteSpace: 'nowrap',
                                                                                        marginRight: '5px',
                                                                                        ...(isMobile ? {padding: '0'} : {padding: '12px'}),
                                                                                        fontSize: '14px',
                                                                                    }}
                                                                                    onClick={(): void => {
                                                                                        if (
                                                                                            this.isAdmin(this.props.me.id) ||
                                                                                            (this.props.employeeGroups[employee.id] || []).some(
                                                                                                (group): boolean =>
                                                                                                    group.lead.id === this.props.me.id,
                                                                                            )
                                                                                        ) {
                                                                                            if (employee.groups_count == 1) {
                                                                                                this.setState({
                                                                                                    groupEmployeesDialog: {
                                                                                                        open: true,
                                                                                                        group: this.getEmployeeGroups(employee.id)[0],
                                                                                                    },
                                                                                                });
                                                                                            } else if (employee.groups_count > 1) {
                                                                                                this.setState({
                                                                                                    employeeGroupsDialog: {
                                                                                                        open: true,
                                                                                                        groups: this.getEmployeeGroups(employee.id),
                                                                                                    },
                                                                                                });
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {employee.groups_count}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        }
                                                                        {
                                                                            isBrowser &&
                                                                            <TableCell
                                                                                style={this.isAdmin(this.props.me.id) ? {cursor: 'pointer'} : {}}
                                                                                onClick={(): void => {
                                                                                    if (this.isAdmin(this.props.me.id)) {
                                                                                        this.setState({
                                                                                            employeeRolesDialog: {
                                                                                                open: true,
                                                                                                employee,
                                                                                            },
                                                                                        });
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {employee.roles_count}
                                                                            </TableCell>
                                                                        }
                                                                        {
                                                                            isMobile &&
                                                                            <TableCell
                                                                                padding="checkbox"
                                                                                className={isMobile ? 'p-10' : ''}
                                                                            >
                                                                                {
                                                                                    this.isAdmin(this.props.me.id) && (
                                                                                        <Grid
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'row',
                                                                                            }}
                                                                                        >
                                                                                            <Tooltip
                                                                                                title={isBrowser ? 'Manage Roles' : ''}
                                                                                                placement="left"
                                                                                            >
                                                                                                <IconButton
                                                                                                    disableFocusRipple
                                                                                                    style={{
                                                                                                        whiteSpace: 'nowrap',
                                                                                                        marginRight: '5px',
                                                                                                        ...(isMobile ? {padding: '0'} : {padding: '12px'}),
                                                                                                    }}
                                                                                                    onClick={(): void => {
                                                                                                        this.setState({
                                                                                                            employeeRolesDialog: {
                                                                                                                open: true,
                                                                                                                employee,
                                                                                                            },
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    <SupervisorAccountIcon/>
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Grid>
                                                                                    )
                                                                                }
                                                                            </TableCell>
                                                                        }
                                                                    </TableRow>
                                                                );
                                                            })
                                                    }
                                                </TableBody>
                                            </Table>
                                        )
                                }
                            </React.Fragment>
                        }

                        {this.renderTablePagination()}

                        <ManageEmployeeRolesDialog
                            open={this.state.employeeRolesDialog.open}
                            employee={this.state.employeeRolesDialog.employee}
                            onClose={(): void => {
                                this.setState({
                                    employeeRolesDialog: {
                                        open: false,
                                    },
                                });
                            }}
                        />

                        <GroupsDialog
                            open={this.state.employeeGroupsDialog.open}
                            groups={this.state.employeeGroupsDialog.groups}
                            onClose={(): void => {
                                this.setState({
                                    employeeGroupsDialog: {
                                        open: false,
                                    },
                                });
                            }}
                            onSelectGroup={(group): void => {
                                this.setState({
                                    groupEmployeesDialog: {
                                        open: true,
                                        group,
                                    },
                                });
                            }}
                        />

                        <ManageGroupEmployeesDialog
                            open={this.state.groupEmployeesDialog.open}
                            group={this.state.groupEmployeesDialog.group}
                            onClose={(): void => {
                                this.setState({
                                    groupEmployeesDialog: {
                                        open: false,
                                    },
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );

    private getEmployeesStatusesOptions = (employeeStatusesIds: number[]): Option[] =>
        employeeStatusesIds
            .map((employeeStatusId): Option => {
                const employeeStatus = this.props.employeeStatuses.find((employeeStatus): boolean => employeeStatus.id === employeeStatusId);

                if (employeeStatus) {
                    return {
                        label: `${employeeStatus.display_name}`,
                        value: employeeStatus.short_name,
                        data: employeeStatus,
                    };
                } else {
                    return {
                        label: '',
                        value: '',
                        data: null,
                    };
                }
            })
            .filter((option: Option): boolean => !!option.data);
}
