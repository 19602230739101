import {
    AppBar,
    BottomNavigation,
    BottomNavigationAction,
    CircularProgress,
    Dialog,
    Fade,
    Grid,
    Toolbar,
    Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import * as Icons from '@material-ui/icons';
import lodash from 'lodash';
import {WithSnackbarProps} from 'notistack';
import React from 'react';
import {BrowserView, isMobile, MobileView} from 'react-device-detect';
import {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group from '../models/Group';
import GroupStatus from '../models/GroupStatus';
import GroupType from '../models/GroupType';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';
import EmployeeItem from './EmployeeItem';


export const vacationPriorityList = ['Lowest', 'Low', 'Medium', 'High', 'Highest'];

export interface GroupDialogStateToProps extends EmployeeComponentWrapperStateToProps {
    groupTypes: GroupType[];
    groupStatuses: GroupStatus[];
}

export interface GroupDialogDispatchToProps extends EmployeeComponentWrapperDispatchToProps {
    updateGroup: (group: Group) => void;
    createGroup: (group: Group) => void;
}

export type GroupDialogProps = GroupDialogStateToProps & GroupDialogDispatchToProps & {
    open: boolean;
    group?: Group;
    onClose?: () => void;
};

export interface GroupDialogState {
    open: boolean;
    group?: Group;
    submitDialog: {
        title: string;
        open: boolean;
        onYesClicked: () => void;
        onNoClicked: () => void;
    };
    dataWasChanged: boolean;
}

export default class GroupDialog extends React.Component<GroupDialogProps & WithSnackbarProps, GroupDialogState> implements EmployeeComponentWrapper {
    public constructor(props: GroupDialogProps & WithSnackbarProps) {
        super(props);

        this.state = {
            open: false,
            submitDialog: {
                title: '',
                open: false,
                onYesClicked: null,
                onNoClicked: null,
            },
            dataWasChanged: false,
        };
    }

    public componentDidUpdate(prevProps: GroupDialogProps & WithSnackbarProps): void {
        if (this.props.open !== prevProps.open) {
            if (this.props.open) {
                if (this.props.group) {
                    this.setState({
                        group: this.props.group,
                        dataWasChanged: false,
                    });
                } else {
                    this.setState({
                        group: {
                            id: undefined,
                            short_name: '',
                            display_name: '',
                            lead: this.props.me,
                            type: this.props.groupTypes.find((groupType): boolean => groupType.short_name === 'common'),
                            status: this.props.groupStatuses.find((groupStatus): boolean => groupStatus.short_name === 'enabled'),
                            vacation_priority: 0,
                            members_count: 0,
                            created_at: undefined,
                            updated_at: undefined,
                        },
                        dataWasChanged: false,
                    });
                }
            } else {
                this.setState({
                    group: undefined,
                    dataWasChanged: false,
                });
            }
        }
    }

    public updateGroup = (): void => {
        this.props.updateGroup(this.state.group);
        this.props.onClose();
    };

    public createGroup = (): void => {
        this.props.createGroup(this.state.group);
        this.props.onClose();
    };

    public onClose = (): void => {
        this.setState({
            submitDialog: {
                ...this.state.submitDialog,
                title: 'Are you sure you want to cancel the changes you made?',
                open: this.state.dataWasChanged,
                onYesClicked: (): void => {
                    this.setState({
                        submitDialog: {
                            ...this.state.submitDialog,
                            open: false,
                        },
                    });
                    this.props.onClose();
                },
                onNoClicked: (): void => this.setState({
                    submitDialog: {
                        ...this.state.submitDialog,
                        open: false,
                    },
                }),
            },
        });

        if (!this.state.dataWasChanged) {
            this.props.onClose();
        }
    };

    public render = (): React.ReactElement => {
        if (
            !this.props.me ||
            !this.state.group
        ) {
            return <React.Fragment/>;
        }

        const dataWasLoaded =
            this.props.groupStatuses != undefined &&
            this.props.groupTypes != undefined;

        const createNewGroup = (!this.state.group || this.state.group.id == undefined);

        return (
            <Grid>
                <Dialog
                    open={this.props.open}
                    fullScreen={isMobile}
                    onClose={this.onClose}
                >
                    <BrowserView>
                        <DialogTitle>
                            {
                                createNewGroup ?
                                    `Add Group` :
                                    this.state.group && `Edit ${this.state.group.display_name} Group`
                            }
                        </DialogTitle>
                    </BrowserView>

                    <MobileView>
                        <AppBar position="static">
                            <Toolbar style={{minHeight: '52px'}}>
                                <Typography variant="h6" style={{marginLeft: '16px'}}>
                                    {
                                        createNewGroup ?
                                            `Add Group` :
                                            this.state.group && `Edit ${this.state.group.display_name} Group`
                                    }
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </MobileView>

                    {
                        !dataWasLoaded ?
                            <Grid className="spinner">
                                <Fade
                                    in={!dataWasLoaded}
                                    unmountOnExit
                                >
                                    <CircularProgress
                                        color="inherit"
                                        size={80}
                                    />
                                </Fade>
                            </Grid> :
                            <React.Fragment>
                                <DialogContent style={isMobile ? {paddingTop: '16px'} : {}}>
                                    <TextField
                                        label="Short Name"
                                        inputProps={{
                                            maxLength: 25,
                                        }}
                                        value={this.state.group ? this.state.group.short_name : ''}
                                        style={{marginBottom: '15px'}}
                                        onChange={
                                            (event): void => {
                                                let regex = new RegExp(/ +/, 'g');

                                                this.setState({
                                                    group: {
                                                        ...this.state.group,
                                                        short_name: event.target.value.replace(regex, ' ').trimLeft(),
                                                    },
                                                    dataWasChanged: true,
                                                });
                                            }
                                        }
                                        fullWidth
                                    />

                                    <TextField
                                        label="Display Name"
                                        inputProps={{
                                            maxLength: 25,
                                        }}
                                        value={this.state.group ? this.state.group.display_name : ''}
                                        style={{marginBottom: '15px'}}
                                        onChange={
                                            (event): void => {
                                                let regex = new RegExp(/ +/, 'g');

                                                this.setState({
                                                    group: {
                                                        ...this.state.group,
                                                        display_name: event.target.value.replace(regex, ' ').trimLeft(),
                                                    },
                                                    dataWasChanged: true,
                                                });
                                            }
                                        }
                                        fullWidth
                                    />

                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="lead">Lead</InputLabel>

                                        <Select
                                            disabled={!this.isAdmin(this.props.me.id)}
                                            value={this.state.group.lead.id}
                                            style={{marginBottom: '15px'}}
                                            inputProps={{
                                                id: 'lead',
                                            }}
                                            renderValue={(employeeId): React.ReactElement => {
                                                const employee = this.props.employees.find(
                                                    (employee): boolean =>
                                                        employee.id === employeeId,
                                                );

                                                return (
                                                    <EmployeeItem
                                                        employee={employee}
                                                        WrapperProps={{
                                                            style: {
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                            },
                                                        }}
                                                    />
                                                );
                                            }}
                                            onChange={
                                                (event): void => {
                                                    this.setState({
                                                        dataWasChanged: true,
                                                        group: {
                                                            ...this.state.group,
                                                            lead: this.props.employees.find(
                                                                (employee): boolean =>
                                                                    employee.id === event.target.value,
                                                            ),
                                                        },
                                                    });
                                                }
                                            }
                                            fullWidth
                                        >
                                            {
                                                this.props.employees
                                                    .sort((a, b): number => {
                                                        let aName = `${a.first_name} ${a.last_name}`;
                                                        let bName = `${b.first_name} ${b.last_name}`;

                                                        return aName.localeCompare(bName);
                                                    })
                                                    .map((employee): React.ReactElement => {
                                                        return (
                                                            <MenuItem
                                                                key={`employee-${employee.id}`}
                                                                value={employee.id}
                                                            >
                                                                <EmployeeItem employee={employee} WrapperProps={{style: {whiteSpace: 'initial'}}}/>
                                                            </MenuItem>
                                                        );
                                                    })
                                            }
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="group_type">Type</InputLabel>

                                        <Select
                                            value={this.state.group.type.id}
                                            style={{marginBottom: '15px'}}
                                            inputProps={{
                                                id: 'group_type',
                                            }}
                                            onChange={
                                                (event): void => {
                                                    this.setState({
                                                        dataWasChanged: true,
                                                        group: {
                                                            ...this.state.group,
                                                            type: this.props.groupTypes.find((groupType): boolean =>
                                                                groupType.id === event.target.value,
                                                            ),
                                                        },
                                                    });
                                                }
                                            }
                                            fullWidth
                                        >
                                            {
                                                this.props.groupTypes
                                                    .sort((a, b): number => a.display_name.localeCompare(b.display_name))
                                                    .map((groupType): React.ReactElement =>
                                                        <MenuItem
                                                            key={`group-type-${groupType.id}`}
                                                            value={groupType.id}
                                                        >
                                                            {groupType.display_name}
                                                        </MenuItem>,
                                                    )
                                            }
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="group_status">Status</InputLabel>
                                        <Select
                                            value={this.state.group.status.id}
                                            style={{marginBottom: '15px'}}
                                            inputProps={{
                                                id: 'group_status',
                                            }}
                                            onChange={
                                                (event): void => {
                                                    this.setState({
                                                        dataWasChanged: true,
                                                        group: {
                                                            ...this.state.group,
                                                            status: this.props.groupStatuses.find((groupStatus): boolean =>
                                                                groupStatus.id === event.target.value,
                                                            ),
                                                        },
                                                    });
                                                }
                                            }
                                            fullWidth
                                        >
                                            {
                                                this.props.groupStatuses
                                                    .sort((a, b): number => a.display_name.localeCompare(b.display_name))
                                                    .map((groupStatus): React.ReactElement =>
                                                        <MenuItem
                                                            key={`group-status-${groupStatus.id}`}
                                                            value={groupStatus.id}
                                                        >
                                                            {groupStatus.display_name}
                                                        </MenuItem>,
                                                    )
                                            }
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="group_status">Vacation Priority</InputLabel>
                                        <Select
                                            value={lodash.min([lodash.max([this.state.group.vacation_priority, 0]), 4])}
                                            style={{marginBottom: '15px'}}
                                            onChange={(event): void => {
                                                let value = parseInt(event.target.value as string) || 0;

                                                if (value > 4) {
                                                    value = 4;
                                                } else if (value < 0) {
                                                    value = 0;
                                                }

                                                this.setState({
                                                    group: {
                                                        ...this.state.group,
                                                        vacation_priority: value,
                                                    },
                                                    dataWasChanged: true,
                                                });
                                            }}
                                            fullWidth
                                        >
                                            {
                                                vacationPriorityList.map(
                                                    (vacationPriority, vacationPriorityIndex): React.ReactElement =>
                                                        <MenuItem
                                                            key={`vacation-priority-${vacationPriorityIndex}`}
                                                            value={vacationPriorityIndex}
                                                        >
                                                            {vacationPriority}
                                                        </MenuItem>,
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </DialogContent>

                                <BrowserView>
                                    <DialogActions className="dialog-buttons-container">
                                        <Button
                                            fullWidth
                                            disableFocusRipple
                                            disabled={
                                                !this.state.group.display_name ||
                                                !this.state.group.short_name ||
                                                this.state.group.vacation_priority < 0 ||
                                                this.state.group.vacation_priority > 4 ||
                                                !this.state.dataWasChanged
                                            }
                                            onClick={createNewGroup ? this.createGroup : this.updateGroup}
                                        >
                                            Save
                                        </Button>

                                        <Button
                                            fullWidth
                                            disableFocusRipple
                                            onClick={this.onClose}
                                        >
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </BrowserView>

                                <MobileView>
                                    <BottomNavigation showLabels>
                                        <BottomNavigationAction
                                            label="Save"
                                            icon={<Icons.Save/>}
                                            focusRipple={false}
                                            onClick={createNewGroup ? this.createGroup : this.updateGroup}
                                        />

                                        <BottomNavigationAction
                                            label="Cancel"
                                            icon={<Icons.Cancel/>}
                                            focusRipple={false}
                                            onClick={this.onClose}
                                        />
                                    </BottomNavigation>
                                </MobileView>
                            </React.Fragment>
                    }
                </Dialog>

                <Dialog
                    open={this.state.submitDialog.open}
                    onClose={(): void => {
                        this.state.submitDialog.onNoClicked();
                    }}
                >
                    <DialogTitle>{this.state.submitDialog.title}</DialogTitle>

                    <DialogActions>
                        <Button
                            fullWidth
                            disableFocusRipple
                            onClick={(): void => this.state.submitDialog.onYesClicked()}
                            color="primary"
                        >
                            Yes
                        </Button>

                        <Button
                            fullWidth
                            disableFocusRipple
                            onClick={(): void => this.state.submitDialog.onNoClicked()}
                            color="primary"
                        >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        );
    };

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
