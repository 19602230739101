import {VacationStatusAction} from '../actions/VacationStatus';
import VacationStatus from '../models/VacationStatus';


export type VacationStatusReducerReceivingTarget =
    'VACATION_STATUSES';

export interface VacationStatusReducerState {
    vacationStatuses: VacationStatus[];
    receivingCounter: number;
}

const initialState: VacationStatusReducerState = {
    vacationStatuses: [],
    receivingCounter: 0,
};

const VacationStatusReducer = (state: VacationStatusReducerState = initialState, action: VacationStatusAction): VacationStatusReducerState => {
    switch (action.type) {
        case 'RECEIVE_VACATION_STATUSES_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_VACATION_STATUSES_FINISHED':
            state.vacationStatuses = action.vacationStatuses;
            state.receivingCounter--;
            break;
    }

    return {...state};
};

export default VacationStatusReducer;
