import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import GroupDialog, {GroupDialogDispatchToProps, GroupDialogStateToProps} from '../components/GroupDialog';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import GroupService from '../services/Group';


const mapStateToProps = (state): GroupDialogStateToProps => ({
    groupTypes: state.GroupType.groupTypes,
    groupStatuses: state.GroupStatus.groupStatuses,
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: GroupDialogDispatchToProps = {
    updateGroup: GroupService.update,
    createGroup: GroupService.create,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(GroupDialog));
