import {Button, CircularProgress, Fade, Grid, Table, TableSortLabel, TextField} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import {WithSnackbarProps} from 'notistack';
import React from 'react';
import {isBrowser, isMobile} from 'react-device-detect';
import '../assets/scss/home.scss';
import Log from '../models/Log';


export interface LogsStateToProps {
    logs: Log[];
    logsCount: number;
}

export interface LogsDispatchToProps {
    receiveLogs: (limit?: number, offset?: number, search?: string, ordering?: string) => void;
}

export type LogsProps = LogsStateToProps & LogsDispatchToProps;

type LogComparator = (a: Log, b: Log) => number;
type OrderDirection = 'asc' | 'desc';
type OrderBy = 'action' | 'subject' | 'object' | 'created_at';

export interface LogsState {
    sidenav: {
        open: boolean;
    };
    page: number;
    rowsPerPage: number;
    order: OrderDirection;
    orderBy: OrderBy;
    searchValue: string;
    inputValue: string;
}

export default class LogsPage extends React.Component<LogsProps & WithSnackbarProps, LogsState> {
    public headRows = [
        {id: 'action', numeric: false, label: 'Action'},
        {id: 'subject', numeric: false, label: 'Subject'},
        {id: 'object', numeric: false, label: 'Object', onlyForDesktop: true},
        {id: 'created_at', numeric: false, label: 'Created At'},
    ];

    public constructor(props: LogsProps & WithSnackbarProps) {
        super(props);

        this.state = {
            sidenav: {
                open: false,
            },
            page: 0,
            rowsPerPage: 15,
            order: 'desc',
            orderBy: 'created_at',
            searchValue: '',
            inputValue: '',
        };
    }

    public componentDidMount(): void {
        this.props.receiveLogs(
            this.state.rowsPerPage,
            this.state.page * this.state.rowsPerPage,
            this.state.searchValue,
            `${this.state.order === 'asc' ? '' : '-'}${this.state.orderBy}`,
        );
    }

    public componentDidUpdate(prevProps: Readonly<LogsProps & WithSnackbarProps>, prevState: Readonly<LogsState>): void {
        if (
            this.state.page != prevState.page ||
            this.state.rowsPerPage != prevState.rowsPerPage ||
            this.state.searchValue != prevState.searchValue ||
            this.state.orderBy != prevState.orderBy ||
            this.state.order != prevState.order
        ) {
            this.props.receiveLogs(
                this.state.rowsPerPage,
                this.state.page * this.state.rowsPerPage,
                this.state.searchValue,
                `${this.state.order === 'asc' ? '' : '-'}${this.state.orderBy}`,
            );
        }
    }

    public renderTablePagination = (): React.ReactElement => {
        return <TablePagination
            rowsPerPageOptions={this.props.logsCount !== 0 ? [5, 10, 15, 30, 45, 100] : []}
            component="div"
            labelRowsPerPage={!isMobile ? 'Rows per page:' : 'Rows:'}
            count={this.props.logsCount}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={(event: object, newPage: number): void => this.setState({page: newPage})}
            onChangeRowsPerPage={
                (event): void => {
                    this.setState({
                        rowsPerPage: +event.target.value,
                        page: 0,
                    });
                }
            }
        />;
    };

    public handleKeyDown = (event): void => {
        if (event.key === 'Enter') {
            this.setState({
                inputValue: event.target.value,
                searchValue: event.target.value.length === 0 ? '' : this.state.inputValue,
                page: 0,
            });
        }
    };

    public render(): React.ReactElement {
        const dataWasLoaded = this.props.logs != undefined;

        return (
            <Grid className="wrapper">
                <Grid className="spinner">
                    <Fade
                        in={!dataWasLoaded}
                        unmountOnExit
                    >
                        <CircularProgress
                            color="inherit"
                            size={80}
                        />
                    </Fade>
                </Grid>

                <Grid className={!dataWasLoaded ? 'data-not-loaded' : 'data-loaded'}>
                    <Grid className="sidenav-wrapper">
                        <Grid container direction="row" wrap="nowrap" className="search-container">
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="search"
                                placeholder="Search..."
                                inputProps={{
                                    maxLength: 25,
                                    style: {
                                        height: '38px',
                                        padding: '0 14px',
                                    },
                                }}
                                onChange={(event): void => {
                                    let regex = new RegExp(/ +/, 'g');
                                    let value = event.target.value;

                                    this.setState({
                                        inputValue: value.replace(regex, ' ').trimLeft(),
                                    });

                                    if (event.target.value.replace(regex, ' ').trimLeft().length === 0) {
                                        this.setState({searchValue: ''});
                                    }
                                }}
                                onKeyDown={this.handleKeyDown}
                                value={this.state.inputValue}
                            />

                            <Button
                                type="button"
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={(): void => this.setState({
                                    searchValue: this.state.inputValue,
                                    page: 0,
                                })}
                            >
                                Apply
                            </Button>
                        </Grid>

                        {this.renderTablePagination()}

                        {
                            dataWasLoaded &&
                            <Table>
                                <colgroup>
                                    <col/>
                                    <col/>
                                    {isBrowser && <col/>}
                                    <col style={isBrowser ? {width: '1%'} : {}}/>
                                </colgroup>
                                <TableHead>
                                    <TableRow>
                                        {
                                            this.headRows
                                                .filter((row): boolean => isBrowser || !row.onlyForDesktop)
                                                .map(
                                                    (row): React.ReactElement =>
                                                        <TableCell
                                                            className={isMobile ? 'p-10' : ''}
                                                            key={`log-${row.id}`}
                                                            sortDirection={this.state.orderBy === row.id ? this.state.order : false}
                                                        >
                                                            <TableSortLabel
                                                                active={this.state.orderBy === row.id}
                                                                direction={this.state.order}
                                                                onClick={(): void => {
                                                                    const isDesc = this.state.order === 'desc';

                                                                    this.setState({
                                                                        order: isDesc ? 'asc' : 'desc',
                                                                        orderBy: row.id as OrderBy,
                                                                    });
                                                                }}
                                                            >
                                                                {row.label}
                                                            </TableSortLabel>
                                                        </TableCell>,
                                                )
                                        }
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        this.props.logs
                                            .map((log: Log): React.ReactElement => (
                                                <TableRow key={`log-${log.id}`}>
                                                    <TableCell
                                                        className={isMobile ? 'p-10' : ''}
                                                    >{log.action}</TableCell>
                                                    <TableCell
                                                        className={isMobile ? 'p-10' : ''}
                                                    >{log.subject}</TableCell>
                                                    {isBrowser && <TableCell>{log.object}</TableCell>}
                                                    <TableCell
                                                        className={isMobile ? 'p-10' : ''}
                                                        style={isMobile ? {whiteSpace: 'pre-line'} : {whiteSpace: 'nowrap'}}
                                                    >
                                                        {moment(log.created_at).format('YYYY-MM-DD HH:mm:ss')}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                    }
                                </TableBody>
                            </Table>
                        }

                        {this.renderTablePagination()}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
