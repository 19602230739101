import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import GroupsDialog from '../components/GroupsDialog';
import {
    EmployeeComponentWrapperDispatchToProps,
    employeeComponentWrapperMapDispatchToProps,
    employeeComponentWrapperMapStateToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';


const mapStateToProps = (state): EmployeeComponentWrapperStateToProps => ({
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: EmployeeComponentWrapperDispatchToProps = {
    ...employeeComponentWrapperMapDispatchToProps,
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(GroupsDialog));
