import {AppBar, Dialog, DialogContent, TableBody, Toolbar, Typography} from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import lodash from 'lodash';
import {WithSnackbarProps} from 'notistack';
import * as React from 'react';
import {BrowserView, isBrowser, isMobile, MobileView} from 'react-device-detect';
import {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';


export const vacationPriorityList = ['Lowest', 'Low', 'Medium', 'High', 'Highest'];

export type GroupsDialogProps = EmployeeComponentWrapperStateToProps & EmployeeComponentWrapperDispatchToProps & {
    open: boolean;
    onClose?: () => void;
    groups: Group[];
    onSelectGroup?: (group: Group) => void;
}

export interface GroupsDialogState {

}

export default class GroupsDialog extends React.Component<GroupsDialogProps & WithSnackbarProps, GroupsDialogState> implements EmployeeComponentWrapper {
    public constructor(props: GroupsDialogProps & WithSnackbarProps) {
        super(props);
    }

    public componentDidMount(): void {
        this.props.receiveMe();
    }

    public render(): React.ReactElement {
        if (!this.props.me) {
            return <React.Fragment/>;
        }

        return (
            <Dialog
                open={this.props.open}
                maxWidth={false}
                onClose={this.props.onClose}
                fullScreen={isMobile}
            >
                <BrowserView>
                    <DialogTitle>User Groups</DialogTitle>
                </BrowserView>

                <MobileView>
                    <AppBar position="static">
                        <Toolbar style={{minHeight: '52px'}}>
                            <Typography variant="h6" style={{marginLeft: '16px'}}>
                                User Groups
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </MobileView>

                <DialogContent style={{padding: 0}}>
                    <Table size={isBrowser ? 'medium' : 'small'}>
                        <TableHead>
                            <TableRow>
                                {isBrowser && <TableCell>Name</TableCell>}
                                <TableCell>Lead</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Vacation Priority</TableCell>
                                <TableCell>Members</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                this.props.groups &&
                                this.props.groups
                                    .map((group): React.ReactElement =>
                                        (
                                            <TableRow
                                                key={`group-${group.id}`}
                                                className={(this.isAdmin(this.props.me.id) || group.lead.id == this.props.me.id) ? 'clickable-row' : ''}
                                                style={(this.isAdmin(this.props.me.id) || group.lead.id == this.props.me.id) ? {cursor: 'pointer'} : {}}
                                                onClick={
                                                    (): void => {
                                                        if (this.isAdmin(this.props.me.id) || group.lead.id == this.props.me.id) {
                                                            this.props.onSelectGroup(group);
                                                        }
                                                    }
                                                }
                                            >
                                                <TableCell>{group.display_name}</TableCell>
                                                <TableCell>{`${group.lead.last_name} ${group.lead.first_name}`}</TableCell>
                                                <TableCell>{group.type.display_name}</TableCell>
                                                <TableCell>{group.status.display_name}</TableCell>
                                                <TableCell>{vacationPriorityList[lodash.min([lodash.max([group.vacation_priority, 0]), 4])]}</TableCell>
                                                <TableCell>{group.members_count}</TableCell>
                                            </TableRow>
                                        ),
                                    )
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        );
    }

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
