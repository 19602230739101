import {connect} from 'react-redux';
import GroupFilter, {GroupFilterDispatchToProps, GroupFilterStateToProps} from '../components/GroupFilter';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import {groupComponentWrapperMapDispatchToProps, groupComponentWrapperMapStateToProps} from '../models/Group';
import EmployeeService from '../services/Employee';
import GroupStatusService from '../services/GroupStatus';
import GroupTypeService from '../services/GroupType';


const mapStateToProps = (state): GroupFilterStateToProps => ({
    groupLeads: state.Employee.employees,
    groupStatuses: state.GroupStatus.groupStatuses,
    groupTypes: state.GroupType.groupTypes,
    ...groupComponentWrapperMapStateToProps(state),
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: GroupFilterDispatchToProps = {
    receiveGroupLeads: EmployeeService.receive,
    receiveGroupStatuses: GroupStatusService.receive,
    receiveGroupTypes: GroupTypeService.receive,
    ...groupComponentWrapperMapDispatchToProps,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupFilter);
