import {Avatar, Box, Chip, Divider, Fade, Grid, IconButton, Tooltip, Typography} from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import lodash from 'lodash';
import moment from 'moment';
import React from 'react';
import '../assets/scss/annual-calendar.scss';
import history from '../history';
import Employee from '../models/Employee';
import Vacation from '../models/Vacation';
import EmployeeAvatar from './EmployeeAvatar';
import EmployeeItem from './EmployeeItem';
import {DisplayMonthFilterOptionValue} from './Filter';


interface Day {
    date: moment.Moment;
    vacations: Vacation[];
    tooltip: string;
    isMore: boolean;
    color: string;
    selected: boolean;
}

interface Week {
    days: Day[];
}

interface Month {
    monthIndex: number;
    weeks: Week[];
    isEmpty: boolean;
}

const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export interface AnnualCalendarProps {
    year: number;
    vacations: Vacation[];
    employeesCount: number;
    displayOption?: DisplayMonthFilterOptionValue;
    onClickDay?: (date: moment.Moment) => void;
    onClickNextYear?: () => void;
    onClickPrevYear?: () => void;
    onSelectInterval?: (start, end) => void;
}

export interface AnnualCalendarState {
    months: Month[];
    currentMonthIndex: number;
    interval: {
        start?: moment.Moment;
        end?: moment.Moment;
        uiInterval?: {
            start?: moment.Moment;
            end?: moment.Moment;
        };
        onSelecting: boolean;
    };
}

export default class AnnualCalendar extends React.Component<AnnualCalendarProps, AnnualCalendarState> {
    public constructor(props) {
        super(props);

        this.state = {
            months: [],
            currentMonthIndex: this.props.displayOption === 'fromNow' ? parseInt(moment().format('MM')) - 1 : 0,
            interval: {
                onSelecting: false,
            },
        };
    }

    public componentDidUpdate(prevProps: Readonly<AnnualCalendarProps>): void {
        if (
            !lodash.isEqual(this.props.vacations, prevProps.vacations) ||
            this.state.months.length === 0 ||
            this.props.year !== prevProps.year
        ) {
            let months: Month[] = [];

            const vacations = this.props.vacations || [];

            for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
                const month: Month = {
                    monthIndex,
                    weeks: [],
                    isEmpty: true,
                };

                let curMoment = moment({year: this.props.year, month: monthIndex, date: 1}).weekday(0);
                let monthEnd =
                    moment({
                        year: this.props.year,
                        month: monthIndex,
                    })
                        .endOf('month');

                while (curMoment <= monthEnd) {
                    const week: Week = {
                        days: [],
                    };

                    for (let i = 0; i < 7; i++) {
                        const dateVacations =
                            vacations
                                .filter((vacation): boolean =>
                                    moment(vacation.start) <= curMoment &&
                                    moment(vacation.end) >= curMoment,
                                );

                        let tooltip = '';

                        dateVacations.forEach((vacation): void => {
                            tooltip += `${vacation.reason.display_name}: ${vacation.employee.last_name} ${vacation.employee.first_name}, ${vacation.status.display_name}\n`;
                        });

                        if (
                            dateVacations.length > 0 &&
                            parseInt(curMoment.format('MM')) - 1 === monthIndex
                        ) {
                            month.isEmpty = false;
                        }

                        const dayLoading = lodash.round(this.props.employeesCount > 0 ? dateVacations.length / this.props.employeesCount : 0, 2);

                        let color = '#9e9e9e';

                        if (dayLoading > 0) {
                            if (dayLoading <= 0.33) {
                                color = '#4caf50';
                            } else if (dayLoading <= 0.67) {
                                color = '#ffc107';
                            } else {
                                color = '#f44336';
                            }
                        }

                        const day: Day = {
                            date: curMoment.clone(),
                            vacations: dateVacations,
                            tooltip: tooltip,
                            isMore: dateVacations.length > 5,
                            selected: false,
                            color,
                        };

                        week.days.push(day);

                        curMoment = curMoment.add(1, 'days');
                    }

                    month.weeks.push(week);
                }

                months.push(month);
            }

            this.setState({
                months,
            });
        }

        if (
            this.props.displayOption != prevProps.displayOption ||
            this.props.year != prevProps.year
        ) {
            let currentMonthIndex = 0;

            if (
                this.props.displayOption === 'fromNow' &&
                parseInt(moment().format('YYYY')) === this.props.year
            ) {
                currentMonthIndex = parseInt(moment().format('MM')) - 1;
            }

            this.setState({
                currentMonthIndex,
            });
        }
    }

    public openProfile = (employee: Employee): void => {
        history.push(`/profile-${employee.id}`);
    };

    public startSelecting = (day: Day): void => {
        this.setState({
            interval: {
                start: day.date,
                end: day.date,
                uiInterval: {
                    start: day.date,
                    end: day.date,
                },
                onSelecting: true,
            },
        });
    };

    public setSelectingEnd = (day: Day): void => {
        if (this.state.interval.onSelecting) {
            this.setState({
                interval: {
                    ...this.state.interval,
                    uiInterval: {
                        start: moment.min(this.state.interval.start, day.date),
                        end: moment.max(this.state.interval.start, day.date),
                    },
                    end: day.date,
                },
            });
        }
    };

    public endSelecting = (): void => {
        const start = this.state.interval.uiInterval.start;
        const end = this.state.interval.uiInterval.end;

        this.setState({
            interval: {
                onSelecting: false,
            },
        });

        if (start !== end) {
            this.props.onSelectInterval && this.props.onSelectInterval(start, end);
        }
    };

    public render(): React.ReactElement {
        let chips = {};
        let dayRendered = false;
        let dayAdded = false;

        return (
            <Grid container direction="column" style={{marginBottom: '56px'}}>
                <Grid item container justify="center" alignItems="center">
                    <Grid item>
                        <IconButton
                            disableFocusRipple
                            onClick={this.props.onClickPrevYear}
                        >
                            <ArrowLeftIcon/>
                        </IconButton>
                    </Grid>

                    <Grid item>
                        <Box margin="10px">
                            <Typography variant="h4">
                                {this.props.year}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item>
                        <IconButton
                            disableFocusRipple
                            onClick={this.props.onClickNextYear}
                        >
                            <ArrowRightIcon/>
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid item container direction="row" wrap="wrap" justify="center" className="disable-select">
                    {
                        this.state.months.map(
                            (month, monthIndex): React.ReactElement =>
                                monthIndex >= this.state.currentMonthIndex &&
                                (
                                    this.props.displayOption !== 'onlyNonEmptyMonths' ||
                                    !month.isEmpty
                                ) &&
                                <Grid key={`month-${monthIndex}`} item>
                                    <Box padding="15px">
                                        <Grid container direction="column">
                                            <Grid item container justify="center">
                                                <Typography variant="h6">
                                                    {`${lodash.padStart(`${monthIndex + 1}`, 2, '0')}. ${monthNames[month.monthIndex]}`}
                                                </Typography>
                                            </Grid>

                                            <Grid item container direction="row" wrap="nowrap">
                                                {
                                                    dayNames.map(
                                                        (dayName, dayNameIndex): React.ReactElement =>
                                                            <Grid
                                                                key={`day-${monthIndex}-${dayNameIndex}`} item xs
                                                                container
                                                            >
                                                                <Box width="100%" textAlign="center" padding="5px">
                                                                    {dayName}
                                                                </Box>
                                                            </Grid>,
                                                    )
                                                }
                                            </Grid>

                                            {
                                                month.weeks.map(
                                                    (week, weekIndex): React.ReactElement =>
                                                        <Grid
                                                            key={`week-${monthIndex}-${weekIndex}`}
                                                            item
                                                            container
                                                            direction="row"
                                                            wrap="nowrap"
                                                        >
                                                            {
                                                                week.days.map(
                                                                    (day, dayIndex): React.ReactElement => {
                                                                        dayRendered = true;
                                                                        dayAdded = false;

                                                                        return <Grid

                                                                            key={`day-${monthIndex}-${weekIndex}-${dayIndex}`}
                                                                            item
                                                                            xs
                                                                            container
                                                                            justify="center"
                                                                        >
                                                                            <Tooltip
                                                                                title={
                                                                                    day.vacations.length > 0 ?
                                                                                        <Grid container>
                                                                                            {
                                                                                                day.vacations.map(
                                                                                                    (vacation, vacationIndex): React.ReactElement => {
                                                                                                        if (
                                                                                                            !dayAdded &&
                                                                                                            parseInt(day.date.format('MM')) - 1 == monthIndex &&
                                                                                                            vacation.status.short_name !== 'rejected'
                                                                                                        ) {
                                                                                                            if (vacation.employee.id in chips) {
                                                                                                                chips[vacation.employee.id].count++;
                                                                                                            } else {
                                                                                                                chips[vacation.employee.id] = {
                                                                                                                    employee: vacation.employee,
                                                                                                                    count: 1,
                                                                                                                };
                                                                                                            }

                                                                                                            dayAdded = true;
                                                                                                        }

                                                                                                        if (vacationIndex < 10) {
                                                                                                            return <Grid
                                                                                                                container
                                                                                                                key={`vacation-${monthIndex}-${weekIndex}-${dayIndex}-${vacationIndex}`}
                                                                                                            >
                                                                                                                <EmployeeItem
                                                                                                                    employee={vacation.employee}
                                                                                                                >
                                                                                                                    {`, ${vacation.reason.display_name}, ${vacation.comment}, ${vacation.status.display_name.toUpperCase()}`}
                                                                                                                </EmployeeItem>

                                                                                                                {
                                                                                                                    vacationIndex < day.vacations.length - 1 &&
                                                                                                                    <Divider
                                                                                                                        style={{
                                                                                                                            backgroundColor: '#bdbdbd',
                                                                                                                            width: '100%',
                                                                                                                        }}
                                                                                                                    />
                                                                                                                }
                                                                                                            </Grid>;
                                                                                                        } else if (vacationIndex == 10) {
                                                                                                            return <Box
                                                                                                                key={`vacation-${monthIndex}-${weekIndex}-${dayIndex}-${vacationIndex}`}
                                                                                                                style={{fontSize: '12px'}}
                                                                                                            >
                                                                                                                More...
                                                                                                            </Box>;
                                                                                                        }
                                                                                                    },
                                                                                                )
                                                                                            }
                                                                                        </Grid> : ''
                                                                                }
                                                                                PopperProps={{
                                                                                    style: {
                                                                                        whiteSpace: 'pre-line',
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <Box
                                                                                    width="100%"
                                                                                    paddingTop="7px"
                                                                                    paddingLeft="1px"
                                                                                    paddingRight="1px"
                                                                                    onClick={(): void => this.props.onClickDay && this.props.onClickDay(day.date)}
                                                                                    onMouseDown={(): void => this.startSelecting(day)}
                                                                                    onMouseUp={(): void => this.endSelecting()}
                                                                                    onMouseEnter={(): void => this.setSelectingEnd(day)}
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        cursor: 'pointer',
                                                                                        opacity: parseInt(day.date.format('MM')) - 1 !== monthIndex ? 0.4 : 1,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        day.vacations.length > 0 ?
                                                                                            <Box
                                                                                                position="relative"
                                                                                                width="30px"
                                                                                                height="30px"
                                                                                                margin="7px"
                                                                                                className={
                                                                                                    (
                                                                                                        this.state.interval.uiInterval &&
                                                                                                        this.state.interval.uiInterval.start &&
                                                                                                        this.state.interval.uiInterval.end &&
                                                                                                        day.date >= this.state.interval.uiInterval.start &&
                                                                                                        day.date <= this.state.interval.uiInterval.end
                                                                                                    ) ?
                                                                                                        'selected-day' :
                                                                                                        ''
                                                                                                }
                                                                                                style={{
                                                                                                    borderRadius: '50%',
                                                                                                    backgroundColor: day.color,
                                                                                                }}
                                                                                            >
                                                                                                <Box
                                                                                                    position="absolute"
                                                                                                    top="50%"
                                                                                                    left="50%"
                                                                                                    fontSize="9px"
                                                                                                    style={{
                                                                                                        transform: 'translate(-50%, -50%)',
                                                                                                        fontWeight: 'bold',
                                                                                                    }}
                                                                                                >
                                                                                                    {day.date.format('DD')}
                                                                                                </Box>
                                                                                                {
                                                                                                    day.vacations.map(
                                                                                                        (vacation, vacationIndex): React.ReactElement => {
                                                                                                            const maxVacationsCount = lodash.min([day.vacations.length, 5]);

                                                                                                            if (vacationIndex < maxVacationsCount) {
                                                                                                                return <Grid
                                                                                                                    key={`employee-avatar-${monthIndex}-${weekIndex}-${dayIndex}-${vacationIndex}-${vacation.employee.id}`}
                                                                                                                    style={{
                                                                                                                        position: 'absolute',
                                                                                                                        top: 0,
                                                                                                                        left: 0,
                                                                                                                        borderRadius: '50%',
                                                                                                                        width: '30px',
                                                                                                                        height: '30px',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <Grid
                                                                                                                        container
                                                                                                                        style={{
                                                                                                                            position: 'relative',
                                                                                                                            width: '30px',
                                                                                                                            height: '30px',
                                                                                                                            borderRadius: '50%',
                                                                                                                            transform: `rotate(-${Math.floor(360 / maxVacationsCount * vacationIndex)}deg)`,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <EmployeeAvatar
                                                                                                                            style={{
                                                                                                                                position: 'absolute',
                                                                                                                                width: '16px',
                                                                                                                                height: '16px',
                                                                                                                                fontSize: '8px',
                                                                                                                                transform: `rotate(${Math.floor(360 / maxVacationsCount * vacationIndex)}deg)`,
                                                                                                                                top: '-8px',
                                                                                                                                left: '7px',
                                                                                                                            }}
                                                                                                                            employee={vacation.employee}
                                                                                                                        />
                                                                                                                    </Grid>
                                                                                                                </Grid>;
                                                                                                            }
                                                                                                        },
                                                                                                    )
                                                                                                }
                                                                                                {
                                                                                                    day.isMore &&
                                                                                                    <Grid
                                                                                                        style={{
                                                                                                            position: 'absolute',
                                                                                                            top: 0,
                                                                                                            left: 0,
                                                                                                            borderRadius: '50%',
                                                                                                            width: '30px',
                                                                                                            height: '30px',
                                                                                                        }}
                                                                                                    >
                                                                                                        <Grid
                                                                                                            container
                                                                                                            style={{
                                                                                                                position: 'relative',
                                                                                                                width: '30px',
                                                                                                                height: '30px',
                                                                                                                borderRadius: '50%',
                                                                                                                transform: 'rotate(-288deg)',
                                                                                                            }}
                                                                                                        >
                                                                                                            <Avatar
                                                                                                                style={{
                                                                                                                    position: 'absolute',
                                                                                                                    width: '16px',
                                                                                                                    height: '16px',
                                                                                                                    fontSize: '14px',
                                                                                                                    transform: 'rotate(288deg)',
                                                                                                                    top: '-8px',
                                                                                                                    left: '7px',
                                                                                                                    background: 'black',
                                                                                                                }}
                                                                                                            >
                                                                                                                ⋯
                                                                                                            </Avatar>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                }
                                                                                            </Box> :
                                                                                            <Box
                                                                                                position="relative"
                                                                                                textAlign="center"
                                                                                                width="30px"
                                                                                                height="30px"
                                                                                                margin="7px"
                                                                                                className={
                                                                                                    (
                                                                                                        this.state.interval.uiInterval &&
                                                                                                        this.state.interval.uiInterval.start &&
                                                                                                        this.state.interval.uiInterval.end &&
                                                                                                        day.date >= this.state.interval.uiInterval.start &&
                                                                                                        day.date <= this.state.interval.uiInterval.end
                                                                                                    ) ?
                                                                                                        'selected-day' :
                                                                                                        ''
                                                                                                }
                                                                                                style={{
                                                                                                    borderRadius: '50%',
                                                                                                    backgroundColor: day.color,
                                                                                                }}
                                                                                            >
                                                                                                <Box
                                                                                                    position="absolute"
                                                                                                    top="50%"
                                                                                                    left="50%"
                                                                                                    fontSize="9px"
                                                                                                    style={{
                                                                                                        transform: 'translate(-50%, -50%)',
                                                                                                        fontWeight: 'bold',
                                                                                                    }}
                                                                                                >
                                                                                                    {day.date.format('DD')}
                                                                                                </Box>
                                                                                            </Box>
                                                                                    }
                                                                                </Box>
                                                                            </Tooltip>
                                                                        </Grid>;
                                                                    },
                                                                )
                                                            }
                                                        </Grid>,
                                                )
                                            }
                                        </Grid>
                                    </Box>
                                </Grid>,
                        )
                    }

                    {
                        !dayRendered &&
                        <Grid
                            container justify="center" alignItems="center"
                            style={{fontSize: '34px', padding: '24px', fontWeight: 'bold'}}
                        >
                            NO DATA
                        </Grid>
                    }
                </Grid>

                <Fade in={Object.keys(chips).length > 0}>
                    <Grid
                        item
                        container
                        wrap="nowrap"
                        justify="flex-start"
                        className="chips-container"
                    >
                        {
                            Object.keys(chips).map((employeeId): React.ReactElement =>
                                <Chip
                                    key={`employee-chip-${chips[employeeId].employee.id}`}
                                    style={{
                                        margin: '10px',
                                    }}
                                    deleteIcon={
                                        <Avatar
                                            style={{width: '24px', height: '24px', fontSize: '14px', color: 'white'}}
                                        >
                                            {chips[employeeId].count}
                                        </Avatar>
                                    }
                                    clickable
                                    onClick={(): void => this.openProfile(chips[employeeId].employee)}
                                    onDelete={(): void => this.openProfile(chips[employeeId].employee)}
                                    label={`${chips[employeeId].employee.last_name} ${chips[employeeId].employee.first_name}`}
                                    avatar={<EmployeeAvatar employee={chips[employeeId].employee}/>}
                                />,
                            )
                        }
                    </Grid>
                </Fade>
            </Grid>
        );
    }
}
