import {Action} from 'redux';
import Alert from '../models/Alert';


export type AlertActionType =
    'SHOW_ALERT' |
    'HIDE_ALERT';

export interface ShowAlertAction extends Action<AlertActionType> {
    alert: Alert;
}

export const showAlert = (alert: Alert): ShowAlertAction => ({
    type: 'SHOW_ALERT',
    alert,
});

export const hideAlert = (): Action<AlertActionType> => ({
    type: 'HIDE_ALERT',
});

export type AlertAction =
    ShowAlertAction;
