import {connect} from 'react-redux';
import VacationRequest, {
    VacationRequestDispatchToProps,
    VacationRequestStateToProps,
} from '../components/VacationRequest';
import EmployeeService from '../services/Employee';
import VacationService from '../services/Vacation';


const mapStateToProps = (state): VacationRequestStateToProps => ({
    me: state.Employee.me,
    groups: state.Group.groups,
    vacationReasons: state.Vacation.reasons,
    employees: state.Employee.employees,
});

const mapDispatchToProps: VacationRequestDispatchToProps = {
    receiveMe: EmployeeService.receiveMe,
    approveVacationRequest: VacationService.approveVacationRequest,
    rejectVacationRequest: VacationService.rejectVacationRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(VacationRequest);
