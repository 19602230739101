import {
    AppBar,
    BottomNavigation,
    BottomNavigationAction,
    Button,
    FormControl,
    Grid,
    Link,
    Paper,
    Toolbar,
    Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import * as Icons from '@material-ui/icons';
import {DatePicker} from '@material-ui/pickers';
import lodash from 'lodash';
import moment from 'moment';
import {WithSnackbarProps} from 'notistack';
import React from 'react';
import {BrowserView, isMobile, MobileView} from 'react-device-detect';
import Draggable from 'react-draggable';
import '../assets/scss/utils.scss';
import '../assets/scss/vacation-dialog.scss';
import ProfileDialog from '../containers/ProfileDialog';
import VacationRequestsDialog from '../containers/VacationRequestsDialog';
import Alert from '../models/Alert';
import Employee, {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group, {
    GroupComponentWrapper,
    GroupComponentWrapperDispatchToProps,
    GroupComponentWrapperStateToProps,
} from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation, {
    VacationComponentWrapper,
    VacationComponentWrapperDispatchToProps,
    VacationComponentWrapperStateToProps,
} from '../models/Vacation';
import VacationReason from '../models/VacationReason';
import VacationRequest from '../models/VacationRequest';
import EmployeeItem from './EmployeeItem';


export interface VacationDialogStateToProps extends VacationComponentWrapperStateToProps, GroupComponentWrapperStateToProps, EmployeeComponentWrapperStateToProps {
    vacationReasons: VacationReason[];
}

export interface VacationDialogDispatchToProps extends VacationComponentWrapperDispatchToProps, GroupComponentWrapperDispatchToProps, EmployeeComponentWrapperDispatchToProps {
    pushVacation?: (vacation: Vacation) => void;
    updateVacation?: (vacation: Vacation) => void;
    removeVacation?: (vacation: Vacation) => void;
    showAlert: (alert: Alert) => void;
    pushSystemNotification: (systemNotification: string) => void;
    approveVacationRequest?: (vacationRequest) => void;
    rejectVacationRequest?: (vacationRequest, reason) => void;
}

export type VacationDialogProps = VacationDialogStateToProps & VacationDialogDispatchToProps & {
    open: boolean;
    vacation?: Vacation;
    start?: moment.Moment;
    end?: moment.Moment;
    onClose?: () => void;
    anchorElement?: HTMLElement;
}

export interface VacationDialogState {
    vacation: Vacation;
    position: {
        x: number;
        y: number;
    };
    submitDialog: {
        title: string;
        open: boolean;
        onYesClicked: () => void;
        onNoClicked: () => void;
    };
    backClick: boolean;
    employeeRolesDialog: {
        open: boolean;
        employee?: Employee;
    };
    vacationRequestDialog: {
        open: boolean;
    };
    reasonReject: {
        reason: string;
        open: boolean;
    };
    anchorElementProfile: HTMLElement;
}

export default class VacationDialog extends React.Component<VacationDialogProps & WithSnackbarProps, VacationDialogState> implements VacationComponentWrapper, GroupComponentWrapper, EmployeeComponentWrapper {
    public constructor(props: VacationDialogProps & WithSnackbarProps) {
        super(props);

        this.state = {
            vacation: this.props.vacation,
            position: {
                x: 0,
                y: 0,
            },
            backClick: false,
            submitDialog: {
                title: '',
                open: false,
                onYesClicked: null,
                onNoClicked: null,
            },
            employeeRolesDialog: {
                open: false,
            },
            vacationRequestDialog: {
                open: false,
            },
            reasonReject: {
                reason: '',
                open: false,
            },
            anchorElementProfile: null,
        };
    }

    public componentDidUpdate(prevProps: Readonly<VacationDialogProps & WithSnackbarProps>): void {
        if (
            this.props.me &&
            this.props.me != prevProps.me
        ) {
            this.props.receiveEmployeeRoles(this.props.me.id);
        }

        if (prevProps.open !== this.props.open) {
            if (this.props.open) {
                if (this.props.vacation) {
                    this.props.receiveVacationRequests(this.props.vacation.id);
                    this.props.receiveEmployeeGroups(this.props.vacation.employee.id);

                    this.setState({
                        vacation: this.props.vacation,
                    });

                    this.props.receiveVacationRequests(this.props.vacation.id);
                } else {
                    this.setState({
                        vacation: {
                            employee: this.props.me,
                            start: this.props.start || moment(),
                            end: this.props.end || moment(),
                            reason: this.props.vacationReasons.find((vacationReason): boolean => vacationReason.short_name == 'vacation'),
                            comment: '',
                            id: undefined,
                            status: undefined,
                            created_at: undefined,
                            updated_at: undefined,
                        },
                    });
                }
            } else {
                this.setState({
                    vacation: undefined,
                });
            }
        }
    }

    public draggablePaper = (props): React.ReactElement => {
        return (
            <Draggable
                bounds="body"
                cancel=".MuiDialogContent-root, .MuiDialogActions-root"
            >
                <Paper {...props} style={{margin: 0}}/>
            </Draggable>
        );
    };

    public onCancel = (): void => {
        const createNewVacation = !this.state.vacation.id;
        const dataWasChanged = this.props.vacation != this.state.vacation;

        if (dataWasChanged && !createNewVacation) {
            this.setState({
                submitDialog: {
                    ...this.state.submitDialog,
                    title: 'Are you sure you want to cancel the changes you made?',
                    open: dataWasChanged && !createNewVacation,
                    onYesClicked: (): void => {
                        this.setState({
                            submitDialog: {
                                ...this.state.submitDialog,
                                open: false,
                            },
                            employeeRolesDialog: {
                                ...this.state.employeeRolesDialog,
                                open: false,
                            },
                            reasonReject: {
                                ...this.state.reasonReject,
                                open: false,
                            },
                        });
                        this.props.onClose();
                    },
                    onNoClicked: (): void => this.setState({
                        submitDialog: {
                            ...this.state.submitDialog,
                            open: false,
                        },
                    }),
                },
            });
        } else {
            this.setState({
                employeeRolesDialog: {
                    ...this.state.employeeRolesDialog,
                    open: false,
                },
                reasonReject: {
                    ...this.state.reasonReject,
                    open: false,
                },
            });

            this.props.onClose();
        }
    };

    public onSave = (): void => {
        const createNewVacation = !this.state.vacation.id;
        const dataWasChanged = !lodash.isEqual(this.props.vacation, this.state.vacation);

        if (!this.state.vacation.comment) {
            this.props.showAlert({
                title: 'Comment Required',
                text: 'You need to fill in the comment field',
            });
        } else {
            if (createNewVacation || dataWasChanged) {
                if (
                    moment(this.state.vacation.employee.employment_date)
                        .add(6, 'month')
                        .format('YYYY-MM-DD') >
                    moment(this.state.vacation.start).format('YYYY-MM-DD') &&
                    this.state.vacation.reason.short_name === 'vacation'
                ) {
                    this.setState({
                        submitDialog: {
                            ...this.state.submitDialog,
                            title: `Please note, that 6 months have not passed since employment. The nearest date from which you can create a vacation: ${moment(this.state.vacation.employee.employment_date).add(6, 'month').format('YYYY/MM/DD')}. Are you sure you want to continue with the entered data?`,
                            open: dataWasChanged,
                            onYesClicked: (): void => {
                                this.setState({
                                    submitDialog: {
                                        ...this.state.submitDialog,
                                        open: false,
                                    },
                                    employeeRolesDialog: {
                                        ...this.state.employeeRolesDialog,
                                        open: false,
                                    },
                                    reasonReject: {
                                        ...this.state.reasonReject,
                                        open: false,
                                    },
                                });

                                createNewVacation ?
                                    this.props.pushVacation(this.state.vacation) :
                                    this.props.updateVacation(this.state.vacation);

                                this.props.onClose();
                            },
                            onNoClicked: (): void => this.setState({
                                submitDialog: {
                                    ...this.state.submitDialog,
                                    open: false,
                                },
                                employeeRolesDialog: {
                                    ...this.state.employeeRolesDialog,
                                    open: false,
                                },
                                reasonReject: {
                                    ...this.state.reasonReject,
                                    open: false,
                                },
                            }),
                        },
                    });
                } else {
                    createNewVacation ?
                        this.props.pushVacation(this.state.vacation) :
                        this.props.updateVacation(this.state.vacation);

                    this.setState({
                        employeeRolesDialog: {
                            ...this.state.employeeRolesDialog,
                            open: false,
                        },
                        reasonReject: {
                            ...this.state.reasonReject,
                            open: false,
                        },
                    });
                    this.props.onClose();
                }
            } else {
                this.props.pushSystemNotification(
                    `${this.state.vacation.reason.display_name} has not been changed, as your changes completely correspond to the original`,
                );
                this.setState({
                    employeeRolesDialog: {
                        ...this.state.employeeRolesDialog,
                        open: false,
                    },
                    reasonReject: {
                        ...this.state.reasonReject,
                        open: false,
                    },
                });
                this.props.onClose();
            }
        }
    };

    public onRemove = (): void => {
        this.setState({
            submitDialog: {
                ...this.state.submitDialog,
                title: 'Are you sure you want to remove the vacation?',
                open: true,
                onYesClicked: (): void => {
                    this.props.removeVacation(this.state.vacation);
                    this.setState({
                        submitDialog: {
                            ...this.state.submitDialog,
                            open: false,
                        },
                        employeeRolesDialog: {
                            ...this.state.employeeRolesDialog,
                            open: false,
                        },
                        reasonReject: {
                            ...this.state.reasonReject,
                            open: false,
                        },

                    });
                    this.props.onClose();
                },
                onNoClicked: (): void => this.setState({
                    submitDialog: {
                        ...this.state.submitDialog,
                        open: false,
                    },
                }),
            },
        });
    };

    public onCheckVacationStatus = (): void => {
        this.setState({
            vacationRequestDialog: {
                open: true,
            },
        });
    };

    public render(): React.ReactElement {
        if (
            !this.props.me ||
            !this.state.vacation
        ) {
            return <React.Fragment/>;
        }

        const isTwoWeeksRuleFault =
            moment(this.state.vacation.start).format('YYYY-MM-DD') <
            moment().add(14, 'days').format('YYYY-MM-DD');

        const isStartGreaterNow =
            moment(this.state.vacation.start).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD');

        const isStartLowerEqualEnd =
            moment(this.state.vacation.start).format('YYYY-MM-DD') <=
            moment(this.state.vacation.end).format('YYYY-MM-DD');

        const isEditAvailable = (
            this.isAdmin(this.props.me.id) ||
            !this.props.vacation ||
            (
                this.state.vacation.employee.id == this.props.me.id &&
                isStartGreaterNow
            )
        );

        const vacationRequest = this.props.vacation ? this.getVacationRequests(this.props.vacation.id).find(
            (vacationRequest): boolean =>
                vacationRequest.group.lead.id === this.props.me.id &&
                vacationRequest.status.short_name === 'pending',
        ) : undefined;

        const createNewVacation = !this.state.vacation.id;

        return (
            <React.Fragment>
                <Dialog
                    open={this.props.open}
                    maxWidth={false}
                    hideBackdrop={true}
                    fullScreen={isMobile}
                    PaperComponent={this.draggablePaper}
                    onClose={(): void => this.onCancel()}
                >
                    <BrowserView>
                        <DialogTitle style={{cursor: 'move'}}>
                            {
                                this.state.vacation.reason ? (
                                    !createNewVacation ?
                                        `${this.state.vacation.reason.display_name}: ${moment(this.state.vacation.start).format('YYYY-MM-DD')} - ${moment(this.state.vacation.end).format('YYYY-MM-DD')} (${moment(this.state.vacation.end).diff(this.state.vacation.start, 'days') + 1} days)` :
                                        `New ${this.state.vacation.reason.display_name}: ${moment(this.state.vacation.start).format('YYYY-MM-DD')} - ${moment(this.state.vacation.end).format('YYYY-MM-DD')} (${moment(this.state.vacation.end).diff(this.state.vacation.start, 'days') + 1} days)`
                                ) : ''
                            }
                        </DialogTitle>
                    </BrowserView>

                    <MobileView>
                        <AppBar position="static">
                            <Toolbar style={{minHeight: '52px'}}>
                                <Typography variant="h6" style={{marginLeft: '16px'}}>
                                    {
                                        this.state.vacation.reason ? (
                                            createNewVacation ?
                                                `New ${this.state.vacation.reason.display_name}` :
                                                `${this.state.vacation.reason.display_name}`
                                        ) : ''
                                    }
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </MobileView>

                    <DialogContent style={isMobile ? {paddingTop: '16px'} : {}}>
                        <TextField
                            fullWidth
                            disabled={!isEditAvailable}
                            inputProps={{
                                maxLength: 100,
                            }}
                            value={this.state.vacation.comment}
                            label="Comment"
                            onChange={
                                (event): void => {
                                    let regex = new RegExp(/ +/, 'g');

                                    this.setState({
                                        vacation: {
                                            ...this.state.vacation,
                                            comment: event.target.value.replace(regex, ' ').trimLeft(),
                                        },
                                    });
                                }
                            }
                            helperText={
                                isEditAvailable &&
                                'Comment length should not exceed 100 characters'
                            }
                        />

                        <Grid
                            container direction="row"
                            wrap={isMobile ? 'wrap' : 'nowrap'}
                            justify="space-between"
                            alignItems="flex-start"
                        >
                            <FormControl fullWidth margin="dense" className={isMobile ? '' : 'm-r-8'}>
                                <InputLabel htmlFor="employee">
                                    {`User `}
                                    {
                                        (
                                            this.isAdmin(this.props.me.id) ||
                                            this.getEmployeeGroups(this.state.vacation.employee.id)
                                                .some((group): boolean => group.lead.id === this.props.me.id)
                                        ) &&
                                        (
                                            <React.Fragment>
                                                (<Link target="_blank" href={`/profile-${this.state.vacation.employee.id}`}>view
                                                profile</Link>)
                                            </React.Fragment>
                                        )
                                    }
                                </InputLabel>

                                <Select
                                    fullWidth
                                    inputProps={{
                                        id: 'employee',
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingBottom: '4px',
                                        },
                                    }}
                                    disabled={!this.isAdmin(this.props.me.id)}
                                    value={this.state.vacation.employee && this.state.vacation.employee.id}
                                    renderValue={(employeeId): React.ReactElement => {
                                        const employee = this.props.employees.find(
                                            (employee): boolean =>
                                                employee.id === employeeId,
                                        );

                                        return (
                                            <EmployeeItem
                                                employee={employee}
                                                fontSize={14}
                                                WrapperProps={
                                                    {
                                                        style: {
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                        },
                                                    }
                                                }
                                            />
                                        );
                                    }}
                                    onChange={(event): void => {
                                        this.setState({
                                            vacation: {
                                                ...this.state.vacation,
                                                employee: this.props.employees.find(
                                                    (employee): boolean =>
                                                        employee.id === event.target.value,
                                                ),
                                            },
                                        });
                                    }}
                                >
                                    {this.props.employees
                                        .sort((a, b): number => {
                                            let aName = `${a.last_name} ${a.first_name}`;
                                            let bName = `${b.last_name} ${b.first_name}`;

                                            return aName.localeCompare(bName);
                                        })

                                        .filter((employee): boolean => {
                                            return (employee.status.short_name == 'active');
                                        })
                                        .map((employee): React.ReactElement => (
                                            <MenuItem
                                                value={employee.id} key={`employee-${employee.id}`}
                                            >
                                                <EmployeeItem employee={employee} fontSize={16}/>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth margin="dense">
                                <DatePicker
                                    className={isMobile ? '' : 'vacation-dialog-employment-date'}
                                    disabled={true}
                                    onChange={null}
                                    format="YYYY/MM/DD"
                                    value={this.state.vacation.employee.employment_date}
                                    label="Employment Date"
                                />
                            </FormControl>
                        </Grid>

                        <Grid
                            container direction="row"
                            wrap={isMobile ? 'wrap' : 'nowrap'}
                            justify="space-between"
                            alignItems="flex-start"
                        >
                            <FormControl fullWidth margin="dense" className={isMobile ? '' : 'm-r-8'}>
                                <InputLabel htmlFor="reason">Reason</InputLabel>

                                <Select
                                    inputProps={{
                                        id: 'reason',
                                    }}
                                    disabled={!isEditAvailable}
                                    value={this.state.vacation.reason && this.state.vacation.reason.id}
                                    onChange={(event): void => {
                                        this.setState({
                                            vacation: {
                                                ...this.state.vacation,
                                                reason: this.props.vacationReasons.find(
                                                    (reason): boolean => reason.id === event.target.value,
                                                ),
                                            },
                                        });
                                    }}
                                >
                                    {
                                        this.props.vacationReasons.map((reason): React.ReactElement => (
                                            <MenuItem value={reason.id} key={`reason-${reason.id}`}>
                                                {`${reason.display_name}`}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            {
                                this.state.vacation.status &&
                                <FormControl fullWidth>
                                    <TextField
                                        margin="dense"
                                        disabled={true}
                                        value={this.state.vacation.status.display_name}
                                        label="Vacation Status"
                                    />
                                </FormControl>
                            }
                        </Grid>

                        <Grid
                            container direction="row"
                            wrap={isMobile ? 'wrap' : 'nowrap'}
                            justify="space-between"
                            alignItems="flex-start"
                        >
                            <FormControl margin="dense" className={isMobile ? '' : 'm-r-8'} fullWidth>
                                <DatePicker
                                    disabled={true}
                                    onChange={null}
                                    format="YYYY/MM/DD"
                                    value={moment(this.state.vacation.created_at)}
                                    label="Created Date"
                                />
                            </FormControl>

                            <FormControl margin="dense" className={isMobile ? '' : 'm-r-8'} fullWidth>
                                <DatePicker
                                    error={
                                        isEditAvailable &&
                                        !this.isAdmin(this.props.me.id) &&
                                        (
                                            !isStartGreaterNow ||
                                            (
                                                isTwoWeeksRuleFault &&
                                                this.state.vacation.reason.short_name === 'vacation'
                                            ) ||
                                            !isStartLowerEqualEnd
                                        )
                                    }
                                    disabled={!isEditAvailable}
                                    format="YYYY/MM/DD"
                                    label="Start Date"
                                    views={['date']}
                                    minDate={
                                        (
                                            isEditAvailable &&
                                            !this.isAdmin(this.props.me.id)
                                        ) ?
                                            (
                                                this.state.vacation.reason.short_name === 'vacation' ?
                                                    new Date(moment().add(14, 'days').format('YYYY-MM-DD')) :
                                                    new Date()
                                            ) : undefined
                                    }
                                    value={this.state.vacation.start}
                                    onChange={(date: moment.Moment): void => {
                                        this.setState({
                                            vacation: {
                                                ...this.state.vacation,
                                                start: date,
                                            },
                                        });
                                    }}
                                    helperText={
                                        isEditAvailable &&
                                        !this.isAdmin(this.props.me.id) &&
                                        (
                                            (
                                                !isStartGreaterNow &&
                                                'Start date must be greater than or equal to the current date'
                                            ) || (
                                                (
                                                    isTwoWeeksRuleFault &&
                                                    this.state.vacation.reason.short_name === 'vacation'
                                                ) &&
                                                'Vacation must be created no later than 2 weeks before the start'
                                            ) || (
                                                !isStartLowerEqualEnd &&
                                                'Start date must be lower than or equal to the end date'
                                            )
                                        )
                                    }
                                />
                            </FormControl>

                            <FormControl margin="dense" fullWidth>
                                <DatePicker
                                    error={
                                        isEditAvailable &&
                                        !this.isAdmin(this.props.me.id) &&
                                        !isStartLowerEqualEnd
                                    }
                                    disabled={!isEditAvailable}
                                    label={`End Date`}
                                    format="YYYY/MM/DD"
                                    views={['date']}
                                    minDate={
                                        (
                                            this.isAdmin(this.props.me.id) ||
                                            this.state.vacation.employee.id === this.props.me.id
                                        ) && this.state.vacation.start
                                    }
                                    value={this.state.vacation.end}
                                    onChange={(date: moment.Moment): void => {
                                        this.setState({
                                            vacation: {
                                                ...this.state.vacation,
                                                end: date,
                                            },
                                        });
                                    }}
                                    helperText={
                                        isEditAvailable &&
                                        (
                                            !isStartLowerEqualEnd &&
                                            'End date must be greater than or equal to the start date'
                                        )
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {
                            vacationRequest && this.state.reasonReject.open && (
                                <TextField
                                    fullWidth
                                    inputProps={{
                                        maxLength: 150,
                                    }}
                                    label="Reason for rejection"
                                    value={this.state.reasonReject.reason}
                                    onChange={(event): void => {
                                        let regex = new RegExp(/ +/, 'g');

                                        this.setState({
                                            reasonReject: {
                                                ...this.state.reasonReject,
                                                reason: event.target.value
                                                    .replace(regex, ' ')
                                                    .trimLeft(),
                                            },
                                        });
                                    }}
                                    helperText="Reason length should not exceed 150 characters"
                                />
                            )
                        }
                    </DialogContent>

                    {
                        vacationRequest && (
                            <DialogActions className="dialog-buttons-container wrapper-button">
                                <Button
                                    fullWidth
                                    disableFocusRipple
                                    variant="contained"
                                    color="primary"
                                    className="accept-button"
                                    onClick={(): void => {
                                        this.props.approveVacationRequest(vacationRequest);
                                        this.setState({
                                            employeeRolesDialog: {
                                                ...this.state.employeeRolesDialog,
                                                open: false,
                                            },
                                        });
                                        this.props.onClose();
                                    }}
                                >
                                    Approve
                                </Button>

                                <Button
                                    fullWidth
                                    disableFocusRipple
                                    variant="contained"
                                    color="primary"
                                    className="reject-button"
                                    onClick={(): void => {
                                        if (!this.state.reasonReject.open) {
                                            this.setState({
                                                reasonReject: {
                                                    ...this.state.reasonReject,
                                                    open: true,
                                                },
                                            });
                                        } else {
                                            if (this.state.reasonReject.reason) {
                                                this.setState({
                                                    employeeRolesDialog: {
                                                        ...this.state.employeeRolesDialog,
                                                        open: false,
                                                    },
                                                });
                                                this.props.onClose();
                                            }

                                            this.props.rejectVacationRequest(vacationRequest, this.state.reasonReject.reason);
                                        }
                                    }}
                                >
                                    Reject
                                </Button>
                            </DialogActions>
                        )
                    }

                    {
                        !isMobile &&
                        this.state.vacation &&
                        this.getVacationRequests(this.state.vacation.id).length > 0 &&
                        <DialogActions className="dialog-buttons-container wrapper-button">
                            <Button
                                disableFocusRipple
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={
                                    (): void => {
                                        this.setState({
                                            vacationRequestDialog: {
                                                open: true,
                                            },
                                        });
                                    }
                                }
                            >
                                Check {this.state.vacation.reason.display_name} status
                            </Button>
                        </DialogActions>
                    }

                    <BrowserView>
                        <DialogActions className="dialog-buttons-container wrapper-button">
                            {
                                isEditAvailable && !createNewVacation && (
                                    <Button
                                        disableFocusRipple
                                        fullWidth
                                        onClick={(): void => this.onRemove()}
                                        color="secondary"
                                    >
                                        Remove
                                    </Button>
                                )
                            }

                            {
                                isEditAvailable && (
                                    <Button
                                        fullWidth
                                        disableFocusRipple
                                        disabled={
                                            isEditAvailable &&
                                            !this.isAdmin(this.props.me.id) &&
                                            (
                                                (
                                                    isTwoWeeksRuleFault &&
                                                    this.state.vacation.reason.short_name === 'vacation'
                                                ) ||
                                                !isStartLowerEqualEnd ||
                                                !isStartGreaterNow
                                            )
                                        }
                                        onClick={(): void => this.onSave()}
                                        color="primary" autoFocus
                                    >
                                        Save
                                    </Button>
                                )
                            }

                            <Button
                                disableFocusRipple
                                fullWidth
                                onClick={(): void => this.onCancel()}
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </BrowserView>

                    <MobileView>
                        <BottomNavigation showLabels>
                            {
                                isEditAvailable &&
                                <BottomNavigationAction
                                    label="Save"
                                    icon={<Icons.Save/>}
                                    focusRipple={false}
                                    onClick={(): void => this.onSave()}
                                />
                            }

                            {
                                this.state.vacation &&
                                this.getVacationRequests(this.state.vacation.id).length > 0 &&
                                <BottomNavigationAction
                                    label="Status"
                                    icon={<Icons.List/>}
                                    focusRipple={false}
                                    onClick={(): void => this.onCheckVacationStatus()}
                                />
                            }

                            {
                                isEditAvailable && !createNewVacation &&
                                <BottomNavigationAction
                                    label="Remove"
                                    icon={<Icons.Delete/>}
                                    focusRipple={false}
                                    onClick={(): void => this.onRemove()}
                                />
                            }

                            <BottomNavigationAction
                                label="Cancel"
                                icon={<Icons.Cancel/>}
                                focusRipple={false}
                                onClick={(): void => this.onCancel()}
                            />
                        </BottomNavigation>
                    </MobileView>
                </Dialog>

                <Dialog
                    open={this.state.submitDialog.open}
                    onClose={(): void => {
                        this.state.submitDialog.onNoClicked();
                    }}
                >
                    <DialogTitle>{this.state.submitDialog.title}</DialogTitle>

                    <DialogActions>
                        <Button
                            fullWidth
                            disableFocusRipple
                            onClick={(): void => this.state.submitDialog.onYesClicked()}
                            color="primary"
                        >
                            Yes
                        </Button>

                        <Button
                            fullWidth
                            disableFocusRipple
                            onClick={(): void => this.state.submitDialog.onNoClicked()}
                            color="primary"
                        >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                <ProfileDialog
                    open={this.state.employeeRolesDialog.open}
                    employee={this.state.employeeRolesDialog.employee}
                    clickVacation={this.state.backClick}
                    draggableVacation={(): void => this.setState({backClick: !this.state.backClick})}
                    anchorElement={this.state.anchorElementProfile}
                    onClose={(): void => {
                        this.setState({
                            employeeRolesDialog: {
                                open: false,
                            },
                        });
                    }}
                />

                <VacationRequestsDialog
                    open={this.state.vacationRequestDialog.open}
                    vacation={this.props.vacation}
                    onClose={(): void => {
                        this.setState({
                            vacationRequestDialog: {
                                open: false,
                            },
                        });
                    }}
                />
            </React.Fragment>
        );
    };

    public getVacationRequests = (vacationId: number): VacationRequest[] =>
        this.props.vacationRequests[vacationId] || [];

    public getGroupMembers = (groupId: number): Employee[] =>
        this.props.groupMembers[groupId] || [];

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
