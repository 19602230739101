import {LogAction} from '../actions/Log';
import Log from '../models/Log';


export type LogReducerReceivingTarget =
    'LOGS';

export interface LogReducerState {
    logs: Log[];
    logsCount: number;
    prevLimit?: number;
    prevOffset?: number;
    prevSearch?: string;
    prevOrdering?: string;
    receivingCounter: number;
}

const initialState: LogReducerState = {
    logs: [],
    logsCount: 0,
    receivingCounter: 0,
};

const LogReducer = (state: LogReducerState = initialState, action: LogAction): LogReducerState => {
    switch (action.type) {
        case 'RECEIVE_LOGS_STARTED':
            state.prevLimit = action.limit;
            state.prevOffset = action.offset;
            state.prevSearch = action.search;
            state.prevOrdering = action.ordering;
            state.receivingCounter++;
            break;
        case 'RECEIVE_LOGS_FINISHED':
            state.logs = action.logs;
            state.logsCount = action.count;
            state.receivingCounter--;
            break;
    }

    return {...state};
};

export default LogReducer;
