import moment from 'moment';
import {FilterValues} from '../components/Filter';
import VacationService from '../services/Vacation';
import Employee from './Employee';
import VacationReason from './VacationReason';
import VacationRequest from './VacationRequest';
import VacationStatus from './VacationStatus';


export default interface Vacation {
    id: number;
    employee: Employee;
    start: moment.Moment;
    end: moment.Moment;
    status: VacationStatus;
    reason: VacationReason;
    comment: string;
    created_at: moment.Moment;
    updated_at: moment.Moment;
    rejection_reason?: string;
}

export interface VacationComponentWrapperStateToProps {
    vacations: Vacation[];
    vacationRequests: { [vacationId: number]: VacationRequest[] };
}

export interface VacationComponentWrapperDispatchToProps {
    receiveVacations: (filter?: FilterValues) => void;
    receiveVacationRequests: (vacationId: number) => void;
}

export const vacationComponentWrapperMapStateToProps = (state): VacationComponentWrapperStateToProps => ({
    vacations: state.Vacation.vacations,
    vacationRequests: state.Vacation.requests,
});

export const vacationComponentWrapperMapDispatchToProps: VacationComponentWrapperDispatchToProps = {
    receiveVacations: VacationService.receive,
    receiveVacationRequests: VacationService.receiveRequests,
};

export interface VacationComponentWrapper {
    getVacationRequests: (vacationId: number) => VacationRequest[];
}
