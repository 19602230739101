import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import MonthlyCalendarPage, {
    MonthlyCalendarDispatchToProps,
    MonthlyCalendarStateToProps,
} from '../components/MonthlyCalendarPage';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import {groupComponentWrapperMapDispatchToProps, groupComponentWrapperMapStateToProps} from '../models/Group';
import {vacationComponentWrapperMapDispatchToProps, vacationComponentWrapperMapStateToProps} from '../models/Vacation';
import AlertService from '../services/Alert';
import SystemNotificationService from '../services/SystemNotification';


const mapStateToProps = (state): MonthlyCalendarStateToProps => ({
    ...vacationComponentWrapperMapStateToProps(state),
    ...groupComponentWrapperMapStateToProps(state),
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: MonthlyCalendarDispatchToProps = {
    pushSystemNotification: SystemNotificationService.pushInfo,
    showAlert: AlertService.show,
    ...vacationComponentWrapperMapDispatchToProps,
    ...groupComponentWrapperMapDispatchToProps,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(MonthlyCalendarPage));
