import axios from 'axios';
import moment from 'moment';
import {Dispatch} from 'redux';
import {
    receiveVacationRequestsFinished,
    receiveVacationRequestsStarted,
    receiveVacationsFinished,
    receiveVacationsFinishedWithError,
    receiveVacationsStarted,
} from '../actions/Vacation';
import {FilterValues} from '../components/Filter';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';
import Utils from '../utils/Utils';
import AuthenticationService from './Authentication';
import SystemNotificationService from './SystemNotification';


class VacationService {
    public static receive = (filter?: FilterValues): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            let filterObj = {};

            if (filter) {
                if (filter.ids) {
                    filterObj['id__in'] = filter.ids.join(',');
                }

                if (filter.start && filter.end) {
                    filterObj['istart'] = filter.start.format('YYYY-MM-DD');
                    filterObj['iend'] = filter.end.format('YYYY-MM-DD');
                }

                if (filter.groupIds && filter.groupIds.length > 0) {
                    filterObj['employee__groups__in'] =
                        filter.groupIds
                            .join(',');
                }

                if (filter.employeeIds && filter.employeeIds.length > 0) {
                    filterObj['employee__in'] =
                        filter.employeeIds
                            .join(',');
                }

                if (filter.vacationStatusIds && filter.vacationStatusIds.length > 0) {
                    filterObj['status__in'] =
                        filter.vacationStatusIds
                            .join(',');
                }

                if (filter.vacationReasonIds && filter.vacationReasonIds.length > 0) {
                    filterObj['reason__in'] =
                        filter.vacationReasonIds
                            .join(',');
                }
            } else {
                filterObj = undefined;
            }

            dispatch(receiveVacationsStarted(filter));

            axios.get(Utils.generateApiUrl('vacations', filterObj), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response): void => {
                    let vacations = response.data as Vacation[];

                    vacations = vacations.map((vacation): Vacation => ({
                        ...vacation,
                        start: moment(vacation.start),
                        end: moment(vacation.end),
                    }));

                    dispatch(receiveVacationsFinished(vacations));
                })
                .catch((error): void => {
                    dispatch(receiveVacationsFinishedWithError());

                    Utils.handleError(error);
                });
        };

    public static remove = (vacation: Vacation): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            axios.delete(Utils.generateApiUrl(`vacations/${vacation.id}`), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((): void => {
                    SystemNotificationService.pushInfo(`${vacation.reason.display_name} ${moment(vacation.start).format('YYYY/MM/DD')} - ${moment(vacation.end).format('YYYY/MM/DD')} was successfully removed`)(dispatch);
                })
                .catch(Utils.handleError);
        };

    public static create = (vacation: Vacation): ((dispatch) => void) => {
        vacation.start = vacation.start.startOf('day');
        vacation.end = vacation.end.startOf('day');

        return (dispatch: Dispatch): void => {
            axios.post(Utils.generateApiUrl('vacations'), {
                employee: vacation.employee.id,
                start: vacation.start.format('YYYY-MM-DD'),
                end: vacation.end.format('YYYY-MM-DD'),
                reason: vacation.reason.id,
                comment: vacation.comment,
            }, {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((): void => {
                    SystemNotificationService.pushInfo(`${vacation.reason.display_name} ${moment(vacation.start).format('YYYY/MM/DD')} - ${moment(vacation.end).format('YYYY/MM/DD')} was successfully created`)(dispatch);
                })
                .catch(Utils.handleError);
        };
    };

    public static update = (vacation: Vacation): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            axios.put(Utils.generateApiUrl(`vacations/${vacation.id}`), {
                employee: vacation.employee.id,
                start: vacation.start.format('YYYY-MM-DD'),
                end: vacation.end.format('YYYY-MM-DD'),
                reason: vacation.reason.id,
                comment: vacation.comment,
            }, {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((): void => {
                    SystemNotificationService.pushInfo(`${vacation.reason.display_name} ${moment(vacation.start).format('YYYY/MM/DD')} - ${moment(vacation.end).format('YYYY/MM/DD')} was successfully updated`)(dispatch);
                })
                .catch(Utils.handleError);
        };

    public static receiveRequests = (vacationId: number): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveVacationRequestsStarted(vacationId));

            axios.get(Utils.generateApiUrl(`vacations/${vacationId}/requests`), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response): void => {
                    dispatch(receiveVacationRequestsFinished(vacationId, response.data as VacationRequest[]));
                })
                .catch((error): void => {
                    dispatch(receiveVacationsFinishedWithError());

                    Utils.handleError(error);
                });
        };

    public static approveVacationRequest = (vacationRequest: VacationRequest): ((dispatch) => void) =>
        (): void => {
            axios.post(
                Utils.generateApiUrl(`vacations/${vacationRequest.vacation.id || vacationRequest.vacation}/requests/${vacationRequest.id}/approve`),
                {},
                {
                    headers: {
                        'Authorization': `Token ${AuthenticationService.getToken()}`,
                    },
                })
                .catch(Utils.handleError);
        };

    public static rejectVacationRequest = (vacationRequest: VacationRequest, reason: string): ((dispatch) => void) =>
        (): void => {
            axios.post(
                Utils.generateApiUrl(`vacations/${vacationRequest.vacation.id || vacationRequest.vacation}/requests/${vacationRequest.id}/reject`),
                {
                    reason,
                },
                {
                    headers: {
                        'Authorization': `Token ${AuthenticationService.getToken()}`,
                    },
                })
                .catch(Utils.handleError);
        };
}

export default VacationService;
