import {GroupAction} from '../actions/Group';
import {GroupFilterValues} from '../components/GroupFilter';
import Employee from '../models/Employee';
import Group from '../models/Group';


export interface GroupReducerState {
    groups: Group[];
    groupsCount: number;
    members: { [groupId: number]: Employee[] };
    prevLimit?: number;
    prevOffset?: number;
    prevSearch?: string;
    prevOrdering?: string;
    prevFilter?: GroupFilterValues;
    receivingCounter: number;
}


const initialState: GroupReducerState = {
    groups: [],
    groupsCount: 0,
    members: {},
    receivingCounter: 0,
};

const GroupReducer = (state: GroupReducerState = initialState, action: GroupAction): GroupReducerState => {
    switch (action.type) {
        case 'RECEIVE_GROUPS_STARTED':
            state.prevLimit = action.limit;
            state.prevOffset = action.offset;
            state.prevSearch = action.search;
            state.prevOrdering = action.ordering;
            state.prevFilter = action.filter;
            state.receivingCounter++;
            break;
        case 'RECEIVE_GROUPS_FINISHED':
            state.groups = action.groups;
            state.groupsCount = action.count;
            state.receivingCounter--;
            break;
        case 'RECEIVE_GROUP_MEMBERS_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_GROUP_MEMBERS_FINISHED':
            state.members[action.groupId] = action.members;
            state.members = Object.assign({}, state.members);
            state.receivingCounter--;
            break;
        case 'RECEIVE_FINISHED_WITH_ERROR':
            state.receivingCounter--;
            break;
    }

    return {...state};
};

export default GroupReducer;
