import {Grid} from '@material-ui/core';
import React from 'react';
import Employee from '../models/Employee';
import EmployeeAvatar from './EmployeeAvatar';


export interface EmployeeItemProps {
    employee: Employee;
    fontSize?: number;
    style?: React.CSSProperties;
    WrapperProps?: {
        style: React.CSSProperties;
    };
}


export default class EmployeeItem extends React.Component<EmployeeItemProps> {
    public render(): React.ReactElement {
        const employee = this.props.employee;
        const wrapperProps = this.props.WrapperProps || {style: {}};

        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                wrap="nowrap"
                style={{
                    marginTop: '1px',
                    marginBottom: '1px',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    hyphens: 'auto',
                    fontSize: (this.props.fontSize && `${this.props.fontSize}px`) || '12px',
                    ...this.props.style,
                }}
            >
                <EmployeeAvatar
                    employee={employee}
                    style={{
                        marginRight: '6px',
                        width: (this.props.fontSize && `${this.props.fontSize + 4}px`) || '16px',
                        height: (this.props.fontSize && `${this.props.fontSize + 4}px`) || '16px',
                        fontSize: (this.props.fontSize && `${this.props.fontSize - 6}px`) || '8px',
                    }}
                />
                <Grid container style={wrapperProps.style}>
                    {employee && `${employee.last_name} ${employee.first_name}`}
                    {this.props.children}
                </Grid>
            </Grid>
        );
    }

}
