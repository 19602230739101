import axios from 'axios';
import {Dispatch} from 'redux';
import {receiveLogsFinished, receiveLogsStarted} from '../actions/Log';
import Log from '../models/Log';
import Utils from '../utils/Utils';
import AuthenticationService from './Authentication';


class LogService {
    public static receive = (limit?: number, offset?: number, search?: string, ordering?: string): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveLogsStarted(limit, offset, search, ordering));

            axios.get(Utils.generateApiUrl('logs', {
                limit,
                offset,
                search,
                ordering,
            }), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response): void => {
                    if (response.data.results) {
                        dispatch(receiveLogsFinished(response.data.results as Log[], response.data.count));
                    } else {
                        const logs = response.data as Log[];

                        dispatch(receiveLogsFinished(logs, logs.length));
                    }
                })
                .catch(Utils.handleError);
        };
}

export default LogService;
