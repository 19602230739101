import {Grid} from '@material-ui/core';
import moment from 'moment';
import {WithSnackbarProps} from 'notistack';
import QueryString from 'query-string';
import React from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import {RouteComponentProps} from 'react-router-dom';
import '../assets/scss/home.scss';
import '../assets/scss/react-big-calendar.scss';
import '../assets/scss/react-yearly-calendar.scss';
import Filter from '../containers/Filter';
import VacationDialog from '../containers/VacationDialog';
import {EmployeeComponentWrapperDispatchToProps, EmployeeComponentWrapperStateToProps} from '../models/Employee';
import Vacation from '../models/Vacation';
import VacationReason from '../models/VacationReason';
import AnnualCalendar from './AnnualCalendar';
import {FilterValues} from './Filter';
import VacationsListDialog from './VacationsListDialog';


export interface AnnualCalendarStateToProps extends EmployeeComponentWrapperStateToProps {
    vacations: Vacation[];
    vacationReasons: VacationReason[];
    receivingCounter: number;
}

export interface AnnualCalendarDispatchToProps extends EmployeeComponentWrapperDispatchToProps {
    receiveVacations: (filter?: FilterValues) => void;
    receiveVacationReasons: () => void;
}

export type AnnualCalendarProps = AnnualCalendarStateToProps & AnnualCalendarDispatchToProps;

export interface AnnualCalendarState {
    loaded: boolean;
    vacationDialog: {
        open: boolean;
        vacation?: Vacation;
        start?: moment.Moment;
        end?: moment.Moment;
    };
    dayVacationsDialog: {
        open: boolean;
        date?: moment.Moment;
    };
    calendar: {
        year: number;
    };
    sidenav: {
        open: boolean;
    };
    filter: FilterValues;
}

export default class AnnualCalendarPage extends React.Component<AnnualCalendarProps & WithSnackbarProps & RouteComponentProps, AnnualCalendarState> {
    public constructor(props: AnnualCalendarProps & WithSnackbarProps & RouteComponentProps) {
        super(props);

        this.state = {
            loaded: false,
            vacationDialog: {
                open: false,
            },
            dayVacationsDialog: {
                open: false,
            },
            calendar: {
                year: parseInt(moment().format('YYYY')),
            },
            filter: {
                start: moment().startOf('year'),
                end: moment().endOf('year'),
                displayMonth: 'fromNow',
                vacationStatusIds: [1, 3],
            },
            sidenav: {
                open: false,
            },
        };
    }

    public componentDidMount(): void {
        this.props.receiveEmployees();
        this.props.receiveVacationReasons();

        const queryParams = QueryString.parse(this.props.location.search);

        const queryEmployees = queryParams.employees;
        const queryVacationReasons = queryParams.vacation_reasons;
        const queryVacationStatuses = queryParams.vacation_statuses;

        let filter: FilterValues = this.state.filter;

        if (typeof queryEmployees === 'string') {
            filter.employeeIds = queryEmployees.split(',').map((employeeId): number => parseInt(employeeId));
        }

        if (typeof queryVacationReasons === 'string') {
            filter.vacationReasonIds = queryVacationReasons.split(',').map((vacationReasonId): number => parseInt(vacationReasonId));
        }

        if (typeof queryVacationStatuses === 'string') {
            filter.vacationStatusIds = queryVacationStatuses.split(',').map((vacationStatusId): number => parseInt(vacationStatusId));
        }

        if (Object.keys(filter).length) {
            filter.displayMonth = 'onlyNonEmptyMonths';
        }

        this.onChangeFilter(filter);
    }

    public componentDidUpdate(): void {
        if (this.props.receivingCounter === 0 && !this.state.loaded) {
            this.setState({
                loaded: true,
            });
        }
    }

    public onDatePicked = (date): void => {
        const vacationDay = this.props.vacations.filter(
            (vacation): boolean => (
                moment(date).format('YYYY-MM-DD') >= moment(vacation.start).format('YYYY-MM-DD') &&
                moment(date).format('YYYY-MM-DD') <= moment(vacation.end).format('YYYY-MM-DD')),
        );
        if (vacationDay.length > 1) {
            this.setState({
                dayVacationsDialog: {
                    open: true,
                    date,
                },
            });
        }
        if (vacationDay.length === 1) {
            this.setState({
                vacationDialog: {
                    open: true,
                    vacation: vacationDay[0],
                },
            });
        }
    };

    public onClickNextYear = (): void => {
        const newYear = this.state.calendar.year + 1;

        this.props.receiveVacations({
            ...this.state.filter,
            start: moment(`${newYear}-01-01`),
            end: moment(`${newYear}-12-31`),
        });

        this.setState({
            calendar: {
                year: newYear,
            },
        });
    };

    public onClickPrevYear = (): void => {
        const newYear = this.state.calendar.year - 1;

        this.props.receiveVacations({
            ...this.state.filter,
            start: moment(`${newYear}-01-01`),
            end: moment(`${newYear}-12-31`),
        });

        this.setState({
            calendar: {
                year: newYear,
            },
        });
    };

    public onClickDay = (date): void => {
        this.onDatePicked(moment(date));
    };

    public onClose = (): void => {
        this.setState({
            dayVacationsDialog: {
                ...this.state.dayVacationsDialog,
                open: false,
            },
        });
    };

    public onChangeFilter = (filter): void => {
        this.setState(
            {
                filter,
            },
            (): void => {
                this.props.receiveVacations({
                    ...this.state.filter,
                    start: moment(`${this.state.calendar.year}-01-01`),
                    end: moment(`${this.state.calendar.year}-12-31`),
                });
            },
        );
    };

    public render(): React.ReactElement {
        if (!this.state.loaded) {
            return <React.Fragment/>;
        }

        return (
            <Grid container direction="row" wrap="wrap" alignContent="flex-start">
                <Grid item container>
                    <Filter
                        groupsFilter
                        employeesFilter
                        vacationStatusesFilter
                        vacationReasonsFilter
                        displayMonthFilter
                        defaultValue={this.state.filter}
                        onChange={this.onChangeFilter}
                    />
                </Grid>

                <Grid item container>
                    <AnnualCalendar
                        year={this.state.calendar.year}
                        onClickPrevYear={this.onClickPrevYear}
                        onClickNextYear={this.onClickNextYear}
                        onClickDay={this.onClickDay}
                        displayOption={this.state.filter.displayMonth}
                        vacations={
                            this.props.vacations
                                .filter((vacation: Vacation): boolean => {
                                    return (vacation.employee.status.short_name == 'active');
                                })
                        }
                        employeesCount={this.props.employees.length}
                        onSelectInterval={(start: moment.Moment, end: moment.Moment): void => {
                            this.setState({
                                vacationDialog: {
                                    open: true,
                                    start,
                                    end,
                                },
                            });
                        }}
                    />
                </Grid>

                <VacationDialog
                    open={this.state.vacationDialog.open}
                    vacation={this.state.vacationDialog.vacation}
                    start={this.state.vacationDialog.start}
                    end={this.state.vacationDialog.end}
                    onClose={(): void => this.setState({
                        vacationDialog: {
                            ...this.state.vacationDialog,
                            open: false,
                        },
                    })}
                />

                <VacationsListDialog
                    open={this.state.dayVacationsDialog.open}
                    onClose={
                        (): void => {
                            this.setState({
                                dayVacationsDialog: {
                                    ...this.state.dayVacationsDialog,
                                    open: false,
                                },
                            });
                        }
                    }
                    date={this.state.dayVacationsDialog.date}
                    vacations={this.props.vacations}
                    onSelectVacation={
                        (vacation): void => {
                            this.setState({
                                vacationDialog: {
                                    open: true,
                                    vacation,
                                },
                            });
                        }
                    }
                />
            </Grid>
        );
    }
}
