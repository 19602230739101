import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import Login, {LoginDispatchToProps} from '../components/Login';
import AuthenticationService from '../services/Authentication';


const mapDispatchToProps: LoginDispatchToProps = {
    login: AuthenticationService.login,
};

export default withSnackbar(connect(null, mapDispatchToProps)(Login));
