import {Button, Grid, TextField} from '@material-ui/core';
import React from 'react';
import Select from 'react-select';
import {Option} from 'react-select/lib/filters';
import '../assets/scss/filter-select.scss';
import Employee, {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group, {
    GroupComponentWrapper,
    GroupComponentWrapperDispatchToProps,
    GroupComponentWrapperStateToProps,
} from '../models/Group';
import GroupStatus from '../models/GroupStatus';
import GroupType from '../models/GroupType';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';


export interface GroupFilterValues {
    groupSearchStr?: string;
    groupLeadsIds?: number[];
    groupTypesIds?: number[];
    groupStatusesIds?: number[];
}

interface SelectOption extends Option {
    id: number;
}

export interface GroupFilterStateToProps extends EmployeeComponentWrapperStateToProps, GroupComponentWrapperStateToProps {
    groupLeads: Employee[];
    groupTypes: GroupType[];
    groupStatuses: GroupStatus[];
}

export interface GroupFilterDispatchToProps extends EmployeeComponentWrapperDispatchToProps, GroupComponentWrapperDispatchToProps {
    receiveGroupLeads: () => void;
    receiveGroupStatuses: () => void;
    receiveGroupTypes: () => void;
}

const filterSelectStyles = {
    menu: (base): unknown => ({
        ...base,
        zIndex: 999,
    }),
};

export type GroupFilterProps = GroupFilterStateToProps & GroupFilterDispatchToProps & {
    defaultValue?: GroupFilterValues;
    groupSearch?: boolean;
    groupLeadsFilter?: boolean;
    groupTypesFilter?: boolean;
    groupStatusesFilter?: boolean;
    onChange: (filter: GroupFilterValues) => void;
};

export interface GroupFilterState {
    groupSearchStr: string;
    groupLeadsIds: number[];
    groupTypesIds: number[];
    groupStatusesIds: number[];
}

export default class GroupFilter extends React.Component<GroupFilterProps, GroupFilterState> implements EmployeeComponentWrapper, GroupComponentWrapper {
    public constructor(props: GroupFilterProps) {
        super(props);

        const defaultValue: GroupFilterValues = this.props.defaultValue || {};

        this.state = {
            groupSearchStr: defaultValue.groupSearchStr || '',
            groupLeadsIds: defaultValue.groupLeadsIds || [],
            groupTypesIds: defaultValue.groupTypesIds || [],
            groupStatusesIds: defaultValue.groupStatusesIds || [],
        };
    }

    public componentDidMount(): void {
        this.props.receiveGroupLeads();
        this.props.receiveGroupStatuses();
        this.props.receiveGroupTypes();
    }

    public changeGroupSearch = (e): void => {
        this.setState({
            groupSearchStr: e.target.value,
        });
    };

    public changeGroupLeadsFilter = (selectedOptions): void => {
        if (selectedOptions.length > 0) {
            const groupLeadsIds = selectedOptions.map(
                (selectedOption: SelectOption): number => selectedOption.data.id,
            );

            this.setState({
                groupLeadsIds,
            });
        } else {
            this.setState(
                {
                    groupLeadsIds: [],
                }, this.onSubmit,
            );
        }
    };

    public changeGroupTypesFilter = (selectedOptions): void => {
        if (selectedOptions.length > 0) {
            const groupTypesIds = selectedOptions.map(
                (selectedOption: SelectOption): number => selectedOption.data.id,
            );

            this.setState({
                groupTypesIds,
            });
        } else {
            this.setState(
                {
                    groupTypesIds: [],
                }, this.onSubmit,
            );
        }
    };

    public changeGroupStatusesFilter = (selectedOptions): void => {
        if (selectedOptions.length > 0) {
            const groupStatusesIds = selectedOptions.map(
                (selectedOption: SelectOption): number => selectedOption.data.id,
            );

            this.setState({
                groupStatusesIds,
            });
        } else {
            this.setState(
                {
                    groupStatusesIds: [],
                }, this.onSubmit,
            );
        }
    };

    public onSubmit = (): void => {
        this.props.onChange({
            groupSearchStr: this.state.groupSearchStr,
            groupLeadsIds: this.state.groupLeadsIds,
            groupTypesIds: this.state.groupTypesIds,
            groupStatusesIds: this.state.groupStatusesIds,
        });
    };

    public handleKeyDown = (event): void => {
        if (event.key === 'Enter') {
            this.onSubmit();
        }
    };

    public render(): React.ReactElement {
        return (
            <Grid container className="filter-container" direction="row" wrap="wrap">
                {
                    this.props.groupSearch &&
                    <Grid item xs className="filter-item">
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search..."
                            inputProps={{
                                style: {
                                    height: '38px',
                                    padding: '0 14px',
                                },
                            }}
                            onChange={this.changeGroupSearch}
                            value={this.state.groupSearchStr}
                        />
                    </Grid>
                }

                {
                    this.props.groupLeadsFilter &&
                    <Grid item xs className="filter-item">
                        <Select
                            isMulti
                            name="group-leads"
                            placeholder="Select group leads..."
                            classNamePrefix="Group Lead"
                            styles={filterSelectStyles}
                            noOptionsMessage={(): string => 'No matches found'}
                            onChange={this.changeGroupLeadsFilter}
                            onKeyDown={this.handleKeyDown}
                            value={this.getGroupLeadsOptions(this.state.groupLeadsIds)}
                            options={
                                this.getGroupLeadsOptions(
                                    this.props.employees
                                        .sort(
                                            (a, b): number =>
                                                `${a.last_name} ${a.first_name}`
                                                    .localeCompare(`${b.last_name} ${b.first_name}`),
                                        )
                                        .map((employee): number => employee.id),
                                )
                            }
                        />
                    </Grid>
                }

                {
                    this.props.groupTypesFilter &&
                    <Grid item xs className="filter-item">
                        <Select
                            isMulti
                            name="group-types"
                            placeholder="Select group types..."
                            className="basic-single second-select"
                            classNamePrefix="GroupType"
                            styles={filterSelectStyles}
                            noOptionsMessage={(): string => 'No matches found'}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.changeGroupTypesFilter}
                            value={this.getGroupTypesOptions(this.state.groupTypesIds || [])}
                            options={
                                this.getGroupTypesOptions(
                                    this.props.groupTypes
                                        .sort(
                                            (a, b): number =>
                                                a.display_name.localeCompare(b.display_name),
                                        )
                                        .map((groupType): number => groupType.id),
                                )
                            }
                        />
                    </Grid>
                }

                {
                    this.props.groupStatusesFilter &&
                    <Grid item xs className="filter-item">
                        <Select
                            isMulti
                            name="group-statuses"
                            placeholder="Select group statuses..."
                            classNamePrefix="Group Status"
                            styles={filterSelectStyles}
                            noOptionsMessage={(): string => 'No matches found'}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.changeGroupStatusesFilter}
                            value={this.getGroupStatusesOptions(this.state.groupStatusesIds || [])}
                            options={
                                this.getGroupStatusesOptions(
                                    this.props.groupStatuses
                                        .sort(
                                            (a, b): number =>
                                                a.display_name.localeCompare(b.display_name),
                                        )
                                        .map((groupStatus): number => groupStatus.id),
                                )
                            }
                        />
                    </Grid>
                }

                {
                    (
                        this.props.groupLeadsFilter ||
                        this.props.groupTypesFilter ||
                        this.props.groupStatusesFilter
                    ) &&
                    <Grid item>
                        <Button
                            disableFocusRipple
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={this.onSubmit}
                        >
                            Apply
                        </Button>
                    </Grid>
                }
            </Grid>
        );
    }

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );

    public getGroupMembers = (groupId: number): Employee[] =>
        this.props.groupMembers[groupId] || [];

    private getGroupNamesOptions = (groupNamesIds: number[]): Option[] =>
        groupNamesIds
            .map((groupNameId): Option => {
                const group = this.props.groups.find((group): boolean => group.id === groupNameId);

                if (group) {
                    return {
                        label: group.display_name,
                        value: group.short_name,
                        data: group,
                    };
                } else {
                    return {
                        label: '',
                        value: '',
                        data: null,
                    };
                }
            })
            .filter((option: Option): boolean => !!option.data);

    private getGroupLeadsOptions = (employeeIds: number[]): Option[] =>
        employeeIds
            .map((employeeId): Option => {
                const employee = this.props.employees.find((employee): boolean => employee.id === employeeId);

                if (employee) {
                    return {
                        label: `${employee.last_name} ${employee.first_name}`,
                        value: employee.username,
                        data: employee,
                    };
                } else {
                    return {
                        label: '',
                        value: '',
                        data: null,
                    };
                }
            })
            .filter((option: Option): boolean => !!option.data);

    private getGroupTypesOptions = (groupTypesIds: number[]): Option[] =>
        groupTypesIds
            .map((groupTypeId): Option => {
                const groupType = this.props.groupTypes.find((groupType): boolean => groupType.id === groupTypeId);

                if (groupType) {
                    return {
                        label: groupType.display_name,
                        value: groupType.short_name,
                        data: groupType,
                    };
                } else {
                    return {
                        label: '',
                        value: '',
                        data: null,
                    };
                }
            })
            .filter((option: Option): boolean => !!option.data);

    private getGroupStatusesOptions = (groupStatusesIds: number[]): Option[] =>
        groupStatusesIds
            .map((groupStatusId): Option => {
                const groupStatus = this.props.groupStatuses.find((groupStatus): boolean => groupStatus.id === groupStatusId);

                if (groupStatus) {
                    return {
                        label: groupStatus.display_name,
                        value: groupStatus.short_name,
                        data: groupStatus,
                    };
                } else {
                    return {
                        label: '',
                        value: '',
                        data: null,
                    };
                }
            })
            .filter((option: Option): boolean => !!option.data);
}
