import {connect} from 'react-redux';
import Header, {HeaderDispatchToProps, HeaderStateToProps} from '../components/Header';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import AlertService from '../services/Alert';
import AuthenticationService from '../services/Authentication';
import SystemNotificationService from '../services/SystemNotification';
import VacationReasonService from '../services/VacationReason';


const mapStateToProps = (state): HeaderStateToProps => ({
    vacationReasons: state.VacationReason.vacationReasons,
    isDataReceiving:
        state.Employee.receivingCounter > 0 ||
        state.Group.receivingCounter > 0 ||
        state.Vacation.receivingCounter > 0,
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: HeaderDispatchToProps = {
    receiveVacationReasons: VacationReasonService.receive,
    pushSystemNotification: SystemNotificationService.pushInfo,
    showAlert: AlertService.show,
    logout: AuthenticationService.logout,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
