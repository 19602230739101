import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import VacationDialog, {VacationDialogDispatchToProps, VacationDialogStateToProps} from '../components/VacationDialog';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import {groupComponentWrapperMapDispatchToProps, groupComponentWrapperMapStateToProps} from '../models/Group';
import {vacationComponentWrapperMapDispatchToProps, vacationComponentWrapperMapStateToProps} from '../models/Vacation';
import AlertService from '../services/Alert';
import SystemNotificationService from '../services/SystemNotification';
import VacationService from '../services/Vacation';


const mapStateToProps = (state): VacationDialogStateToProps => {
    return {
        vacationReasons: state.VacationReason.vacationReasons,
        ...vacationComponentWrapperMapStateToProps(state),
        ...groupComponentWrapperMapStateToProps(state),
        ...employeeComponentWrapperMapStateToProps(state),
    };
};

const mapDispatchToProps: VacationDialogDispatchToProps = {
    pushVacation: VacationService.create,
    updateVacation: VacationService.update,
    removeVacation: VacationService.remove,
    showAlert: AlertService.show,
    pushSystemNotification: SystemNotificationService.pushInfo,
    approveVacationRequest: VacationService.approveVacationRequest,
    rejectVacationRequest: VacationService.rejectVacationRequest,
    ...vacationComponentWrapperMapDispatchToProps,
    ...groupComponentWrapperMapDispatchToProps,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(VacationDialog));
