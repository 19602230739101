import {connect} from 'react-redux';
import ManageEmployeeRolesDialog, {
    ManageEmployeeRolesDialogDispatchToProps,
    ManageEmployeeRolesDialogStateToProps,
} from '../components/ManageEmployeeRolesDialog';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import {groupComponentWrapperMapDispatchToProps, groupComponentWrapperMapStateToProps} from '../models/Group';
import EmployeeService from '../services/Employee';
import GroupService from '../services/Group';
import RoleService from '../services/Role';


const mapStateToProps = (state): ManageEmployeeRolesDialogStateToProps => ({
    groups: state.Group.groups,
    roles: state.Role.roles,
    employeeRoles: state.Employee.roles,
    groupMembers: state.Group.members,
    ...groupComponentWrapperMapStateToProps(state),
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: ManageEmployeeRolesDialogDispatchToProps = {
    receiveEmployeeRoles: EmployeeService.receiveRoles,
    receiveEmployeeGroups: EmployeeService.receiveGroups,
    receiveGroupMembers: GroupService.receiveMembers,
    addEmployeeRole: EmployeeService.addRole,
    removeEmployeeRole: EmployeeService.removeRole,
    receiveRoles: RoleService.receive,
    ...groupComponentWrapperMapDispatchToProps,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageEmployeeRolesDialog);
