import {Action} from 'redux';
import EmployeeStatus from '../models/EmployeeStatus';


export type EmployeeStatusActionStatuses =
    'RECEIVE_EMPLOYEE_STATUSES_STARTED' |
    'RECEIVE_EMPLOYEE_STATUSES_FINISHED';

export const receiveEmployeeStatusesStarted = (): Action<EmployeeStatusActionStatuses> => ({
    type: 'RECEIVE_EMPLOYEE_STATUSES_STARTED',
});

export interface ReceiveEmployeeStatusesFinishedAction extends Action<EmployeeStatusActionStatuses> {
    employeeStatuses: EmployeeStatus[];
}

export const receiveEmployeeStatusesFinished = (employeeStatuses: EmployeeStatus[]): ReceiveEmployeeStatusesFinishedAction => ({
    type: 'RECEIVE_EMPLOYEE_STATUSES_FINISHED',
    employeeStatuses,
});

export type EmployeeStatusAction =
    ReceiveEmployeeStatusesFinishedAction;

