import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import App, {AppDispatchToProps, AppStateToProps} from '../components/App';
import AlertService from '../services/Alert';
import SystemNotificationService from '../services/SystemNotification';


const mapStateToProps = (state): AppStateToProps => ({
    systemNotification: state.SystemNotification.systemNotification,
    alert: state.Alert.alert,
});

const mapDispatchToProps: AppDispatchToProps = {
    pushSystemNotification: SystemNotificationService.pushInfo,
    clearSystemNotification: SystemNotificationService.clear,
    showAlert: AlertService.show,
    hideAlert: AlertService.hideAlert,
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(App));
