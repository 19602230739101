import {Action} from 'redux';
import Settings from '../models/Settings';


export type SettingsActionTypes =
    'RECEIVE_SETTINGS_STARTED' |
    'RECEIVE_SETTINGS_FINISHED';

export const receiveSettingsStarted = (): Action<SettingsActionTypes> => ({
    type: 'RECEIVE_SETTINGS_STARTED',
});

export interface ReceiveSettingsFinishedAction extends Action<SettingsActionTypes> {
    settings: Settings;
}

export const receiveSettingsFinished = (settings: Settings): ReceiveSettingsFinishedAction => ({
    type: 'RECEIVE_SETTINGS_FINISHED',
    settings,
});

export type SettingsAction =
    ReceiveSettingsFinishedAction;
