import {Action} from 'redux';
import GroupStatus from '../models/GroupStatus';


export type GroupStatusActionTypes =
    'RECEIVE_GROUP_STATUSES_STARTED' |
    'RECEIVE_GROUP_STATUSES_FINISHED';

export const receiveGroupStatusesStarted = (): Action<GroupStatusActionTypes> => ({
    type: 'RECEIVE_GROUP_STATUSES_STARTED',
});

export interface ReceiveGroupStatusesFinishedAction extends Action<GroupStatusActionTypes> {
    groupStatuses: GroupStatus[];
}

export const receiveGroupStatusesFinished = (groupStatuses: GroupStatus[]): ReceiveGroupStatusesFinishedAction => ({
    type: 'RECEIVE_GROUP_STATUSES_FINISHED',
    groupStatuses,
});

export type GroupStatusAction =
    ReceiveGroupStatusesFinishedAction;
