import {Action} from 'redux';
import {EmployeesFilterValues} from '../components/EmployeesFilter';
import Employee from '../models/Employee';
import Group from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';


export type EmployeeActionTypes =
    'RECEIVE_EMPLOYEES_STARTED' |
    'RECEIVE_EMPLOYEES_FINISHED' |

    'RECEIVE_EMPLOYEE_ROLES_STARTED' |
    'RECEIVE_EMPLOYEE_ROLES_FINISHED' |

    'RECEIVE_EMPLOYEE_NOTIFICATIONS_STARTED' |
    'RECEIVE_EMPLOYEE_NOTIFICATIONS_FINISHED' |

    'RECEIVE_EMPLOYEE_VACATION_REQUESTS_STARTED' |
    'RECEIVE_EMPLOYEE_VACATION_REQUESTS_FINISHED' |

    'RECEIVE_EMPLOYEE_VACATIONS_STARTED' |
    'RECEIVE_EMPLOYEE_VACATIONS_FINISHED' |

    'RECEIVE_EMPLOYEE_GROUPS_STARTED' |
    'RECEIVE_EMPLOYEE_GROUPS_FINISHED' |

    'RECEIVE_ME_STARTED' |
    'RECEIVE_ME_FINISHED' |

    'RECEIVE_FINISHED_WITH_ERROR';

// RECEIVE_EMPLOYEES

export interface ReceiveEmployeesStartedAction extends Action<EmployeeActionTypes> {
    limit?: number;
    offset?: number;
    search?: string;
    ordering?: string;
    filter?: EmployeesFilterValues;
}

export const receiveEmployeesStarted = (limit?: number, offset?: number, search?: string, ordering?: string, filter?: EmployeesFilterValues): ReceiveEmployeesStartedAction => ({
    type: 'RECEIVE_EMPLOYEES_STARTED',
    limit,
    offset,
    search,
    ordering,
    filter,
});

export interface ReceiveEmployeesFinishedAction extends Action<EmployeeActionTypes> {
    employees: Employee[];
    count?: number;
}

export const receiveEmployeesFinished = (employees: Employee[], count: number): ReceiveEmployeesFinishedAction => ({
    type: 'RECEIVE_EMPLOYEES_FINISHED',
    employees,
    count,
});

// RECEIVE_EMPLOYEE_ROLES

export interface ReceiveEmployeeRolesStartedAction extends Action<EmployeeActionTypes> {
    employeeId: number;
}

export const receiveEmployeeRolesStarted = (employeeId: number): ReceiveEmployeeRolesStartedAction => ({
    type: 'RECEIVE_EMPLOYEE_ROLES_STARTED',
    employeeId,
});

export interface ReceiveEmployeeRolesFinishedAction extends Action<EmployeeActionTypes> {
    employeeId: number;
    roles: Role[];
}

export const receiveEmployeeRolesFinished = (employeeId: number, roles: Role[]): ReceiveEmployeeRolesFinishedAction => ({
    type: 'RECEIVE_EMPLOYEE_ROLES_FINISHED',
    employeeId,
    roles,
});

// RECEIVE_EMPLOYEE_NOTIFICATIONS

export const receiveEmployeeNotificationsStarted = (): Action<EmployeeActionTypes> => ({
    type: 'RECEIVE_EMPLOYEE_NOTIFICATIONS_STARTED',
});

export interface ReceiveEmployeeNotificationsFinishedAction extends Action<EmployeeActionTypes> {
    notifications: Notification[];
}

export const receiveEmployeeNotificationsFinished = (notifications: Notification[]): ReceiveEmployeeNotificationsFinishedAction => ({
    type: 'RECEIVE_EMPLOYEE_NOTIFICATIONS_FINISHED',
    notifications,
});

// RECEIVE_EMPLOYEE_VACATION_REQUESTS

export const receiveEmployeeVacationRequestsStarted = (): Action<EmployeeActionTypes> => ({
    type: 'RECEIVE_EMPLOYEE_VACATION_REQUESTS_STARTED',
});

export interface ReceiveEmployeeVacationRequestsFinishedAction extends Action<EmployeeActionTypes> {
    vacationRequests: VacationRequest[];
}

export const receiveEmployeeVacationRequestsFinished = (vacationRequests: VacationRequest[]): ReceiveEmployeeVacationRequestsFinishedAction => ({
    type: 'RECEIVE_EMPLOYEE_VACATION_REQUESTS_FINISHED',
    vacationRequests,
});

// RECEIVE_EMPLOYEE_VACATIONS

export interface ReceiveEmployeeVacationsStartedAction extends Action<EmployeeActionTypes> {
    employeeId: number;
}

export const receiveEmployeeVacationsStarted = (employeeId: number): ReceiveEmployeeVacationsStartedAction => ({
    type: 'RECEIVE_EMPLOYEE_VACATIONS_STARTED',
    employeeId,
});

export interface ReceiveEmployeeVacationsFinishedAction extends Action<EmployeeActionTypes> {
    employeeId: number;
    vacations: Vacation[];
}

export const receiveEmployeeVacationsFinished = (employeeId: number, vacations: Vacation[]): ReceiveEmployeeVacationsFinishedAction => ({
    type: 'RECEIVE_EMPLOYEE_VACATIONS_FINISHED',
    employeeId,
    vacations,
});

// RECEIVE_EMPLOYEE_GROUPS

export interface ReceiveEmployeeGroupsStartedAction extends Action<EmployeeActionTypes> {
    employeeId: number;
}

export const receiveEmployeeGroupsStarted = (employeeId: number): ReceiveEmployeeGroupsStartedAction => ({
    type: 'RECEIVE_EMPLOYEE_GROUPS_STARTED',
    employeeId,
});

export interface ReceiveEmployeeGroupsFinishedAction extends Action<EmployeeActionTypes> {
    employeeId: number;
    groups: Group[];
}

export const receiveEmployeeGroupsFinished = (employeeId: number, groups: Group[]): ReceiveEmployeeGroupsFinishedAction => ({
    type: 'RECEIVE_EMPLOYEE_GROUPS_FINISHED',
    employeeId,
    groups,
});

// RECEIVE_ME

export const receiveMeStarted = (): Action<EmployeeActionTypes> => ({
    type: 'RECEIVE_ME_STARTED',
});

export interface ReceiveMeFinishedAction extends Action<EmployeeActionTypes> {
    me: Employee;
}

export const receiveMeFinished = (me: Employee): ReceiveMeFinishedAction => ({
    type: 'RECEIVE_ME_FINISHED',
    me,
});

// RECEIVE_ERROR

export const receiveEmployeesFinishedWithError = (): Action<EmployeeActionTypes> => ({
    type: 'RECEIVE_FINISHED_WITH_ERROR',
});

export type EmployeeAction =
    ReceiveEmployeesStartedAction &
    ReceiveEmployeesFinishedAction &

    ReceiveEmployeeRolesStartedAction &
    ReceiveEmployeeRolesFinishedAction &

    ReceiveEmployeeNotificationsFinishedAction &

    ReceiveEmployeeVacationRequestsFinishedAction &

    ReceiveEmployeeVacationsStartedAction &
    ReceiveEmployeeVacationsFinishedAction &

    ReceiveEmployeeGroupsStartedAction &
    ReceiveEmployeeGroupsFinishedAction &

    ReceiveMeFinishedAction;
