import {Button, Grid, TextField} from '@material-ui/core';
import React from 'react';
import Select from 'react-select';
import {Option} from 'react-select/lib/filters';
import '../assets/scss/filter-select.scss';
import Employee, {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import EmployeeStatus from '../models/EmployeeStatus';
import Group, {
    GroupComponentWrapper,
    GroupComponentWrapperDispatchToProps,
    GroupComponentWrapperStateToProps,
} from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';


export interface EmployeesFilterValues {
    employeeSearchStr?: string;
    employeeStatusesIds?: number[];
}

interface SelectOption extends Option {
    id: number;
}

export interface EmployeesFilterStateToProps extends EmployeeComponentWrapperStateToProps, GroupComponentWrapperStateToProps {
    employeeStatuses: EmployeeStatus[];
}

export interface EmployeesFilterDispatchToProps extends EmployeeComponentWrapperDispatchToProps, GroupComponentWrapperDispatchToProps {
    receiveEmployeeStatuses: () => void;
}

const filterSelectStyles = {
    menu: (base): unknown => ({
        ...base,
        zIndex: 999,
    }),
};

export type EmployeesFilterProps = EmployeesFilterStateToProps & EmployeesFilterDispatchToProps & {
    defaultValue?: EmployeesFilterValues;
    employeeSearch?: boolean;
    employeesStatusesFilter?: boolean;

    onChange: (filter: EmployeesFilterValues) => void;
};

export interface EmployeesFilterState {
    employeeSearchStr: string;
    employeeStatusesIds: number[];
}

export default class EmployeesFilter extends React.Component<EmployeesFilterProps, EmployeesFilterState> implements EmployeeComponentWrapper, GroupComponentWrapper {
    public constructor(props: EmployeesFilterProps) {
        super(props);

        const defaultValue: EmployeesFilterValues = this.props.defaultValue || {};

        this.state = {
            employeeSearchStr: defaultValue.employeeSearchStr || '',
            employeeStatusesIds: defaultValue.employeeStatusesIds || [],
        };
    }

    public componentDidMount(): void {
        this.props.receiveEmployeeStatuses();

        (!this.props.groups || !this.props.groups.length) && this.props.receiveGroups();
    }

    public changeEmployeeSearch = (e): void => {
        this.setState({
            employeeSearchStr: e.target.value,
        });
    };

    public changeEmployeeStatusesFilter = (selectedOptions): void => {
        if (selectedOptions.length > 0) {
            const employeeStatusesIds = selectedOptions.map(
                (selectedOption: SelectOption): number => selectedOption.data.id,
            );

            this.setState({
                employeeStatusesIds,
            });
        } else {
            this.setState(
                {
                    employeeStatusesIds: [],
                }, this.onSubmit,
            );
        }
    };

    public onSubmit = (): void => {
        this.props.onChange({
            employeeSearchStr: this.state.employeeSearchStr,
            employeeStatusesIds: this.state.employeeStatusesIds,
        });
    };

    public handleKeyDown = (event): void => {
        if (event.key === 'Enter') {
            this.onSubmit();
        }
    };

    public render(): React.ReactElement {
        return (
            <Grid container className="filter-container" direction="row" wrap="wrap">
                {
                    this.props.employeeSearch &&
                    <Grid item xs className="filter-item">
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search..."
                            inputProps={{
                                style: {
                                    height: '38px',
                                    padding: '0 14px',
                                },
                            }}
                            onChange={this.changeEmployeeSearch}
                            value={this.state.employeeSearchStr}
                        />
                    </Grid>
                }

                {
                    this.props.employeesStatusesFilter &&
                    <Grid item xs className="filter-item">
                        <Select
                            isMulti
                            name="user_status"
                            placeholder="Select user statuses..."
                            className="basic-single second-select"
                            classNamePrefix="UserStatus"
                            styles={filterSelectStyles}
                            noOptionsMessage={(): string => 'No matches found'}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.changeEmployeeStatusesFilter}
                            value={
                                this.getEmployeesStatusesOptions(this.state.employeeStatusesIds ||
                                    [])
                            }
                            options={
                                this.getEmployeesStatusesOptions(
                                    this.props.employeeStatuses
                                        .sort(
                                            (a, b): number =>
                                                a.display_name.localeCompare(b.display_name),
                                        )
                                        .map((employeeStatus): number => employeeStatus.id),
                                )
                            }
                        />
                    </Grid>
                }

                {
                    (
                        this.props.employeeSearch ||
                        this.props.employeesStatusesFilter
                    ) &&
                    <Grid item>
                        <Button
                            disableFocusRipple
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={this.onSubmit}
                        >
                            Apply
                        </Button>
                    </Grid>
                }
            </Grid>
        );
    }

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );

    public getGroupMembers = (groupId: number): Employee[] =>
        this.props.groupMembers[groupId] || [];

    private getEmployeeOptions = (employeeIds: number[]): Option[] =>
        employeeIds
            .map((employeeId): Option => {
                const employee = this.props.employees.find((employee): boolean => employee.id === employeeId);

                if (employee) {
                    return {
                        label: `${employee.last_name} ${employee.first_name}`,
                        value: employee.username,
                        data: employee,
                    };
                } else {
                    return {
                        label: '',
                        value: '',
                        data: null,
                    };
                }
            })
            .filter((option: Option): boolean => !!option.data);

    private getEmployeesStatusesOptions = (employeeStatusesIds: number[]): Option[] =>
        employeeStatusesIds
            .map((employeeStatusId): Option => {
                const employeeStatus = this.props.employeeStatuses.find((employeeStatus): boolean => employeeStatus.id === employeeStatusId);

                if (employeeStatus) {
                    return {
                        label: `${employeeStatus.display_name}`,
                        value: employeeStatus.short_name,
                        data: employeeStatus,
                    };
                } else {
                    return {
                        label: '',
                        value: '',
                        data: null,
                    };
                }
            })
            .filter((option: Option): boolean => !!option.data);
}
