import {GroupTypeAction} from '../actions/GroupType';
import GroupType from '../models/GroupType';


export type GroupTypeReducerReceivingTarget =
    'GROUP_TYPES';

export interface GroupTypeReducerState {
    groupTypes: GroupType[];
    receivingCounter: number;
}

const initialState: GroupTypeReducerState = {
    groupTypes: [],
    receivingCounter: 0,
};

const GroupTypeReducer = (state: GroupTypeReducerState = initialState, action: GroupTypeAction): GroupTypeReducerState => {
    switch (action.type) {
        case 'RECEIVE_GROUP_TYPES_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_GROUP_TYPES_FINISHED':
            state.receivingCounter--;
            state.groupTypes = action.groupTypes;
            break;
    }

    return {...state};
};

export default GroupTypeReducer;
