import {connect} from 'react-redux';
import Filter, {FilterDispatchToProps, FilterStateToProps} from '../components/Filter';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import {groupComponentWrapperMapDispatchToProps, groupComponentWrapperMapStateToProps} from '../models/Group';
import EmployeeService from '../services/Employee';
import GroupService from '../services/Group';
import VacationReasonService from '../services/VacationReason';
import VacationStatusService from '../services/VacationStatus';


const mapStateToProps = (state): FilterStateToProps => ({
    groups: state.Group.groups,
    employees: state.Employee.employees,
    vacationStatuses: state.VacationStatus.vacationStatuses,
    vacationReasons: state.VacationReason.vacationReasons,
    ...groupComponentWrapperMapStateToProps(state),
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: FilterDispatchToProps = {
    receiveGroups: GroupService.receive,
    receiveEmployees: EmployeeService.receive,
    receiveVacationStatuses: VacationStatusService.receive,
    receiveVacationReasons: VacationReasonService.receive,
    ...groupComponentWrapperMapDispatchToProps,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
