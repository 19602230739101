import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import AnnualCalendarPage, {
    AnnualCalendarDispatchToProps,
    AnnualCalendarStateToProps,
} from '../components/AnnualCalendarPage';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import VacationService from '../services/Vacation';
import VacationReasonService from '../services/VacationReason';


const mapStateToProps = (state): AnnualCalendarStateToProps => ({
    vacations: state.Vacation.vacations,
    vacationReasons: state.VacationReason.vacationReasons,
    receivingCounter: state.Employee.receivingCounter + state.Vacation.receivingCounter + state.VacationReason.receivingCounter,
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: AnnualCalendarDispatchToProps = {
    receiveVacations: VacationService.receive,
    receiveVacationReasons: VacationReasonService.receive,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(AnnualCalendarPage));
