import {connect} from 'react-redux';
import ProfilePage, {ProfilePageDispatchToProps, ProfilePageStateToProps} from '../components/ProfilePage';
import Employee, {
    employeeComponentWrapperMapDispatchToProps,
    employeeComponentWrapperMapStateToProps,
} from '../models/Employee';
import AlertService from '../services/Alert';
import EmployeeService from '../services/Employee';
import EmployeeStatusService from '../services/EmployeeStatus';
import SystemNotificationService from '../services/SystemNotification';


const mapStateToProps = (state, ownProps): ProfilePageStateToProps => {
    return {
        employee: state.Employee.employees.find((employee: Employee): boolean => employee.id == ownProps.match.params.id),
        employeeStatuses: state.EmployeeStatus.employeeStatuses,
        ...employeeComponentWrapperMapStateToProps(state),
    };
};

const mapDispatchToProps: ProfilePageDispatchToProps = {
    updateEmployee: EmployeeService.update,
    showAlert: AlertService.show,
    pushSystemNotification: SystemNotificationService.pushInfo,
    receiveEmployeeStatuses: EmployeeStatusService.receive,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
