import {Avatar} from '@material-ui/core';
import {AvatarProps} from '@material-ui/core/Avatar';
import React from 'react';
import Employee from '../models/Employee';


export interface EmployeeAvatarProps {
    employee: Employee;
}

export default class EmployeeAvatar extends React.Component<EmployeeAvatarProps & AvatarProps> {
    public render(): React.ReactElement {
        if (!this.props.employee) {
            return <React.Fragment/>;
        }

        const employeeInitials = (this.props.employee.first_name[0] || '').toUpperCase() + (this.props.employee.last_name[0] || '').toUpperCase();

        return (
            <Avatar
                {...this.props}
                style={{
                    background: this.props.employee.avatar ? `url(${this.props.employee.avatar}) center center / cover no-repeat` : `gray`,
                    ...this.props.style,
                }}
            >
                {this.props.children || (!this.props.employee.avatar && employeeInitials)}
            </Avatar>
        );
    }
}
