import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    ExpansionPanel,
    ExpansionPanelActions,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import React from 'react';
import Settings, {SettingsObj} from '../models/Settings';


export type ExpansionPanelName = '' | 'general' | 'email';

export interface SettingsPageStateToProps {
    settings: Settings;
}

export interface SettingsPageDispatchToProps {
    receiveSettings: () => void;
    updateSettings: (settings: SettingsObj) => void;
}

export type SettingsPageProps = SettingsPageStateToProps & SettingsPageDispatchToProps;

export interface SettingsPageState {
    expansionPanels: {
        expandedPanel: ExpansionPanelName;
    };
    settings: SettingsObj;
    password?: string;
    confirmPassword?: string;
    passwordsNotEquals: boolean;
}

export default class SettingsPage extends React.Component<SettingsPageProps, SettingsPageState> {
    public constructor(props) {
        super(props);

        this.state = {
            expansionPanels: {
                expandedPanel: '',
            },
            settings: {},
            password: '',
            confirmPassword: '',
            passwordsNotEquals: false,
        };
    }

    public componentDidMount(): void {
        this.props.receiveSettings();
    }

    public componentDidUpdate(prevProps: Readonly<SettingsPageProps>): void {
        if (this.props.settings != prevProps.settings) {
            this.setState({
                settings: this.props.settings.reduce((prev, cur): SettingsObj => {
                    prev[cur.key] = cur.value;

                    return prev;
                }, {}),
            });
        }
    }

    public expandPanel = (panelName: ExpansionPanelName): void => {
        this.setState({
            expansionPanels: {
                expandedPanel: this.state.expansionPanels.expandedPanel === panelName ? '' : panelName,
            },
        });
    };

    public updateSettings = (): void => {
        if (this.state.password !== this.state.confirmPassword) {
            this.setState({
                passwordsNotEquals: true,
            });
        } else {
            this.props.updateSettings({
                ...this.state.settings,
                smtp_pass: this.state.password.length > 0 ? this.state.password : undefined,
            });

            this.setState({
                password: '',
                confirmPassword: '',
                passwordsNotEquals: false,
            });

        }
    };

    public render(): React.ReactElement {
        const isEmptySubjectPrefix =
            !this.state.settings.message_subject_prefix ||
            this.state.settings.message_subject_prefix.length === 0;

        const isEmptyMessageSenderName =
            !this.state.settings.message_sender_name ||
            this.state.settings.message_sender_name.length === 0;

        return (
            <Grid container direction="column" wrap="nowrap" alignContent="flex-start" style={{padding: '8px'}}>
                <ExpansionPanel
                    expanded={this.state.expansionPanels.expandedPanel === 'email'}
                    onChange={(): void => this.expandPanel('email')}
                >
                    <ExpansionPanelSummary
                        expandIcon={<Icons.ExpandMore/>}
                    >
                        <Typography>Email Settings</Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails>
                        <Grid container direction="row" wrap="wrap" spacing={2}>
                            <Grid item xs>
                                <Card style={{width: '100%', minWidth: '300px'}}>
                                    <CardHeader
                                        title="SMTP Settings"
                                        subheader="Email Server Configuration"
                                    />

                                    <CardContent style={{paddingTop: '0'}}>
                                        <Grid container direction="column" wrap="nowrap">
                                            <TextField
                                                label="Hostname"
                                                inputProps={{
                                                    maxLength: 64,
                                                }}
                                                value={this.state.settings.smtp_host || ''}
                                                onChange={
                                                    (event): void => {
                                                        const value = event.target.value.replace(/\s\s+/, ' ').trimLeft();

                                                        this.setState({
                                                            settings: {
                                                                ...this.state.settings,
                                                                smtp_host: value,
                                                            },
                                                        });
                                                    }
                                                }
                                            />

                                            <TextField
                                                inputProps={{
                                                    pattern: '[0-9]+',
                                                    min: 0,
                                                    max: 65535,
                                                }}
                                                label="Port"
                                                margin="dense"
                                                value={this.state.settings.smtp_port || ''}
                                                onChange={
                                                    (event): void => {
                                                        let value = parseInt(event.target.value, 10) || 0;

                                                        if (value < 0) {
                                                            value = 0;
                                                        } else if (value > 65535) {
                                                            value = 65535;
                                                        }

                                                        this.setState({
                                                            settings: {
                                                                ...this.state.settings,
                                                                smtp_port: value.toString(),
                                                            },
                                                        });
                                                    }
                                                }
                                            />

                                            <FormControl margin="dense">
                                                <InputLabel>
                                                    Transport Protocol
                                                </InputLabel>

                                                <Select
                                                    defaultValue={'default'}
                                                    value={this.state.settings.smtp_prot}
                                                    onChange={(event): void => {
                                                        this.setState({
                                                            settings: {
                                                                ...this.state.settings,
                                                                smtp_prot: event.target.value as string,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <MenuItem value="default">Default</MenuItem>
                                                    <MenuItem value="tls">TLS</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <TextField
                                                label="Username"
                                                inputProps={{
                                                    maxLength: 64,
                                                }}
                                                margin="dense"
                                                value={this.state.settings.smtp_user || ''}
                                                onChange={
                                                    (event): void => {
                                                        const value = event.target.value.replace(/\s\s+/, ' ').trimLeft();

                                                        this.setState({
                                                            settings: {
                                                                ...this.state.settings,
                                                                smtp_user: value,
                                                            },
                                                        });
                                                    }
                                                }
                                            />

                                            <TextField
                                                autoComplete="new-password"
                                                type="password"
                                                label="Password"
                                                margin="dense"
                                                helperText={this.state.passwordsNotEquals && 'Entered passwords not equals'}
                                                error={this.state.passwordsNotEquals}
                                                value={this.state.password}
                                                onChange={
                                                    (event): void => {
                                                        this.setState({
                                                            password: event.target.value,
                                                        });
                                                    }
                                                }
                                            />

                                            <TextField
                                                autoComplete="new-password"
                                                type="password"
                                                label="Confirm Password"
                                                margin="dense"
                                                value={this.state.confirmPassword}
                                                onChange={
                                                    (event): void => {
                                                        this.setState({
                                                            confirmPassword: event.target.value,
                                                        });
                                                    }
                                                }
                                            />
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs>
                                <Card style={{width: '100%', minWidth: '300px'}}>
                                    <CardHeader
                                        title="Messages Configuration"
                                        subheader="Client Email Messages Configuration"
                                    />

                                    <CardContent style={{paddingTop: '0'}}>
                                        <Grid container direction="column" wrap="nowrap">
                                            <TextField
                                                error={isEmptySubjectPrefix}
                                                helperText={
                                                    isEmptySubjectPrefix &&
                                                    'This field must be filled'
                                                }
                                                label="Subject Prefix"
                                                inputProps={{
                                                    maxLength: '64',
                                                }}
                                                value={this.state.settings.message_subject_prefix || ''}
                                                onChange={
                                                    (event): void => {
                                                        this.setState({
                                                            settings: {
                                                                ...this.state.settings,
                                                                message_subject_prefix: event.target.value.replace(/\s\s+/, ' ').trimLeft(),
                                                            },
                                                        });
                                                    }
                                                }
                                            />

                                            <TextField
                                                error={isEmptyMessageSenderName}
                                                helperText={
                                                    isEmptyMessageSenderName &&
                                                    'This field must be filled'
                                                }
                                                label="Sender Name"
                                                inputProps={{
                                                    maxLength: '64',
                                                }}
                                                margin="dense"
                                                value={this.state.settings.message_sender_name || ''}
                                                onChange={
                                                    (event): void => {
                                                        this.setState({
                                                            settings: {
                                                                ...this.state.settings,
                                                                message_sender_name: event.target.value.replace(/\s\s+/, ' ').trimLeft(),
                                                            },
                                                        });
                                                    }
                                                }
                                            />
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>

                    <Divider/>

                    <ExpansionPanelActions>
                        <Button
                            disabled={
                                isEmptySubjectPrefix ||
                                isEmptyMessageSenderName
                            }
                            disableFocusRipple
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={this.updateSettings}
                        >
                            Save
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </Grid>
        );
    }
}
