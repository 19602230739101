import {Button, Grid} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import Select, {components as selectComponents} from 'react-select';
import {OptionProps} from 'react-select/lib/components/Option';
import {Option} from 'react-select/lib/filters';
import '../assets/scss/filter-select.scss';
import Employee, {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group, {
    GroupComponentWrapper,
    GroupComponentWrapperDispatchToProps,
    GroupComponentWrapperStateToProps,
} from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationReason from '../models/VacationReason';
import VacationRequest from '../models/VacationRequest';
import VacationStatus from '../models/VacationStatus';
import EmployeeItem from './EmployeeItem';


export interface FilterValues {
    ids?: number[];
    start?: moment.Moment;
    end?: moment.Moment;
    groupIds?: number[];
    employeeIds?: number[];
    vacationStatusIds?: number[];
    vacationReasonIds?: number[];
    displayMonth?: DisplayMonthFilterOptionValue;
}

export type DisplayMonthFilterOptionValue = 'fromNow' | 'all' | 'onlyNonEmptyMonths';

export interface DisplayMonthFilterOption extends Option {
    value: DisplayMonthFilterOptionValue;
}

export const displayMonthFilterOptions: DisplayMonthFilterOption[] = [
    {
        value: 'all',
        label: 'All',
        data: undefined,
    },
    {
        value: 'fromNow',
        label: 'From current month',
        data: undefined,
    },
    {
        value: 'onlyNonEmptyMonths',
        label: 'Only Non-Empty Months',
        data: undefined,
    },
];

interface SelectOption extends Option {
    id: number;
}

export interface FilterStateToProps extends EmployeeComponentWrapperStateToProps, GroupComponentWrapperStateToProps {
    vacationStatuses: VacationStatus[];
    vacationReasons: VacationReason[];
}

export interface FilterDispatchToProps extends EmployeeComponentWrapperDispatchToProps, GroupComponentWrapperDispatchToProps {
    receiveVacationStatuses: () => void;
    receiveVacationReasons: () => void;
}

const filterSelectStyles = {
    menu: (base): unknown => ({
        ...base,
        zIndex: 999,
    }),
};

export type FilterProps = FilterStateToProps & FilterDispatchToProps & {
    defaultValue?: FilterValues;
    groupsFilter?: boolean;
    employeesFilter?: boolean;
    vacationStatusesFilter?: boolean;
    vacationReasonsFilter?: boolean;
    displayMonthFilter?: boolean;
    onChange: (filter: FilterValues) => void;
};

export interface FilterState {
    groupIds: number[];
    employeeIds: number[];
    vacationStatusIds: number[];
    vacationReasonIds: number[];
    displayMonth: DisplayMonthFilterOptionValue;
    year: string;
}

export class EmployeeSelectOptionComponent extends React.Component<OptionProps<Option>> {
    public render(): React.ReactElement {
        const employee = this.props.data.data as Employee;

        return (
            <React.Fragment>
                {
                    employee &&
                    <selectComponents.Option {...this.props}>
                        <EmployeeItem employee={employee}/>
                    </selectComponents.Option>
                }
            </React.Fragment>
        );
    }
}

export default class Filter extends React.Component<FilterProps, FilterState> implements EmployeeComponentWrapper, GroupComponentWrapper {
    public constructor(props: FilterProps) {
        super(props);

        const defaultValue: FilterValues = this.props.defaultValue || {};

        this.state = {
            groupIds: defaultValue.groupIds || [],
            employeeIds: defaultValue.employeeIds || [],
            vacationStatusIds: defaultValue.vacationStatusIds || [],
            vacationReasonIds: defaultValue.vacationReasonIds || [],
            displayMonth: defaultValue.displayMonth || 'fromNow',
            year: moment().format('YYYY'),
        };
    }

    public componentDidMount(): void {
        this.props.receiveVacationStatuses();
        this.props.receiveVacationReasons();

        this.props.receiveGroups();
    }

    public changeEmployeesFilter = (selectedOptions): void => {
        if (selectedOptions.length > 0) {
            const employeeIds = selectedOptions.map(
                (selectedOption: SelectOption): number => selectedOption.data.id,
            );

            this.setState({
                employeeIds,
            });
        } else {
            this.setState(
                {
                    employeeIds: [],
                }, this.onSubmit,
            );
        }
    };

    public changeGroupsFilter = (selectedOptions): void => {
        if (selectedOptions.length > 0) {
            const groupIds = selectedOptions.map(
                (selectedOption: SelectOption): number => selectedOption.data.id,
            );

            this.setState({
                groupIds,
            });
        } else {
            this.setState(
                {
                    groupIds: [],
                }, this.onSubmit,
            );
        }
    };

    public changeVacationStatusesFilter = (selectedOptions): void => {
        if (selectedOptions.length > 0) {
            const vacationStatusIds = selectedOptions.map(
                (selectedOption: SelectOption): number => selectedOption.data.id,
            );

            this.setState({
                vacationStatusIds,
            });
        } else {
            this.setState(
                {
                    vacationStatusIds: [],
                }, this.onSubmit,
            );
        }
    };

    public changeVacationReasonsFilter = (selectedOptions): void => {
        if (selectedOptions.length > 0) {
            const vacationReasonIds = selectedOptions.map(
                (selectedOption: SelectOption): number => selectedOption.data.id,
            );

            this.setState({
                vacationReasonIds,
            });
        } else {
            this.setState(
                {
                    vacationReasonIds: [],
                }, this.onSubmit,
            );
        }
    };

    public changeDisplayMonthFilter = (selectedOption): void => {
        if (selectedOption) {
            const displayMonth = selectedOption.value;

            this.setState({
                displayMonth,
            });
        } else {
            this.setState(
                {
                    displayMonth: 'fromNow' as DisplayMonthFilterOptionValue,
                }, this.onSubmit,
            );
        }
    };

    public onSubmit = (): void => {
        this.props.onChange({
            employeeIds: this.state.employeeIds,
            groupIds: this.state.groupIds,
            vacationStatusIds: this.state.vacationStatusIds,
            vacationReasonIds: this.state.vacationReasonIds,
            displayMonth: this.state.displayMonth,
        });
    };

    public handleKeyDown = (event): void => {
        if (event.key === 'Enter') {
            this.onSubmit();
        }
    };

    public render(): React.ReactElement {
        const isOnCurrentYear = this.state.year === moment().format('YYYY');

        return (
            <Grid container className="filter-container" direction="row" wrap="wrap">
                {
                    this.props.groupsFilter &&
                    <Grid item xs className="filter-item">
                        <Select
                            isMulti
                            name="group"
                            placeholder="Select groups..."
                            classNamePrefix="Group"
                            styles={filterSelectStyles}
                            noOptionsMessage={(): string => 'No matches found'}
                            onChange={this.changeGroupsFilter}
                            onKeyDown={this.handleKeyDown}
                            value={this.getGroupOptions(this.state.groupIds)}
                            options={
                                this.getGroupOptions(
                                    this.props.groups
                                        .sort(
                                            (a, b): number =>
                                                a.display_name.localeCompare(b.display_name),
                                        )
                                        .map((group): number => group.id),
                                )
                            }
                        />
                    </Grid>
                }

                {
                    this.props.employeesFilter &&
                    <Grid item xs className="filter-item">
                        <Select
                            isMulti
                            components={{
                                Option: EmployeeSelectOptionComponent,
                            }}
                            name="employee"
                            placeholder="Select users..."
                            className="basic-single second-select"
                            classNamePrefix="Employee"
                            styles={filterSelectStyles}
                            noOptionsMessage={(): string => 'No matches found'}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.changeEmployeesFilter}
                            value={
                                this.getEmployeeOptions(
                                    this.state.employeeIds ||
                                    [],
                                )
                            }
                            options={
                                this.getEmployeeOptions(
                                    this.props.employees
                                        .sort(
                                            (a, b): number =>
                                                `${a.last_name} ${a.first_name}`
                                                    .localeCompare(`${b.last_name} ${b.first_name}`),
                                        )
                                        .map((employee): number => employee.id),
                                )
                            }
                        />
                    </Grid>
                }

                {
                    this.props.vacationStatusesFilter &&
                    <Grid item xs className="filter-item">
                        <Select
                            isMulti
                            name="status"
                            placeholder="Select statuses..."
                            classNamePrefix="Status"
                            styles={filterSelectStyles}
                            noOptionsMessage={(): string => 'No matches found'}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.changeVacationStatusesFilter}
                            value={this.getVacationStatusOptions(this.state.vacationStatusIds || [])}
                            options={
                                this.getVacationStatusOptions(
                                    this.props.vacationStatuses
                                        .sort(
                                            (a, b): number =>
                                                a.display_name.localeCompare(b.display_name),
                                        )
                                        .filter((vacationStatus): boolean => vacationStatus.short_name != 'canceled')
                                        .map((vacationStatus): number => vacationStatus.id),
                                )
                            }
                        />
                    </Grid>
                }

                {
                    this.props.vacationReasonsFilter &&
                    <Grid item xs className="filter-item">
                        <Select
                            isMulti
                            name="reason"
                            placeholder="Select reasons..."
                            classNamePrefix="Reason"
                            styles={filterSelectStyles}
                            noOptionsMessage={(): string => 'No matches found'}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.changeVacationReasonsFilter}
                            value={this.getVacationReasonOptions(this.state.vacationReasonIds || [])}
                            options={
                                this.getVacationReasonOptions(
                                    this.props.vacationReasons
                                        .sort(
                                            (a, b): number =>
                                                a.display_name.localeCompare(b.display_name),
                                        )
                                        .map((vacationReason): number => vacationReason.id),
                                )
                            }
                        />
                    </Grid>
                }

                {
                    this.props.displayMonthFilter &&
                    <Grid item xs className="filter-item">
                        <Select
                            classNamePrefix="AnnualMonth"
                            styles={filterSelectStyles}
                            isDisabled={!isOnCurrentYear}
                            placeholder="Select display option..."
                            value={
                                displayMonthFilterOptions.find(
                                    (displayMonthFilterOption): boolean =>
                                        displayMonthFilterOption.value === this.state.displayMonth,
                                )
                            }
                            onChange={this.changeDisplayMonthFilter}
                            onKeyDown={this.handleKeyDown}
                            name="annualMonth"
                            options={displayMonthFilterOptions}
                        />
                    </Grid>
                }

                {
                    (
                        this.props.groupsFilter ||
                        this.props.employeesFilter ||
                        this.props.vacationStatusesFilter ||
                        this.props.vacationReasonsFilter ||
                        this.props.displayMonthFilter
                    ) &&
                    <Grid item className="apply-calendar-filter-button-grid">
                        <Button
                            disableFocusRipple
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={this.onSubmit}
                        >
                            Apply
                        </Button>
                    </Grid>
                }
            </Grid>
        );
    }

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );

    public getGroupMembers = (groupId: number): Employee[] =>
        this.props.groupMembers[groupId] || [];

    private getGroupOptions = (groupIds: number[]): Option[] =>
        groupIds
            .map((groupId): Option => {
                const group = this.props.groups.find((group): boolean => group.id === groupId);

                if (group) {
                    return {
                        label: group.display_name,
                        value: group.short_name,
                        data: group,
                    };
                } else {
                    return {
                        label: '',
                        value: '',
                        data: null,
                    };
                }
            })
            .filter((option: Option): boolean => !!option.data);

    private getEmployeeOptions = (employeeIds: number[]): Option[] =>
        employeeIds
            .filter((employeeId): boolean => {
                const employee = this.props.employees.find((employee): boolean => employee.id === employeeId);

                return (employee && employee.status.short_name == 'active');
            })
            .map((employeeId): Option => {
                const employee = this.props.employees.find((employee): boolean => employee.id === employeeId);

                if (employee) {
                    return {
                        label: `${employee.last_name} ${employee.first_name}`,
                        value: employee.username,
                        data: employee,
                    };
                } else {
                    return {
                        label: '',
                        value: '',
                        data: null,
                    };
                }
            })
            .filter((option: Option): boolean => !!option.data);

    private getVacationStatusOptions = (vacationStatusIds: number[]): Option[] =>
        vacationStatusIds
            .map((vacationStatusId): Option => {
                const vacationStatus = this.props.vacationStatuses.find((vacationStatus): boolean => vacationStatus.id === vacationStatusId);

                if (vacationStatus) {
                    return {
                        label: vacationStatus.display_name,
                        value: vacationStatus.short_name,
                        data: vacationStatus,
                    };
                } else {
                    return {
                        label: '',
                        value: '',
                        data: null,
                    };
                }
            })
            .filter((option: Option): boolean => !!option.data);

    private getVacationReasonOptions = (vacationReasonIds: number[]): Option[] =>
        vacationReasonIds
            .map((vacationReasonId): Option => {
                const vacationReason = this.props.vacationReasons.find((vacationReason): boolean => vacationReason.id === vacationReasonId);

                if (vacationReason) {
                    return {
                        label: vacationReason.display_name,
                        value: vacationReason.short_name,
                        data: vacationReason,
                    };
                } else {
                    return {
                        label: '',
                        value: '',
                        data: null,
                    };
                }
            })
            .filter((option: Option): boolean => !!option.data);
}
