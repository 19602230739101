import {Link} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';
import '../assets/scss/notification.scss';
import Employee from '../models/Employee';
import NotificationModel from '../models/Notification';


export interface NotificationStateToProps {
    employees: Employee[];
}

export interface NotificationDispatchToProps {
    hideNotification: (notification) => void;
}

export type NotificationProps = NotificationStateToProps & NotificationDispatchToProps & {
    notification: NotificationModel;
};

export default class Notification extends React.Component<NotificationProps> {
    public render(): React.ReactElement {
        const regexp = new RegExp(/<(employee):([0-9]+)>/g);

        let employeeLinks = [];
        let match;
        while (!!(match = regexp.exec(this.props.notification.description))) {
            const employeeId = match[2];
            const employee = this.props.employees.find((employee): boolean => employee.id.toString() === employeeId);

            employeeLinks.push(
                <Link href={`/annual?employees=${employeeId}&vacation_statuses=1,3&vacation_reasons=1`}>
                    {employee && `${employee.first_name} ${employee.last_name}`}
                </Link>,
            );
        }

        const notificationParts = this.props.notification.description.split(/<employee:[0-9]+>/);

        return (
            <Card className="notification-card">
                <CardContent style={{position: 'relative', padding: '25px 16px 16px 16px'}}>
                    <span>
                        {this.props.notification.title}
                    </span>

                    <Typography variant="body2" style={{whiteSpace: 'pre-line', wordBreak: 'break-word'}}>
                        {
                            notificationParts.map(
                                (notificationPart, index): React.ReactElement => (
                                    <React.Fragment key={`notificationPart-${index}`}>
                                        {notificationPart}
                                        {employeeLinks[index] && employeeLinks[index]}
                                    </React.Fragment>
                                ),
                            )
                        }
                    </Typography>

                    <Typography variant="body2" style={{position: 'absolute', top: '5px', right: '10px'}}>
                        {moment(this.props.notification.created_at).format('YYYY/MM/DD')}
                    </Typography>
                </CardContent>

                <CardActions>
                    <Button
                        disableFocusRipple
                        fullWidth
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(): void => this.props.hideNotification(this.props.notification)}
                    >
                        Hide
                    </Button>
                </CardActions>
            </Card>
        );
    }
}
