import {connect} from 'react-redux';
import Sidenav, {SidenavDispatchToProps, SidenavStateToProps} from '../components/Sidenav';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';


const mapStateToProps = (state): SidenavStateToProps => ({
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: SidenavDispatchToProps = {
    ...employeeComponentWrapperMapDispatchToProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidenav);
