import {SystemNotificationAction} from '../actions/SystemNotification';
import SystemNotification from '../models/SystemNotification';


export interface SystemNotificationReducerState {
    systemNotification?: SystemNotification;
}

const initialState: SystemNotificationReducerState = {};

const SystemNotificationReducer = (state: SystemNotificationReducerState = initialState, action: SystemNotificationAction): SystemNotificationReducerState => {
    switch (action.type) {
        case 'ADD_SYSTEM_NOTIFICATION':
            state.systemNotification = action.systemNotification;
            break;
        case 'CLEAR_SYSTEM_NOTIFICATION':
            state.systemNotification = undefined;
            break;
    }

    return {...state};
};

export default SystemNotificationReducer;
