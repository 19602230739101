import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import LogsPage, {LogsDispatchToProps, LogsStateToProps} from '../components/LogsPage';
import LogService from '../services/Log';


const mapStateToProps = (state): LogsStateToProps => ({
    logs: state.Log.logs,
    logsCount: state.Log.logsCount,
});

const mapDispatchToProps: LogsDispatchToProps = {
    receiveLogs: LogService.receive,
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(LogsPage));
