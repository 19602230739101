import {API_BASE_URL} from '../services/Consts';
import SystemNotificationService from '../services/SystemNotification';
import store from '../store';


export default class Utils {
    public static handleError(error): void {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    SystemNotificationService.pushError('Invalid credentials')(store.dispatch);
                    break;
                case 403:
                    SystemNotificationService.pushError('You do not have sufficient rights to perform this operation')(store.dispatch);
                    break;
                case 400:
                    SystemNotificationService.pushError('The entered data is incorrect')(store.dispatch);
                    break;
                default:
                    if (error.response.status >= 500) {
                        SystemNotificationService.pushError('Something went wrong. Please contact with administrator')(store.dispatch);
                    }
            }
        } else {
            SystemNotificationService.pushError('Lost connection to server. Please try again later')(store.dispatch);
        }
    }

    public static generateApiUrl(relUrl: string, params?: { [key: string]: unknown }): string {
        return `${API_BASE_URL}/${relUrl}/${params ? `?${Object.keys(params)
            .filter((key: string): boolean => !!params[key])
            .map((key: string): string => `${key}=${params[key]}`).join('&')}` : ''}`;
    }
}
