import {Grid, List} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';
import {isMobile} from 'react-device-detect';
import '../assets/scss/notification-sidebar.scss';
import NotificationComponent from '../containers/Notification';
import VacationRequestComponent from '../containers/VacationRequest';
import Employee, {
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationReason from '../models/VacationReason';
import VacationRequest from '../models/VacationRequest';


export interface NotificationsSidebarStateToProps extends EmployeeComponentWrapperStateToProps {
    vacationReasons: VacationReason[];
}

export type NotificationsSidebarProps =
    NotificationsSidebarStateToProps &
    EmployeeComponentWrapperDispatchToProps &
    {
        open: boolean;
        onClose?: () => void;
    };

export interface NotificationSidebarState {
    selectedEmployeeId: number;
    alert: {
        open: boolean;
        title: string;
        message: string;
    };
    vacationRequests: {
        order: 'asc' | 'desc';
        collapsed: boolean;
    };
    notifications: {
        order: 'asc' | 'desc';
        collapsed: boolean;
    };
}

export default class NotificationsSidebar extends React.Component<NotificationsSidebarProps, NotificationSidebarState> {
    public constructor(props: NotificationsSidebarProps) {
        super(props);

        this.state = {
            selectedEmployeeId: null,
            alert: {
                open: false,
                title: '',
                message: '',
            },
            vacationRequests: {
                order: 'asc',
                collapsed: true,
            },
            notifications: {
                order: 'asc',
                collapsed: true,
            },
        };
    }

    public showAlert = (title: string, message: string): void => {
        this.setState({
            alert: {
                open: true,
                title: title,
                message: message,
            },
        });
    };

    public hideAlert = (): void => {
        this.setState({
            alert: {
                ...this.state.alert,
                open: false,
            },
        });
    };

    public getSelectedEmployee = (): Employee => {
        const {selectedEmployeeId} = this.state;

        return this.props.employees.find((employee: Employee): boolean => employee.id === selectedEmployeeId);
    };

    public render(): React.ReactElement {
        if (!this.props.me) {
            return <React.Fragment/>;
        }

        return (
            <React.Fragment>
                <Dialog open={this.state.alert.open} onClose={(): void => this.hideAlert()}>
                    <DialogTitle>{this.state.alert.title}</DialogTitle>

                    <DialogContent>
                        <DialogContentText>{this.state.alert.message}</DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button disableFocusRipple onClick={(): void => this.hideAlert()}>OK</Button>
                    </DialogActions>
                </Dialog>

                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={this.props.open}
                    PaperProps={{
                        style: {
                            boxShadow: 'none',
                            backgroundColor: '#3f51b5',
                            color: 'white',
                            top: '52px',
                            width: window.innerWidth < 567 ? '100%' : (isMobile ? '50%' : 'auto'),
                            maxWidth: '576px',
                            height: 'calc(100% - 52px)',
                        },
                    }}
                    BackdropProps={{
                        style: {
                            opacity: 0,
                        },
                    }}
                    onClose={this.props.onClose}
                >
                    <List component="div" className="sidebar-list" style={{width: '100%'}}>
                        {
                            this.getEmployeeVacationRequests().length > 0 &&
                            <React.Fragment>
                                <ListItem
                                    key="divider-1"
                                    style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
                                >
                                    <Grid container direction="row" alignItems="center" justify="space-between">
                                        <Grid item>
                                            <Typography style={{color: 'white'}}>
                                                Requests: {this.getEmployeeVacationRequests().length}
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <IconButton
                                                onClick={(): void => {
                                                    this.setState({
                                                        vacationRequests: {
                                                            ...this.state.vacationRequests,
                                                            order: this.state.vacationRequests.order === 'desc' ? 'asc' : 'desc',
                                                        },
                                                    });
                                                }}
                                                style={this.state.vacationRequests.collapsed ? {display: 'none'} : {display: 'inline-flex'}}
                                            >
                                                <Icon style={{color: 'white'}}>
                                                    {this.state.vacationRequests.order === 'desc' ? 'arrow_downward' : 'arrow_upward'}
                                                </Icon>
                                            </IconButton>

                                            <IconButton
                                                onClick={(): void => {
                                                    this.setState({
                                                        vacationRequests: {
                                                            ...this.state.vacationRequests,
                                                            collapsed: !this.state.vacationRequests.collapsed,
                                                        },
                                                    });
                                                }}
                                            >
                                                <Icon style={{color: 'white'}}>
                                                    {this.state.vacationRequests.collapsed ? 'arrow_drop_down' : 'arrow_drop_up'}
                                                </Icon>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </ListItem>

                                <Collapse in={!this.state.vacationRequests.collapsed}>
                                    {
                                        this.getEmployeeVacationRequests().filter(
                                            (vacationRequest): boolean =>
                                                moment().format('YYYY-MM-DD') <= moment(vacationRequest.vacation.updated_at).format('YYYY-MM-DD'),
                                        ).length > 0 &&
                                        <Typography className="notifications-title" style={{color: 'white'}}>
                                            New requests:
                                        </Typography>
                                    }
                                    {
                                        this.getEmployeeVacationRequests()
                                            .sort((a, b): number =>
                                                this.state.vacationRequests.order === 'desc' ?
                                                    (moment(a.vacation.start).unix() - moment(b.vacation.start).unix()) :
                                                    (moment(b.vacation.start).unix() - moment(a.vacation.start).unix()),
                                            )
                                            .filter(
                                                (vacationRequest): boolean =>
                                                    moment().format('YYYY-MM-DD') <= moment(vacationRequest.vacation.updated_at).format('YYYY-MM-DD'),
                                            )
                                            .map((vacationRequest): React.ReactElement => (
                                                <ListItem
                                                    key={`vacation-request-${vacationRequest.id}`}
                                                    component="div"
                                                >
                                                    <VacationRequestComponent
                                                        vacationRequest={vacationRequest}
                                                        {...this.props}
                                                    />
                                                </ListItem>
                                            ))
                                    }
                                    {
                                        this.getEmployeeVacationRequests().filter(
                                            (vacationRequest): boolean =>
                                                moment().format('YYYY-MM-DD') > moment(vacationRequest.vacation.updated_at).format('YYYY-MM-DD'),
                                        ).length > 0 &&
                                        <Typography className="notifications-title" style={{color: 'white'}}>
                                            Old requests:
                                        </Typography>
                                    }
                                    {
                                        this.getEmployeeVacationRequests()
                                            .sort((a, b): number =>
                                                this.state.vacationRequests.order === 'desc' ?
                                                    (moment(a.vacation.start).unix() - moment(b.vacation.start).unix()) :
                                                    (moment(b.vacation.start).unix() - moment(a.vacation.start).unix()),
                                            )
                                            .filter((vacationRequest): boolean =>
                                                moment().format('YYYY-MM-DD') > moment(vacationRequest.vacation.updated_at).format('YYYY-MM-DD'),
                                            )
                                            .map((vacationRequest): React.ReactElement => (
                                                <ListItem
                                                    key={`vacation-request-${vacationRequest.id}`}
                                                    component="div"
                                                >
                                                    <VacationRequestComponent
                                                        vacationRequest={vacationRequest}
                                                        {...this.props}
                                                    />
                                                </ListItem>
                                            ))
                                    }
                                </Collapse>
                            </React.Fragment>
                        }

                        {
                            this.getEmployeeNotifications().length > 0 &&
                            <React.Fragment>
                                <ListItem
                                    key="divider-3"
                                    style={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
                                >
                                    <Grid container direction="row" alignItems="center" justify="space-between">
                                        <Grid item>
                                            <Typography style={{color: 'white'}}>
                                                Notifications: {this.getEmployeeNotifications().length}
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <IconButton
                                                onClick={(): void => {
                                                    this.setState({
                                                        notifications: {
                                                            ...this.state.notifications,
                                                            order: this.state.notifications.order === 'desc' ? 'asc' : 'desc',
                                                        },
                                                    });
                                                }}
                                                style={this.state.notifications.collapsed ? {display: 'none'} : {display: 'inline-flex'}}
                                            >
                                                <Icon style={{color: 'white'}}>
                                                    {this.state.notifications.order === 'desc' ? 'arrow_downward' : 'arrow_upward'}
                                                </Icon>
                                            </IconButton>

                                            <IconButton
                                                onClick={(): void => {
                                                    this.setState({
                                                        notifications: {
                                                            ...this.state.notifications,
                                                            collapsed: !this.state.notifications.collapsed,
                                                        },
                                                    });
                                                }}
                                            >
                                                <Icon style={{color: 'white'}}>
                                                    {this.state.notifications.collapsed ? 'arrow_drop_down' : 'arrow_drop_up'}
                                                </Icon>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </ListItem>

                                <Collapse in={!this.state.notifications.collapsed}>
                                    {
                                        this.getEmployeeNotifications()
                                            .sort((a, b): number =>
                                                this.state.notifications.order === 'desc' ?
                                                    (moment(a.created_at).unix() - moment(b.created_at).unix()) :
                                                    (moment(b.created_at).unix() - moment(a.created_at).unix()),
                                            )
                                            .map((notification): React.ReactElement => (
                                                <ListItem key={`notification-${notification.id}`} component={'div'}>
                                                    <NotificationComponent notification={notification}/>
                                                </ListItem>
                                            ))
                                    }
                                </Collapse>
                            </React.Fragment>
                        }

                        {
                            this.getEmployeeVacationRequests() &&
                            this.getEmployeeNotifications() &&
                            this.getEmployeeVacationRequests().length === 0 &&
                            this.getEmployeeNotifications().length === 0 &&
                            (
                                <Typography variant="h5" color="inherit" style={{padding: '16px'}}>
                                    No notifications
                                </Typography>
                            )
                        }
                    </List>
                </Drawer>
            </React.Fragment>
        );
    };

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
