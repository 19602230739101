import {Dispatch} from 'redux';
import {hideAlert, showAlert} from '../actions/Alert';
import Alert from '../models/Alert';


class AlertService {
    public static show = (alert: Alert): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(showAlert(alert));
        };

    public static hideAlert = (): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(hideAlert());
        };
}

export default AlertService;
