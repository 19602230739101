import {VacationAction} from '../actions/Vacation';
import {FilterValues} from '../components/Filter';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';


export interface VacationReducerState {
    vacations: Vacation[];
    requests: { [vacationId: number]: VacationRequest[] };
    prevFilter?: FilterValues;
    receivingCounter: number;
}

const initialState: VacationReducerState = {
    vacations: [],
    requests: [],
    receivingCounter: 0,
};

const VacationReducer = (state: VacationReducerState = initialState, action: VacationAction): VacationReducerState => {
    switch (action.type) {
        case 'RECEIVE_VACATIONS_STARTED':
            state.prevFilter = action.filter;
            state.receivingCounter++;
            break;
        case 'RECEIVE_VACATIONS_FINISHED':
            state.vacations = action.vacations;
            state.receivingCounter--;
            break;
        case 'RECEIVE_VACATION_REQUESTS_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_VACATION_REQUESTS_FINISHED':
            state.requests[action.vacationId] = action.requests;
            state.requests = Object.assign({}, state.requests);
            state.receivingCounter--;
            break;
        case 'RECEIVE_FINISHED_WITH_ERROR':
            state.receivingCounter--;
            break;
    }

    return {...state};
};

export default VacationReducer;
