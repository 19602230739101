import {LogLevelAction} from '../actions/LogLevel';
import LogLevel from '../models/LogLevel';


export type LogLevelReducerReceivingTarget =
    'LOG_LEVELS';

export interface LogLevelReducerState {
    logLevels: LogLevel[];
    receivingCounter: number;
}

const initialState: LogLevelReducerState = {
    logLevels: [],
    receivingCounter: 0,
};

const LogLevelReducer = (state: LogLevelReducerState = initialState, action: LogLevelAction): LogLevelReducerState => {
    switch (action.type) {
        case 'RECEIVE_LOG_LEVELS_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_LOG_LEVELS_FINISHED':
            state.logLevels = action.logLevels;
            state.receivingCounter--;
            break;
    }

    return {...state};
};

export default LogLevelReducer;
