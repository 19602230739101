import axios, {AxiosResponse} from 'axios';
import {URLs} from '../consts';
import history from '../history';
import Token from '../models/Token';
import Utils from '../utils/Utils';


class AuthenticationService {
    public static login = (username: string, password: string): ((dispatch) => void) =>
        (): void => {
            axios.post(Utils.generateApiUrl('auth'), {
                username: username,
                password: password,
            })
                .then((response: AxiosResponse): void => {
                    const token = response.data as Token;
                    localStorage.setItem('token', token.token);
                    localStorage.setItem('employee_id', token.employee_id.toString());
                    history.push(URLs.home);
                })
                .catch((error): void => {
                    Utils.handleError(error);
                });
        };

    public static logout = (): ((dispatch) => void) =>
        (): void => {
            localStorage.removeItem('token');
            history.push('/login');
        };

    public static getToken = (): string | null => localStorage.getItem('token');
    public static getEmployeeId = (): string | null => localStorage.getItem('employee_id');

    public static isAuthenticated = (): boolean =>
        AuthenticationService.getToken() !== null &&
        AuthenticationService.getEmployeeId() !== null;
}

export default AuthenticationService;
