import {connect} from 'react-redux';
import NotificationsSidebar, {NotificationsSidebarStateToProps} from '../components/NotificationsSidebar';
import {
    EmployeeComponentWrapperDispatchToProps,
    employeeComponentWrapperMapDispatchToProps,
    employeeComponentWrapperMapStateToProps,
} from '../models/Employee';


const mapStateToProps = (state): NotificationsSidebarStateToProps => ({
    vacationReasons: state.Vacation.reasons,
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: EmployeeComponentWrapperDispatchToProps = {
    ...employeeComponentWrapperMapDispatchToProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsSidebar);
