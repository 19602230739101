import {Box, CircularProgress, Grid} from '@material-ui/core';
import lodash from 'lodash';
import moment from 'moment';
import {WithSnackbarProps} from 'notistack';
import QueryString from 'query-string';
import React from 'react';
import {Calendar as BigCalendar, Event, stringOrDate} from 'react-big-calendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import {RouteComponentProps} from 'react-router-dom';
import '../assets/scss/home.scss';
import '../assets/scss/react-big-calendar.scss';
import '../assets/scss/utils.scss';
import Filter from '../containers/Filter';
import VacationDialog from '../containers/VacationDialog';
import {localizer} from '../index';
import Alert from '../models/Alert';
import Employee, {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group, {
    GroupComponentWrapper,
    GroupComponentWrapperDispatchToProps,
    GroupComponentWrapperStateToProps,
} from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation, {
    VacationComponentWrapper,
    VacationComponentWrapperDispatchToProps,
    VacationComponentWrapperStateToProps,
} from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';
import EmployeeItem from './EmployeeItem';
import {FilterValues} from './Filter';
import VacationsListDialog from './VacationsListDialog';


export interface ExtendedCalendarEvent extends Event {
    vacation: Vacation;
    tooltip: string;
}

export interface MonthlyCalendarStateToProps extends VacationComponentWrapperStateToProps, GroupComponentWrapperStateToProps, EmployeeComponentWrapperStateToProps {
}

export interface MonthlyCalendarDispatchToProps extends VacationComponentWrapperDispatchToProps, GroupComponentWrapperDispatchToProps, EmployeeComponentWrapperDispatchToProps {
    pushSystemNotification: (systemNotification: string) => void;
    showAlert: (alert: Alert) => void;
}

export type MonthlyCalendarProps = MonthlyCalendarStateToProps & MonthlyCalendarDispatchToProps;

export interface MonthlyCalendarState {
    vacationDialog: {
        open: boolean;
        vacation?: Vacation;
        start?: moment.Moment;
        end?: moment.Moment;
    };
    dayVacationsDialog: {
        open: boolean;
        date?: moment.Moment;
    };
    sidenav: {
        open: boolean;
    };
    filter: FilterValues;
    monthlyCalendarDate: Date;
}

export default class MonthlyCalendarPage extends React.Component<MonthlyCalendarProps & WithSnackbarProps & RouteComponentProps, MonthlyCalendarState> implements VacationComponentWrapper, GroupComponentWrapper, EmployeeComponentWrapper {
    public constructor(props: MonthlyCalendarProps & WithSnackbarProps & RouteComponentProps) {
        super(props);

        this.state = {
            vacationDialog: {
                open: false,
            },
            dayVacationsDialog: {
                open: false,
            },
            sidenav: {
                open: false,
            },
            filter: {
                start: moment().startOf('month'),
                end: moment().endOf('month'),
                vacationStatusIds: [1, 3],
            },
            monthlyCalendarDate: new Date(),
        };
    }

    public componentDidMount(): void {
        this.props.receiveEmployees();

        const queryParams = QueryString.parse(this.props.location.search);

        const queryVacationId = queryParams.vacation;

        if (typeof queryVacationId === 'string') {
            let vacationId = parseInt(queryVacationId);

            this.props.receiveVacations({ids: [vacationId]});
        } else {
            this.props.receiveVacations(this.state.filter);
        }
    }

    public componentDidUpdate(prevProps: Readonly<MonthlyCalendarProps & WithSnackbarProps>, prevState: Readonly<MonthlyCalendarState>): void {
        if (this.state.filter && this.state.filter != prevState.filter) {
            this.props.receiveVacations(this.state.filter);
        }

        const vacation =
            this.state.vacationDialog.vacation &&
            this.state.vacationDialog.vacation.id &&
            this.props.vacations.find(
                (vacation): boolean => vacation.id === this.state.vacationDialog.vacation.id,
            );

        if (
            vacation &&
            !lodash.isEqual(
                prevProps.vacations.find((v): boolean => v.id === vacation.id),
                this.props.vacations.find((v): boolean => v.id === vacation.id),
            )
        ) {
            this.setState({
                vacationDialog: {
                    ...this.state.vacationDialog,
                    vacation,
                },
            });
        }

        if (!this.state.vacationDialog.open) {
            const queryParams = QueryString.parse(this.props.location.search);
            const queryVacationId = queryParams.vacation;

            if (typeof queryVacationId === 'string') {
                let vacationId = parseInt(queryVacationId);

                if (this.props.vacations != undefined) {
                    let queryVacation = this.props.vacations
                        .find((vacation: Vacation): boolean => {
                            return (vacation.id == vacationId);
                        });

                    if (queryVacation != undefined) {
                        this.setState({
                            monthlyCalendarDate: new Date(queryVacation.start.format('YYYY-MM-DD')),
                            filter: {
                                start: moment(queryVacation.start).startOf('month'),
                                end: moment(queryVacation.end).endOf('month'),
                            },
                        });

                        this.openVacationDialog(undefined, undefined, queryVacation);

                        this.props.location.search = '';
                    }
                }
            }
        }
    }

    public openVacationDialog = (start?: stringOrDate, end?: stringOrDate, vacation?: Vacation): void => {
        if (
            vacation == undefined &&
            start != undefined &&
            end != undefined
        ) {
            let startMoment = moment(start);
            let endMoment = moment(end);
            let nowMoment = moment();

            if (
                startMoment.format('YYYY-MM-DD') >= nowMoment.format('YYYY-MM-DD') ||
                this.isAdmin(this.props.me.id)
            ) {
                let intersectionsCount = this.props.vacations
                    .filter((vacation: Vacation): boolean => {
                        let vacationStart = moment(vacation.start).format('YYYY-MM-DD');
                        let vacationEnd = moment(vacation.end).format('YYYY-MM-DD');
                        let newVacationStart = startMoment.format('YYYY-MM-DD');
                        let newVacationEnd = endMoment.format('YYYY-MM-DD');

                        return (
                            (
                                (
                                    vacationStart <= newVacationStart &&
                                    vacationEnd >= newVacationStart
                                ) || (
                                    vacationStart <= newVacationEnd &&
                                    vacationEnd >= newVacationEnd
                                ) || (
                                    newVacationStart <= vacationStart &&
                                    newVacationEnd >= vacationStart
                                ) || (
                                    newVacationStart <= vacationEnd &&
                                    newVacationEnd >= vacationEnd
                                )
                            ) &&
                            vacation.employee.id === this.props.me.id
                        );
                    })
                    .length;

                if (
                    intersectionsCount === 0 ||
                    this.isAdmin(this.props.me.id)
                ) {
                    this.setState({
                        vacationDialog: {
                            open: true,
                            start: startMoment,
                            end: endMoment,
                        },
                    });
                } else {
                    this.props.showAlert({
                        title: 'Incorrect Vacation Interval',
                        text: 'Other vacations are already included in the interval you selected',
                    });
                }
            } else {
                this.props.showAlert({
                    title: 'Incorrect Vacation Interval',
                    text: 'Start date must be greater than or equal to the current date',
                });
            }
        } else if (vacation != undefined) {
            this.setState({
                vacationDialog: {
                    open: true,
                    vacation: vacation,
                },
            });
        } else {
            this.setState({
                vacationDialog: {
                    open: true,
                },
            });
        }
    };

    public closeVacationDialog = (): void => {
        this.setState({
            vacationDialog: {
                ...this.state.vacationDialog,
                open: false,
            },
        });
    };

    public eventPropGetter(event): unknown {
        const style = {
            borderRadius: '0px',
            border: '1px',
            display: 'block',
        };

        switch (event.vacation.status.short_name) {
            case 'pending':
                style['backgroundColor'] = '#03A9F4';
                break;
            case 'rejected':
                style['backgroundColor'] = '#E91E63';
                break;
            case 'approved':
                style['backgroundColor'] = '#1976D2';
                break;
        }

        return {style};
    }

    public dayPropGetter = (date: Date): unknown => {
        if (
            this.props.employees != undefined &&
            this.props.vacations != undefined
        ) {
            const employeesCount = this.props.employees.length;
            const filteredVacationsCount = this.props.vacations.filter(
                (vacation: Vacation): boolean => (
                    moment(date).format('YYYY-MM-DD') >= moment(vacation.start).format('YYYY-MM-DD') &&
                    moment(date).format('YYYY-MM-DD') <= moment(vacation.end).format('YYYY-MM-DD') &&
                    vacation.employee.status.short_name == 'active'
                ),
            ).length;
            const workload = Math.round(filteredVacationsCount / employeesCount * 100);

            if (0 < workload && workload <= 33) {
                return {className: 'free-employment-day'};
            } else if (0 < workload && workload <= 67) {
                return {className: 'half-employment-day'};
            } else if (0 < workload) {
                return {className: 'full-employment-day'};
            }
        }
    };

    public render(): React.ReactElement {
        const dataWasLoaded =
            this.props.vacations != undefined &&
            this.props.employees != undefined &&
            this.props.me != undefined;

        return (
            <React.Fragment>
                <Grid
                    container
                    direction={window.innerWidth < 576 ? 'row' : 'column'}
                    wrap={window.innerWidth < 576 ? 'wrap' : 'nowrap'}
                    alignContent="flex-start"
                >
                    <Grid item container>
                        <Filter
                            groupsFilter
                            employeesFilter
                            vacationStatusesFilter
                            vacationReasonsFilter
                            defaultValue={this.state.filter}
                            onChange={
                                (filter): void => {
                                    this.setState({
                                        filter: {
                                            start: this.state.filter.start,
                                            end: this.state.filter.end,
                                            ...filter,
                                        },
                                    });
                                }
                            }
                        />
                    </Grid>

                    <Grid item container className="monthly-calendar-wrapper">
                        {
                            dataWasLoaded ?
                                <BigCalendar
                                    events={
                                        this.props.vacations
                                            .filter((vacation: Vacation): boolean => {
                                                return (vacation.employee.status.short_name == 'active');
                                            })
                                            .map((vacation: Vacation) => {
                                                return {
                                                    vacation,
                                                    tooltip: `${vacation.employee ? `${vacation.employee.first_name} ${vacation.employee.last_name}` : 'Draft vacation'}${`, ${moment(vacation.start).format('YYYY-MM-DD')} - ${moment(vacation.end).format('YYYY-MM-DD')}`}${vacation.reason ? `, ${(vacation.reason.display_name).toLowerCase()}` : ', Have no reason'}${vacation.comment ? `, ${vacation.comment}` : ''}${vacation.status ? `, ${vacation.status.short_name.toUpperCase()}` : ''}`,
                                                    title: <EmployeeItem employee={vacation.employee}>
                                                        {`, ${vacation.reason.display_name}, ${vacation.comment}, ${vacation.status.short_name.toUpperCase()}`}
                                                    </EmployeeItem>,
                                                    start: vacation.start.format('YYYY-MM-DD'),
                                                    end: vacation.end.format('YYYY-MM-DD'),
                                                };
                                            })
                                    }
                                    selectable={'ignoreEvents'}
                                    views={['month']}
                                    drilldownView={null}
                                    onSelectSlot={
                                        (slotInfo): void =>
                                            this.openVacationDialog(slotInfo.start, slotInfo.end)
                                    }
                                    onSelectEvent={
                                        (event): void =>
                                            this.openVacationDialog(undefined, undefined, event.vacation)
                                    }
                                    className="vacation-calendar"
                                    localizer={localizer}
                                    eventPropGetter={this.eventPropGetter}
                                    dayPropGetter={this.dayPropGetter}
                                    tooltipAccessor={'tooltip'}
                                    date={this.state.monthlyCalendarDate}
                                    onNavigate={
                                        (date: Date): void => {
                                            this.setState({
                                                monthlyCalendarDate: new Date(date),
                                                filter: {
                                                    ...this.state.filter,
                                                    start: moment(date).startOf('month'),
                                                    end: moment(date).endOf('month'),
                                                },
                                            });
                                        }
                                    }
                                    popup={true}
                                    onShowMore={
                                        (events, date): void => {
                                            this.setState({
                                                dayVacationsDialog: {
                                                    open: true,
                                                    date: moment(date),
                                                },
                                            });
                                        }
                                    }
                                /> :
                                <Box
                                    height="100%"
                                    width="100%"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <CircularProgress size="80px"/>
                                </Box>
                        }
                    </Grid>
                </Grid>

                {
                    dataWasLoaded &&
                    <VacationDialog
                        open={this.state.vacationDialog.open}
                        vacation={this.state.vacationDialog.vacation}
                        start={this.state.vacationDialog.start}
                        end={this.state.vacationDialog.end}
                        onClose={(): void => this.closeVacationDialog()}
                    />
                }

                {
                    dataWasLoaded &&
                    <VacationsListDialog
                        open={this.state.dayVacationsDialog.open}
                        onClose={
                            (): void => {
                                this.setState({
                                    dayVacationsDialog: {
                                        ...this.state.dayVacationsDialog,
                                        open: false,
                                    },
                                });
                            }
                        }
                        date={this.state.dayVacationsDialog.date}
                        vacations={this.props.vacations}
                        onSelectVacation={
                            (vacation): void => {
                                this.setState({
                                    vacationDialog: {
                                        open: true,
                                        vacation,
                                    },
                                });
                            }
                        }
                    />
                }
            </React.Fragment>
        );
    }

    public getGroupMembers = (groupId: number): Employee[] =>
        this.props.groupMembers[groupId] || [];

    public getVacationRequests = (vacationId: number): VacationRequest[] =>
        this.props.vacationRequests[vacationId] || [];

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
