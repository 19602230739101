import {EmployeeAction} from '../actions/Employee';
import {EmployeesFilterValues} from '../components/EmployeesFilter';
import Employee from '../models/Employee';
import Group from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';


export interface EmployeeReducerState {
    me?: Employee;
    employees: Employee[];
    employeesCount?: number;
    roles: { [employeeId: number]: Role[] };
    notifications: Notification[];
    vacationRequests: VacationRequest[];
    vacations: { [employeeId: number]: Vacation[] };
    groups: { [employeeId: number]: Group[] };
    prevLimit?: number;
    prevOffset?: number;
    prevSearch?: string;
    prevOrdering?: string;
    prevFilter?: EmployeesFilterValues;
    receivingCounter: number;
}

const initialState: EmployeeReducerState = {
    employees: [],
    roles: {},
    notifications: [],
    vacationRequests: [],
    vacations: {},
    groups: {},
    receivingCounter: 0,
};

const EmployeeReducer = (state: EmployeeReducerState = initialState, action: EmployeeAction): EmployeeReducerState => {
    switch (action.type) {
        case 'RECEIVE_EMPLOYEES_STARTED':
            state.prevLimit = action.limit;
            state.prevOffset = action.offset;
            state.prevSearch = action.search;
            state.prevOrdering = action.ordering;
            state.prevFilter = action.filter;
            state.receivingCounter++;
            break;
        case 'RECEIVE_EMPLOYEES_FINISHED':
            state.employees = action.employees;
            state.employeesCount = action.count;
            state.receivingCounter--;
            break;
        case 'RECEIVE_EMPLOYEE_ROLES_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_EMPLOYEE_ROLES_FINISHED':
            state.roles[action.employeeId] = action.roles;
            state.roles = Object.assign({}, state.roles);
            state.receivingCounter--;
            break;
        case 'RECEIVE_EMPLOYEE_NOTIFICATIONS_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_EMPLOYEE_NOTIFICATIONS_FINISHED':
            state.notifications = action.notifications;
            state.receivingCounter--;
            break;
        case 'RECEIVE_EMPLOYEE_VACATION_REQUESTS_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_EMPLOYEE_VACATION_REQUESTS_FINISHED':
            state.vacationRequests = action.vacationRequests;
            state.receivingCounter--;
            break;
        case 'RECEIVE_EMPLOYEE_VACATIONS_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_EMPLOYEE_VACATIONS_FINISHED':
            state.vacations[action.employeeId] = action.vacations;
            state.vacations = Object.assign({}, state.vacations);
            state.receivingCounter--;
            break;
        case 'RECEIVE_EMPLOYEE_GROUPS_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_EMPLOYEE_GROUPS_FINISHED':
            state.groups[action.employeeId] = action.groups;
            state.groups = Object.assign({}, state.groups);
            state.receivingCounter--;
            break;
        case 'RECEIVE_ME_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_ME_FINISHED':
            state.me = action.me;
            state.receivingCounter--;
            break;
        case 'RECEIVE_FINISHED_WITH_ERROR':
            state.receivingCounter--;
            break;
    }

    return {...state};
};

export default EmployeeReducer;
