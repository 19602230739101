import moment from 'moment';
import {GroupFilterValues} from '../components/GroupFilter';
import GroupService from '../services/Group';
import Employee from './Employee';
import GroupStatus from './GroupStatus';
import GroupType from './GroupType';


export default interface Group {
    id: number;
    short_name: string;
    display_name: string;
    lead: Employee;
    type: GroupType;
    status: GroupStatus;
    vacation_priority: number;
    members_count: number;
    created_at: moment.Moment;
    updated_at: moment.Moment;
}

export interface GroupComponentWrapperStateToProps {
    groups: Group[];
    groupsCount: number;
    groupMembers: { [groupId: number]: Employee[] };
}

export interface GroupComponentWrapperDispatchToProps {
    receiveGroups: (limit?: number, offset?: number, search?: string, ordering?: string, groupFilter?: GroupFilterValues) => void;
    receiveGroupMembers: (groupId: number) => void;
}

export const groupComponentWrapperMapStateToProps = (state): GroupComponentWrapperStateToProps => ({
    groups: state.Group.groups,
    groupsCount: state.Group.groupsCount,
    groupMembers: state.Group.members,
});

export const groupComponentWrapperMapDispatchToProps: GroupComponentWrapperDispatchToProps = {
    receiveGroups: GroupService.receive,
    receiveGroupMembers: GroupService.receiveMembers,
};

export interface GroupComponentWrapper {
    getGroupMembers: (groupId: number) => Employee[];
}
