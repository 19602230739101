import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Fade,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import RenewIcon from '@material-ui/icons/Autorenew';
import {DatePicker} from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import '../assets/scss/home.scss';
import '../assets/scss/profile-page.scss';
import ManageGroupEmployeesDialog from '../containers/ManageGroupEmployeesDialog';
import Alert from '../models/Alert';
import Employee, {
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import EmployeeStatus from '../models/EmployeeStatus';
import Group from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';
import EmployeeAvatar from './EmployeeAvatar';


interface MatchParams {
    id: string;
}

export interface RouteComponentProps<P> {
    match: Match<P>;
}

export interface Match<P> {
    params: P;
    isExact: boolean;
    path: string;
    url: string;
}

export interface ProfilePageStateToProps extends EmployeeComponentWrapperStateToProps {
    employee: Employee;
    employeeStatuses: EmployeeStatus[];
}

export interface ProfilePageDispatchToProps extends EmployeeComponentWrapperDispatchToProps {
    updateEmployee: (employee: Employee, avatar?: File) => void;
    showAlert: (alert: Alert) => void;
    pushSystemNotification: (systemNotification: string) => void;
    receiveEmployeeStatuses: () => void;
}

export type ProfilePageProps = ProfilePageStateToProps & ProfilePageDispatchToProps & RouteComponentProps<MatchParams>;

export interface ProfilePageState {
    employee?: Employee;
    avatar: {
        hovered: boolean;
        newFile?: File;
    };
    groupEmployeesDialog: {
        open: boolean;
        group?: Group;
    };
}

export default class ProfilePage extends React.Component<ProfilePageProps, ProfilePageState> {
    private readonly fileUploader;

    public constructor(props: ProfilePageProps) {
        super(props);

        this.state = {
            avatar: {
                hovered: false,
            },
            groupEmployeesDialog: {
                open: false,
            },
        };

        this.fileUploader = React.createRef();
    }

    public componentDidMount(): void {
        this.props.receiveEmployees();
        this.props.receiveEmployeeStatuses();

        if (this.props.me) {
            this.props.receiveEmployeeRoles(this.props.me.id);
        }
    }

    public componentDidUpdate(prevProps: Readonly<ProfilePageProps>): void {
        if (
            this.props.employee &&
            (
                this.props.employee != prevProps.employee ||
                !this.state.employee
            )
        ) {
            this.setState({
                employee: {
                    ...this.state.employee,
                    ...this.props.employee,
                },
                avatar: {
                    ...this.state.avatar,
                    newFile: undefined,
                },
            });

            this.props.receiveEmployeeGroups(this.props.employee.id);
            this.props.receiveEmployeeVacations(this.props.employee.id);

            if (this.fileUploader.current && this.fileUploader.current instanceof HTMLInputElement) {
                this.fileUploader.current.value = '';
            }
        }

        if (this.props.me && this.props.me != prevProps.me) {
            this.props.receiveEmployeeRoles(this.props.me.id);
        }
    }

    public componentWillUnmount(): void {
        this.setState({
            employee: undefined,
            avatar: {
                hovered: false,
                newFile: undefined,
            },
        });
    }

    public composeVacations = (employee: Employee, vacations: Vacation[]): { vacations: number; canVacations: number } => {
        let day = {
            vacations: 0,
            canVacations: 0,
        };
        let numberOfYears: string;
        const all = moment().diff(employee.employment_date, 'month');

        if (all >= 12) {
            numberOfYears = 'vacation';
        } else if (all < 6) {
            numberOfYears = 'noVacation';
        }

        switch (numberOfYears) {
            case 'noVacation':
                let count = 0;
                for (let i = 0; i < vacations.length; i++) {
                    if (vacations[i].reason.short_name === 'vacation') {
                        count += moment(vacations[i].end).diff(vacations[i].start, 'days') + 1;
                    }
                }
                day = {
                    vacations: count,
                    canVacations: 0,
                };
                break;

            case 'vacation':
                let countDurationAge = 0;
                let myDate = moment(employee.employment_date).add(moment.duration({'years': moment().diff(employee.employment_date, 'years')}));
                if (myDate > moment()) myDate = moment(employee.employment_date).add(moment.duration({'years': moment().diff(employee.employment_date, 'years') - 1}));

                for (let i = 0; i < vacations.length; i++) {
                    if (moment(vacations[i].end) <= moment() && myDate <= moment(vacations[i].start) && vacations[i].reason.short_name === 'vacation') {
                        countDurationAge += moment(vacations[i].end).diff(vacations[i].start, 'days') + 1;
                    }
                }

                const durationMonthly = moment().diff(myDate, 'month');
                const approveDays = 14 + ((6 - durationMonthly) * 2);

                if (durationMonthly >= 6) {
                    day = {
                        vacations: countDurationAge,
                        canVacations: (approveDays - countDurationAge > 0) ? approveDays : 0,
                    };
                } else day = {
                    vacations: countDurationAge,
                    canVacations: 0,
                };

                break;

            default:
                break;
        }

        return day;
    };

    public compose = (vacations: Vacation[]): number => {
        let count = 0;

        for (let i = 0; i < vacations.length; i++) {
            if (moment().format('YYYY') === moment(vacations[i].start).format('YYYY') && moment().format('YYYY') === moment(vacations[i].end).format('YYYY')) {
                count += moment(vacations[i].end).diff(vacations[i].start, 'days') + 1;
            }
        }

        return count < 1 ? 0 : count;
    };

    public composeIllness = (employee: Employee, vacations: Vacation[]): number => {
        return this.compose(
            vacations.filter(
                (vacation): boolean => vacation.reason.short_name === 'illness',
            ),
        );
    };

    public composeAbsence = (employee: Employee, vacations: Vacation[]): number => {
        return this.compose(
            vacations.filter(
                (vacation): boolean => vacation.reason.short_name === 'absence',
            ),
        );
    };

    public composeRemoteWork = (employee: Employee, vacations: Vacation[]): number => {
        return this.compose(
            vacations.filter(
                (vacation): boolean => vacation.reason.short_name === 'remote-work',
            ),
        );
    };

    public render(): React.ReactElement {
        const {employee} = this.state;

        let profileWasNotUpdated = true;

        const dataWasLoaded =
            employee &&
            this.props.employee &&
            this.props.me &&
            this.getEmployeeVacations(this.props.employee.id) &&
            this.getEmployeeGroups(this.props.employee.id);

        const havePermissions = (
            employee &&
            this.props.me &&
            (
                this.props.me.id === employee.id ||
                this.isAdmin(this.props.me.id)
            )
        );

        if (dataWasLoaded) {
            profileWasNotUpdated =
                this.props.employee.first_name === employee.first_name &&
                this.props.employee.last_name === employee.last_name &&
                this.props.employee.mobile_phone === employee.mobile_phone &&
                this.props.employee.employment_date === employee.employment_date &&
                this.props.employee.email === employee.email &&
                this.props.employee.status.id === employee.status.id &&
                !this.state.avatar.newFile;
        }

        const vacationEmployee =
            dataWasLoaded &&
            this.composeVacations(employee, this.getEmployeeVacations(this.props.employee.id).filter(
                (vacation): boolean => vacation.employee.id === employee.id),
            );

        const illnessEmployee =
            dataWasLoaded &&
            this.composeIllness(employee, this.getEmployeeVacations(this.props.employee.id).filter(
                (vacation): boolean => vacation.employee.id === employee.id),
            );

        const absenceEmployee =
            dataWasLoaded &&
            this.composeAbsence(employee, this.getEmployeeVacations(this.props.employee.id).filter(
                (vacation): boolean => vacation.employee.id === employee.id),
            );

        const remoteWorkEmployee =
            dataWasLoaded &&
            this.composeRemoteWork(employee, this.getEmployeeVacations(this.props.employee.id).filter(
                (vacation): boolean => vacation.employee.id === employee.id),
            );

        const emptyFirstName = !dataWasLoaded || employee.first_name.length === 0;
        const emptyLastName = !dataWasLoaded || employee.last_name.length === 0;

        return (
            <React.Fragment>
                <Grid className="wrapper">
                    <Grid className="sidenav-wrapper">
                        {
                            dataWasLoaded
                                ? (
                                    <Grid className="wrapper-profile">
                                        <Grid container spacing={2}>
                                            <Grid item lg={4} md={6} sm={12} xs>
                                                <Card>
                                                    <CardHeader
                                                        title="Profile"
                                                        subheader={`${employee.first_name} ${employee.last_name}`}
                                                        style={{wordBreak: 'break-all', textAlign: 'left'}}
                                                        avatar={
                                                            <React.Fragment>
                                                                <input
                                                                    ref={this.fileUploader}
                                                                    type="file"
                                                                    accept="image/jpeg,image/x-png"
                                                                    style={{
                                                                        display: 'none',
                                                                    }}

                                                                    onChange={
                                                                        (event): void => {
                                                                            if (event.target.files.length === 1) {
                                                                                if (event.target.files[0].size > 2097152) {
                                                                                    this.props.showAlert({
                                                                                        title: 'Incorrect Avatar File Size',
                                                                                        text: 'Size of avatar file must be not greater that 2 MB',
                                                                                    });
                                                                                } else {
                                                                                    this.setState({
                                                                                        avatar: {
                                                                                            ...this.state.avatar,
                                                                                            newFile: event.target.files[0],
                                                                                        },
                                                                                        employee: {
                                                                                            ...employee,
                                                                                            avatar: URL.createObjectURL(event.target.files[0]),
                                                                                        },
                                                                                    });
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                />

                                                                <EmployeeAvatar
                                                                    employee={employee}
                                                                    onMouseEnter={
                                                                        (): void => {
                                                                            this.setState({
                                                                                avatar: {
                                                                                    ...this.state.avatar,
                                                                                    hovered: true,
                                                                                },
                                                                            });
                                                                        }
                                                                    }
                                                                    onMouseLeave={
                                                                        (): void => {
                                                                            this.setState({
                                                                                avatar: {
                                                                                    ...this.state.avatar,
                                                                                    hovered: false,
                                                                                },
                                                                            });
                                                                        }
                                                                    }
                                                                    style={{
                                                                        boxShadow: this.state.avatar.hovered ? 'inset 0 0 30px #000' : 'inset 0 0 0 #000',
                                                                        cursor: 'pointer',
                                                                        transition: '0.5s',
                                                                    }}
                                                                    onClick={
                                                                        (): void => {
                                                                            this.fileUploader.current.click();
                                                                        }
                                                                    }
                                                                >
                                                                    {this.state.avatar.hovered && <RenewIcon/>}
                                                                </EmployeeAvatar>
                                                            </React.Fragment>
                                                        }
                                                    />

                                                    <CardContent className="profile-content">
                                                        <TextField
                                                            fullWidth
                                                            margin="dense"
                                                            label="Username"
                                                            value={employee.username}
                                                            disabled
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            inputProps={{
                                                                maxLength: 50,
                                                            }}
                                                            margin="dense"
                                                            label="First Name"
                                                            value={employee.first_name}
                                                            error={emptyFirstName}
                                                            helperText={emptyFirstName && 'This field cannot be empty'}
                                                            onChange={(event): void => {
                                                                this.setState({
                                                                    employee: {
                                                                        ...employee,
                                                                        first_name: event.target.value.replace(/[^a-zA-ZА-Яа-я]/, ''),
                                                                    },
                                                                });
                                                            }}
                                                            disabled={!havePermissions}

                                                        />

                                                        <TextField
                                                            fullWidth
                                                            margin="dense"
                                                            label="Last Name"
                                                            inputProps={{
                                                                maxLength: 50,
                                                            }}
                                                            value={employee.last_name}
                                                            error={emptyLastName}
                                                            helperText={emptyLastName && 'This field cannot be empty'}
                                                            onChange={(event): void => {
                                                                this.setState({
                                                                    employee: {
                                                                        ...employee,
                                                                        last_name: event.target.value.replace(/[^a-zA-ZА-Яа-я]/, ''),
                                                                    },
                                                                });
                                                            }}
                                                            disabled={!havePermissions}
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            disabled={!this.isAdmin(this.props.me.id)}
                                                            inputProps={{
                                                                maxLength: 128,
                                                            }}
                                                            onChange={
                                                                (event): void => {
                                                                    this.setState({
                                                                        employee: {
                                                                            ...this.state.employee,
                                                                            email: event.target.value,
                                                                        },
                                                                    });
                                                                }
                                                            }
                                                            margin="dense"
                                                            label="Email"
                                                            value={employee.email}
                                                        />

                                                        <TextField
                                                            fullWidth
                                                            inputProps={{
                                                                maxLength: 20,
                                                            }}
                                                            margin="dense"
                                                            label="Mobile Phone"
                                                            value={employee.mobile_phone}
                                                            onChange={(event): void => {
                                                                const regex = /^\+?[0-9]*$/;

                                                                if (event.target.value.match(regex)) {
                                                                    this.setState({
                                                                        employee: {
                                                                            ...employee,
                                                                            mobile_phone: event.target.value
                                                                                .replace(/[^0-9+]/g, ''),
                                                                        },
                                                                    });
                                                                }
                                                            }}
                                                            disabled={!havePermissions}
                                                        />

                                                        <DatePicker
                                                            fullWidth
                                                            disabled={!this.isAdmin(this.props.me.id)}
                                                            onChange={
                                                                (date: moment.Moment): void => {
                                                                    if (this.isAdmin(this.props.me.id)) {
                                                                        this.setState({
                                                                            employee: {
                                                                                ...this.state.employee,
                                                                                employment_date: date,
                                                                            },
                                                                        });
                                                                    }
                                                                }
                                                            }
                                                            margin="dense"
                                                            label="Employment Date"
                                                            format="YYYY/MM/DD"
                                                            maxDate={moment()}
                                                            value={moment(employee.employment_date).format('YYYY/MM/DD')}
                                                        />

                                                        <FormControl fullWidth margin="dense">
                                                            <InputLabel>Status</InputLabel>

                                                            <Select
                                                                fullWidth
                                                                margin="dense"
                                                                disabled={!this.isAdmin(this.props.me.id)}
                                                                value={this.state.employee.status.id}
                                                                onChange={
                                                                    (event): void => {
                                                                        this.setState({
                                                                            employee: {
                                                                                ...this.state.employee,
                                                                                status: this.props.employeeStatuses.find(
                                                                                    (employeeStatus): boolean =>
                                                                                        employeeStatus.id == event.target.value,
                                                                                ),
                                                                            },
                                                                        });
                                                                    }
                                                                }
                                                            >
                                                                {
                                                                    this.props.employeeStatuses.map(
                                                                        (employeeStatus): React.ReactElement =>
                                                                            <MenuItem
                                                                                key={`employee-status-${employeeStatus.id}`}
                                                                                value={employeeStatus.id}
                                                                            >
                                                                                {employeeStatus.display_name}
                                                                            </MenuItem>,
                                                                    )
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </CardContent>

                                                    <CardActions className="profile-actions">
                                                        <Button
                                                            fullWidth
                                                            color="primary"
                                                            disabled={profileWasNotUpdated}
                                                            onClick={
                                                                (): void => {
                                                                    this.setState({
                                                                        employee: this.props.employee,
                                                                        avatar: {
                                                                            ...this.state.avatar,
                                                                            newFile: undefined,
                                                                        },
                                                                    });
                                                                }
                                                            }
                                                        >
                                                            Reset Changes
                                                        </Button>

                                                        <Button
                                                            fullWidth
                                                            color="primary"
                                                            disabled={
                                                                emptyFirstName ||
                                                                emptyLastName ||
                                                                profileWasNotUpdated ||
                                                                !havePermissions
                                                            }
                                                            onClick={(): void => {
                                                                this.props.updateEmployee(
                                                                    employee,
                                                                    this.state.avatar.newFile,
                                                                );
                                                            }}
                                                        >
                                                            Update Profile
                                                        </Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>

                                            <Grid item lg={4} md={6} sm={12} xs>
                                                <Card>
                                                    <CardHeader title="Groups"/>
                                                    {
                                                        !this.getEmployeeGroups(employee.id).length ?
                                                            <Typography
                                                                className="groups-profile-page" style={{marginLeft: '16px'}}
                                                            >
                                                                Not a member of any group
                                                            </Typography> :

                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Name</TableCell>
                                                                        <TableCell>Position</TableCell>
                                                                    </TableRow>
                                                                </TableHead>

                                                                <TableBody>
                                                                    {
                                                                        this.getEmployeeGroups(employee.id)
                                                                            .sort((a, b): number => a.display_name.localeCompare(b.display_name))
                                                                            .map((group): React.ReactElement => (
                                                                                <TableRow
                                                                                    key={`group-${group.id}`}
                                                                                    className={(this.isAdmin(this.props.me.id) || group.lead.id == this.props.me.id) ? 'pointer clickable-row' : ''}
                                                                                    onClick={(): void => {
                                                                                        if (this.isAdmin(this.props.me.id) || group.lead.id == this.props.me.id) {
                                                                                            this.setState({
                                                                                                groupEmployeesDialog: {
                                                                                                    open: true,
                                                                                                    group,
                                                                                                },
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <TableCell>{group.display_name}</TableCell>
                                                                                    <TableCell>
                                                                                        {group.lead.id === employee.id ? 'Lead' : 'Member'}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                    }
                                                </Card>
                                            </Grid>

                                            <Grid item lg={4} md={6} sm={12} xs>
                                                <Card>
                                                    <CardHeader title="Vacations"/>

                                                    <Table>
                                                        <colgroup>
                                                            <col width="50%"/>
                                                            <col width="50%"/>
                                                        </colgroup>

                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="right">Vacation</TableCell>
                                                                <TableCell>{vacationEmployee.vacations}</TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell align="right">Illness</TableCell>
                                                                <TableCell>{illnessEmployee}</TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    align="right"
                                                                    style={{whiteSpace: 'nowrap'}}
                                                                >
                                                                    Absence
                                                                </TableCell>
                                                                <TableCell>{absenceEmployee}</TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    align="right"
                                                                    style={{whiteSpace: 'nowrap'}}
                                                                >
                                                                    Remote Work
                                                                </TableCell>
                                                                <TableCell>{remoteWorkEmployee}</TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell
                                                                    align="right"
                                                                    style={{whiteSpace: 'nowrap'}}
                                                                >
                                                                    Can take vacation
                                                                </TableCell>
                                                                <TableCell>{vacationEmployee.canVacations}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid className="spinner">
                                        <Fade
                                            in={!dataWasLoaded}
                                            unmountOnExit
                                        >
                                            <CircularProgress
                                                color="inherit"
                                                size={80}
                                            />
                                        </Fade>
                                    </Grid>
                                )
                        }
                    </Grid>
                </Grid>

                <ManageGroupEmployeesDialog
                    open={this.state.groupEmployeesDialog.open}
                    group={this.state.groupEmployeesDialog.group}
                    onClose={
                        (): void => {
                            this.setState({
                                groupEmployeesDialog: {
                                    open: false,
                                },
                            });
                        }
                    }
                />
            </React.Fragment>
        );
    };

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
