import {connect} from 'react-redux';
import {ContentWrapper, ContentWrapperDispatchToProps, ContentWrapperStateToProps} from '../components/ContentWrapper';
import EmployeeService from '../services/Employee';


const mapStateToProps = (state): ContentWrapperStateToProps => ({
    me: state.Employee.me,
});

const mapDispatchToProps: ContentWrapperDispatchToProps = {
    receiveMe: EmployeeService.receiveMe,
    receiveEmployeeRoles: EmployeeService.receiveRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentWrapper);
