import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import EmployeesPage, {EmployeesStateToProps, EmployeesDispatchToProps} from '../components/EmployeesPage';
import Employee, {
    employeeComponentWrapperMapDispatchToProps,
    employeeComponentWrapperMapStateToProps,
} from '../models/Employee';
import EmployeeService from '../services/Employee';
import EmployeeStatusService from "../services/EmployeeStatus";


const mapStateToProps = (state): EmployeesStateToProps => ({
    employeeStatuses: state.EmployeeStatus.employeeStatuses,
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: EmployeesDispatchToProps = {
    updateEmployee: EmployeeService.update,
    receiveEmployeeStatuses: EmployeeStatusService.receive,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(EmployeesPage));
