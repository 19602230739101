import {Action} from 'redux';
import {FilterValues} from '../components/Filter';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';


export type VacationActionTypes =
    'RECEIVE_VACATIONS_STARTED' |
    'RECEIVE_VACATIONS_FINISHED' |

    'RECEIVE_VACATION_REQUESTS_STARTED' |
    'RECEIVE_VACATION_REQUESTS_FINISHED' |

    'RECEIVE_FINISHED_WITH_ERROR';

// RECEIVE_VACATIONS

export interface ReceiveVacationsStartedAction extends Action<VacationActionTypes> {
    filter?: FilterValues;
}

export const receiveVacationsStarted = (filter?: FilterValues): ReceiveVacationsStartedAction => ({
    type: 'RECEIVE_VACATIONS_STARTED',
    filter,
});

export interface ReceiveVacationsFinishedAction extends Action<VacationActionTypes> {
    vacations: Vacation[];
}

export const receiveVacationsFinished = (vacations: Vacation[]): ReceiveVacationsFinishedAction => ({
    type: 'RECEIVE_VACATIONS_FINISHED',
    vacations,
});

// RECEIVE_VACATION_REQUESTS

export interface ReceiveVacationRequestsStartedAction extends Action<VacationActionTypes> {
    vacationId: number;
}

export const receiveVacationRequestsStarted = (vacationId: number): ReceiveVacationRequestsStartedAction => ({
    type: 'RECEIVE_VACATION_REQUESTS_STARTED',
    vacationId,
});

export interface ReceiveVacationRequestsFinishedAction extends Action<VacationActionTypes> {
    vacationId: number;
    requests: VacationRequest[];
}

export const receiveVacationRequestsFinished = (vacationId: number, requests: VacationRequest[]): ReceiveVacationRequestsFinishedAction => ({
    type: 'RECEIVE_VACATION_REQUESTS_FINISHED',
    vacationId,
    requests,
});

// RECEIVE_ERROR

export const receiveVacationsFinishedWithError = (): Action<VacationActionTypes> => ({
    type: 'RECEIVE_FINISHED_WITH_ERROR',
});

export type VacationAction =
    ReceiveVacationsStartedAction &
    ReceiveVacationsFinishedAction &
    ReceiveVacationRequestsStartedAction &
    ReceiveVacationRequestsFinishedAction;
