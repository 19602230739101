import {Grid} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import AccountIcon from '@material-ui/icons/AccountBox';
import CalendarDateIcon from '@material-ui/icons/DateRangeOutlined';
import GroupIcon from '@material-ui/icons/Group';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import CalendarMonthIcon from '@material-ui/icons/TodayOutlined';
import React from 'react';
import {isBrowser, isMobile} from 'react-device-detect';
import '../assets/scss/sidenav.scss';
import {URLs} from '../consts';
import history from '../history';
import {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';


export interface SidenavStateToProps extends EmployeeComponentWrapperStateToProps {
}

export interface SidenavDispatchToProps extends EmployeeComponentWrapperDispatchToProps {
}

export type SidenavProps = SidenavStateToProps & SidenavDispatchToProps & {
    open: boolean;
    toggleSidenav?: () => void;
}

export interface SidenavState {
    iosDevice: boolean;
}

export default class Sidenav extends React.Component<SidenavProps, SidenavState> implements EmployeeComponentWrapper {
    public constructor(props: SidenavProps) {
        super(props);

        this.state = {
            iosDevice: /iphone|ipod|ipad/i.test(navigator.userAgent),
        };
    }

    public componentDidMount(): void {
    }

    public toggleSidenav = (): void => {
        this.props.toggleSidenav && this.props.toggleSidenav();
    };

    public handleRedirect = (target: string): void => {
        this.toggleSidenav();

        history.push(target);
    };

    public render(): React.ReactElement {
        return (
            <React.Fragment>
                <Grid className="sidenav-wrapper" container wrap="nowrap">
                    <Drawer
                        open={this.props.open}
                        variant="temporary"
                        onBackdropClick={this.toggleSidenav}
                        PaperProps={{
                            style: {
                                boxShadow: 'none',
                                backgroundColor: '#3f51b5',
                                color: 'white',
                                top: '52px',
                                width: window.innerWidth < 567 ? '100%' : (isMobile ? '50%' : 'auto'),
                                maxWidth: '576px',
                                height: 'calc(100% - 52px)',
                            },
                        }}
                        BackdropProps={{
                            style: {
                                opacity: 0,
                            },
                        }}
                    >
                        <List disablePadding>
                            <Tooltip title={isBrowser ? 'Monthly Calendar' : ''} placement="right">
                                <ListItem
                                    className="sidenav-item"
                                    button
                                    selected={document.URL.includes(URLs.home)}
                                    onClick={(): void => this.handleRedirect(URLs.home)}
                                >
                                    <ListItemIcon className="sidenav-item-icon">
                                        <CalendarMonthIcon fontSize="small"/>
                                    </ListItemIcon>

                                    <ListItemText className="sidenav-item-text" disableTypography>
                                        Monthly Calendar
                                    </ListItemText>
                                </ListItem>
                            </Tooltip>
                        </List>

                        <List disablePadding>
                            <Tooltip title={isBrowser ? 'Annual Calendar' : ''} placement="right">
                                <ListItem
                                    className="sidenav-item"
                                    button
                                    selected={document.URL.includes(URLs.annual)}
                                    onClick={(): void => this.handleRedirect(URLs.annual)}
                                >
                                    <ListItemIcon className="sidenav-item-icon">
                                        <CalendarDateIcon fontSize="small"/>
                                    </ListItemIcon>

                                    <ListItemText className="sidenav-item-text" disableTypography>
                                        Annual Calendar
                                    </ListItemText>
                                </ListItem>
                            </Tooltip>

                        </List>

                        <List disablePadding>
                            <Tooltip title={isBrowser ? 'Groups' : ''} placement="right">
                                <ListItem
                                    className="sidenav-item"
                                    button
                                    selected={document.URL.includes(URLs.groups)}
                                    onClick={(): void => this.handleRedirect(URLs.groups)}
                                >
                                    <ListItemIcon className="sidenav-item-icon">
                                        <GroupIcon fontSize="small"/>
                                    </ListItemIcon>

                                    <ListItemText className="sidenav-item-text" disableTypography>
                                        Groups
                                    </ListItemText>
                                </ListItem>
                            </Tooltip>
                        </List>

                        <List disablePadding>
                            <Tooltip title={isBrowser ? 'Users' : ''} placement="right">
                                <ListItem
                                    className="sidenav-item"
                                    button
                                    selected={document.URL.includes(URLs.employees)}
                                    onClick={(): void => this.handleRedirect(URLs.employees)}
                                >
                                    <ListItemIcon className="sidenav-item-icon">
                                        <AccountIcon fontSize="small"/>
                                    </ListItemIcon>

                                    <ListItemText className="sidenav-item-text" disableTypography>
                                        Users
                                    </ListItemText>
                                </ListItem>
                            </Tooltip>
                        </List>

                        <List disablePadding>
                            <Tooltip title={isBrowser ? 'Logs' : ''} placement="right">
                                <ListItem
                                    className="sidenav-item"
                                    button
                                    selected={document.URL.includes('logs')}
                                    onClick={(): void => this.handleRedirect('logs')}
                                >
                                    <ListItemIcon className="sidenav-item-icon">
                                        <HistoryIcon fontSize="small"/>
                                    </ListItemIcon>

                                    <ListItemText className="sidenav-item-text" disableTypography>
                                        Logs
                                    </ListItemText>
                                </ListItem>
                            </Tooltip>
                        </List>

                        {
                            this.props.me && this.isAdmin(this.props.me.id) &&
                            <List disablePadding>
                                <Tooltip title={isBrowser ? 'Settings' : ''} placement="right">
                                    <ListItem
                                        className="sidenav-item"
                                        button
                                        selected={document.URL.includes('settings')}
                                        onClick={(): void => this.handleRedirect('settings')}
                                    >
                                        <ListItemIcon className="sidenav-item-icon">
                                            <SettingsIcon fontSize="small"/>
                                        </ListItemIcon>

                                        <ListItemText className="sidenav-item-text" disableTypography>
                                            Settings
                                        </ListItemText>
                                    </ListItem>
                                </Tooltip>
                            </List>
                        }
                    </Drawer>

                    <Grid container className="sidenav-children-wrapper">
                        {this.props.children}
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
