import {connect} from 'react-redux';
import ManageGroupEmployeesDialog, {
    ManageGroupEmployeesDialogDispatchToProps,
    ManageGroupEmployeesDialogStateToProps,
} from '../components/ManageGroupEmployeesDialog';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import {groupComponentWrapperMapDispatchToProps, groupComponentWrapperMapStateToProps} from '../models/Group';
import GroupService from '../services/Group';


const mapStateToProps = (state): ManageGroupEmployeesDialogStateToProps => ({
    employees: state.Employee.employees,
    ...groupComponentWrapperMapStateToProps(state),
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: ManageGroupEmployeesDialogDispatchToProps = {
    addGroupMember: GroupService.addMember,
    removeGroupMember: GroupService.removeMember,
    ...groupComponentWrapperMapDispatchToProps,
    ...employeeComponentWrapperMapDispatchToProps,
};


export default connect(mapStateToProps, mapDispatchToProps)(ManageGroupEmployeesDialog);
