import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Fade,
    Grid,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import React from 'react';
import {isBrowser, isMobile} from 'react-device-detect';
import history from '../history';
import Employee, {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group, {
    GroupComponentWrapper,
    GroupComponentWrapperDispatchToProps,
    GroupComponentWrapperStateToProps,
} from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';
import EmployeeAvatar from './EmployeeAvatar';


export interface ManageGroupEmployeesDialogStateToProps extends GroupComponentWrapperStateToProps, EmployeeComponentWrapperStateToProps {
}

export interface ManageGroupEmployeesDialogDispatchToProps extends GroupComponentWrapperDispatchToProps, EmployeeComponentWrapperDispatchToProps {
    addGroupMember: (group: Group, member: Employee) => void;
    removeGroupMember: (group: Group, member: Employee) => void;
}

export type ManageGroupEmployeesDialogProps =
    ManageGroupEmployeesDialogStateToProps &
    ManageGroupEmployeesDialogDispatchToProps &
    {
        open: boolean;
        group?: Group;
        onClose?: () => void;
    };

export interface ManageGroupEmployeesDialogState {
    openEmployeesPickerDialog: boolean;
    searchValue: string;
    inputValue: string;
    submitDialog: {
        title: string;
        open: boolean;
        onYesClicked: () => void;
        onNoClicked: () => void;
    };
}

export default class ManageGroupEmployeesDialog extends React.Component<ManageGroupEmployeesDialogProps, ManageGroupEmployeesDialogState> implements GroupComponentWrapper, EmployeeComponentWrapper {
    public constructor(props: ManageGroupEmployeesDialogProps) {
        super(props);

        this.state = {
            openEmployeesPickerDialog: false,
            searchValue: '',
            inputValue: '',
            submitDialog: {
                title: '',
                open: false,
                onYesClicked: null,
                onNoClicked: null,
            },
        };
    }

    public componentDidUpdate(prevProps: Readonly<ManageGroupEmployeesDialogProps>): void {
        if (this.props.group && this.props.group != prevProps.group) {
            this.props.receiveGroupMembers(this.props.group.id);
        }
    }

    public addMember = (employee: Employee): void => {
        this.props.addGroupMember(this.props.group, employee);
    };

    public removeMember = (employee: Employee): void => {
        this.props.removeGroupMember(this.props.group, employee);
    };

    public filterEmployees(): Employee[] {
        return this.props.employees.filter((employee: Employee): boolean => {
            const regExp = new RegExp(this.state.searchValue, 'gi');

            return !!`${employee.last_name} ${employee.first_name}`.match(regExp) &&
                (
                    !this.props.group ||
                    !this.getGroupMembers(this.props.group.id)
                        .some(
                            (member): boolean =>
                                member.id === employee.id || employee.id === this.props.group.lead.id,
                        )
                );
        });
    }

    public filterMembers(): Employee[] {
        return this.props.group && this.getGroupMembers(this.props.group.id).filter((employee: Employee): boolean => {
            const regExp = new RegExp(this.state.searchValue, 'gi');

            return !!`${employee.last_name} ${employee.first_name}`.match(regExp);
        });

    }

    public handleKeyDown = (event): void => {
        if (event.key === 'Enter') {
            this.setState({
                inputValue: event.target.value,
                searchValue: !event.target.value ? '' : this.state.inputValue,
            });
        }
    };

    public handleCloseButton = (): void => {
        this.setState({
            inputValue: '',
            searchValue: '',
        });

        this.props.onClose();
    };

    public render = (): React.ReactElement => {
        const onDesktop = window.innerWidth > 576;

        if (
            this.props.open &&
            this.props.group != undefined
        ) {
            const dataWasLoaded =
                this.props.employees != undefined;

            const employees = this.filterEmployees();
            const members = this.filterMembers();

            return (
                <Grid>
                    <Dialog
                        open={this.props.open}
                        onClose={this.props.onClose}
                        fullScreen
                    >
                        <AppBar position="static">
                            <Toolbar style={{minHeight: '52px'}}>
                                <IconButton
                                    color="inherit" aria-label="Close" edge="start" onClick={this.handleCloseButton}
                                >
                                    <CloseIcon/>
                                </IconButton>

                                <Typography variant="h6" color="inherit" style={{marginLeft: '15px', flex: '1'}}>
                                    {this.props.group && `${this.props.group.display_name}: Group Members`}
                                </Typography>
                            </Toolbar>
                        </AppBar>

                        <Toolbar className="toolbar">
                            <Grid item container direction="row" wrap="nowrap" className="search-container">
                                <TextField
                                    fullWidth
                                    type="search"
                                    placeholder="Search"
                                    inputProps={{
                                        maxLength: 25,
                                    }}
                                    onChange={(event): void => this.setState({
                                        inputValue: event.target.value,
                                        searchValue: !event.target.value ? '' : this.state.searchValue,
                                    })}
                                    onKeyDown={this.handleKeyDown}
                                    value={this.state.inputValue}
                                />

                                <Button
                                    type="button"
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    disableFocusRipple
                                    onClick={(): void => this.setState({
                                        searchValue: this.state.inputValue,
                                    })}
                                >
                                    Apply
                                </Button>
                            </Grid>
                        </Toolbar>

                        {
                            !dataWasLoaded ?
                                <Grid className="spinner">
                                    <Fade
                                        in={!dataWasLoaded}
                                        unmountOnExit
                                    >
                                        <CircularProgress
                                            color="inherit"
                                            size={80}
                                        />
                                    </Fade>
                                </Grid> :
                                <React.Fragment>
                                    <React.Fragment>
                                        <Toolbar>
                                            <Typography variant="h6" style={{display: 'block'}}>
                                                Group Members
                                            </Typography>
                                        </Toolbar>
                                        {
                                            members.length > 0 ?
                                                <Table>
                                                    <colgroup>
                                                        <col style={{width: '1%'}}/>
                                                        <col style={{width: '40%'}}/>
                                                        {onDesktop && <col style={{width: '40%'}}/>}
                                                        <col style={{width: '15%'}}/>
                                                        <col style={{width: '4%'}}/>
                                                    </colgroup>

                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={isMobile ? 'p-10' : ''}>Avatar</TableCell>
                                                            <TableCell className={isMobile ? 'p-10' : ''}>Full
                                                                Name</TableCell>
                                                            {
                                                                onDesktop &&
                                                                <TableCell>Email</TableCell>
                                                            }
                                                            <TableCell className={isMobile ? 'p-5' : ''}>Roles</TableCell>
                                                            <TableCell className={isMobile ? 'p-5' : ''}/>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {
                                                            members
                                                                .sort((a, b): number => {
                                                                    let aName = `${a.last_name} ${a.first_name}`;
                                                                    let bName = `${b.last_name} ${b.first_name}`;

                                                                    return aName.localeCompare(bName);
                                                                })
                                                                .map((employee): React.ReactElement =>
                                                                    (this.props.group && this.props.group.lead) && (
                                                                        <TableRow key={`employee-${employee.id}`}>
                                                                            <TableCell className={isMobile ? 'p-10' : ''}>
                                                                                <EmployeeAvatar
                                                                                    employee={employee}
                                                                                    onClick={(): void => history.push(`/profile-${employee.id}`)}
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        width: '26px',
                                                                                        height: '26px',
                                                                                        fontSize: '12px',
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell className={isMobile ? 'p-10' : ''}>
                                                                                <Link
                                                                                    component="button"
                                                                                    color="inherit"
                                                                                    underline="none"
                                                                                    variant="subtitle2"
                                                                                    onClick={
                                                                                        (): void => {
                                                                                            history.push(`/profile-${employee.id}`);
                                                                                        }
                                                                                    }
                                                                                    style={{
                                                                                        wordBreak: 'break-all',
                                                                                        textAlign: 'left',
                                                                                    }}
                                                                                >
                                                                                    {`${employee.last_name} ${employee.first_name}`}
                                                                                </Link>
                                                                            </TableCell>
                                                                            {
                                                                                onDesktop &&
                                                                                <TableCell>{employee.email}</TableCell>
                                                                            }
                                                                            <TableCell className={isMobile ? 'p-5' : ''}>{this.props.group.lead.id === employee.id ? 'Lead' : 'Member'}</TableCell>
                                                                            <TableCell padding="checkbox" className={isMobile ? 'p-5' : ''}>
                                                                                {employee.id !== this.props.group.lead.id && (
                                                                                    <Tooltip title={isBrowser ? 'Remove User' : ''}>
                                                                                        <IconButton
                                                                                            disableFocusRipple
                                                                                            style={{whiteSpace: 'nowrap'}}
                                                                                            onClick={(): void => this.setState({
                                                                                                submitDialog: {
                                                                                                    ...this.state.submitDialog,
                                                                                                    title: `Are you sure you want to remove ${employee.last_name} ${employee.first_name} from group?`,
                                                                                                    open: true,
                                                                                                    onYesClicked: (): void => {
                                                                                                        this.removeMember(employee);

                                                                                                        this.setState({
                                                                                                            submitDialog: {
                                                                                                                ...this.state.submitDialog,
                                                                                                                open: false,
                                                                                                            },
                                                                                                        });
                                                                                                    },
                                                                                                    onNoClicked: (): void => this.setState({
                                                                                                        submitDialog: {
                                                                                                            ...this.state.submitDialog,
                                                                                                            open: false,
                                                                                                        },
                                                                                                    }),
                                                                                                },
                                                                                            })}
                                                                                        >
                                                                                            <DeleteIcon/>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                )}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                    </TableBody>
                                                </Table> :
                                                <Box paddingLeft="32px">
                                                    <Typography variant="h6" color="textSecondary">
                                                        No matches found
                                                    </Typography>
                                                </Box>
                                        }
                                    </React.Fragment>

                                    <React.Fragment>
                                        <Toolbar>
                                            <Typography variant="h6">
                                                Other Users
                                            </Typography>
                                        </Toolbar>

                                        {
                                            employees.length > 0 ?
                                                <Table>
                                                    <colgroup>
                                                        <col style={{width: '1%'}}/>
                                                        <col style={onDesktop ? {width: '40%'} : {width: '55%'}}/>
                                                        {onDesktop && <col style={{width: '40%'}}/>}
                                                        {onDesktop && <col style={{width: '15%'}}/>}
                                                        <col style={{width: '4%'}}/>
                                                    </colgroup>

                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={isMobile ? 'p-10' : ''}>Avatar</TableCell>
                                                            <TableCell className={isMobile ? 'p-10' : ''}>Full
                                                                Name</TableCell>
                                                            {
                                                                onDesktop &&
                                                                <TableCell>Email</TableCell>
                                                            }
                                                            {
                                                                onDesktop &&
                                                                <TableCell className={isMobile ? 'p-10' : ''}/>
                                                            }
                                                            <TableCell className={isMobile ? 'p-10' : ''}/>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {
                                                            employees
                                                                .sort((a, b): number => {
                                                                    let aName = `${a.last_name} ${a.first_name}`;
                                                                    let bName = `${b.last_name} ${b.first_name}`;

                                                                    return aName.localeCompare(bName);
                                                                })
                                                                .map((employee): React.ReactElement => (
                                                                    <TableRow key={`employee-${employee.id}`}>
                                                                        <TableCell className={isMobile ? 'p-10' : ''}>
                                                                            <EmployeeAvatar
                                                                                employee={employee}
                                                                                onClick={(): void => history.push(`/profile-${employee.id}`)}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    width: '26px',
                                                                                    height: '26px',
                                                                                    fontSize: '12px',
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell className={isMobile ? 'p-5' : ''}>
                                                                            <Link
                                                                                component="button"
                                                                                color="inherit"
                                                                                underline="none"
                                                                                disabled={!this.isAdmin(this.props.me.id)}
                                                                                variant="subtitle2"
                                                                                onClick={
                                                                                    (): void => {
                                                                                        history.push(`/profile-${employee.id}`);
                                                                                    }
                                                                                }
                                                                                style={{
                                                                                    wordBreak: 'break-all',
                                                                                    textAlign: 'left',
                                                                                }}
                                                                            >
                                                                                {`${employee.last_name} ${employee.first_name}`}
                                                                            </Link>
                                                                        </TableCell>
                                                                        {
                                                                            onDesktop &&
                                                                            <TableCell>{employee.email}</TableCell>
                                                                        }
                                                                        {
                                                                            onDesktop &&
                                                                            <TableCell/>
                                                                        }
                                                                        <TableCell padding="checkbox" className={isMobile ? 'p-5' : ''}>
                                                                            <Tooltip title={isBrowser ? 'Add User' : ''}>
                                                                                <IconButton
                                                                                    disableFocusRipple
                                                                                    style={{whiteSpace: 'nowrap'}}
                                                                                    onClick={(): void => this.addMember(employee)}
                                                                                >
                                                                                    <GroupAddIcon/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))
                                                        }
                                                    </TableBody>
                                                </Table> :
                                                <Box paddingLeft="32px">
                                                    <Typography variant="h6" color="textSecondary">
                                                        No matches found
                                                    </Typography>
                                                </Box>
                                        }
                                    </React.Fragment>
                                </React.Fragment>
                        }
                    </Dialog>

                    <Dialog
                        open={this.state.submitDialog.open}
                        onClose={(): void => {
                            this.state.submitDialog.onNoClicked();
                        }}
                    >
                        <DialogTitle>{this.state.submitDialog.title}</DialogTitle>

                        <DialogActions>
                            <Button
                                fullWidth disableFocusRipple
                                onClick={(): void => this.state.submitDialog.onYesClicked()}
                                color="primary"
                            >
                                Yes
                            </Button>

                            <Button
                                fullWidth
                                disableFocusRipple
                                onClick={(): void => this.state.submitDialog.onNoClicked()}
                                color="primary"
                            >
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            );
        } else {
            return (<React.Fragment/>);
        }
    };

    public getGroupMembers = (groupId: number): Employee[] =>
        this.props.groupMembers[groupId] || [];

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
