import {
    IconButton,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import Draggable from 'react-draggable';
import '../assets/scss/home.scss';
import '../assets/scss/profile-dialog.scss';
import '../assets/scss/profile-page.scss';
import Employee, {
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group, {
    GroupComponentWrapper,
    GroupComponentWrapperDispatchToProps,
    GroupComponentWrapperStateToProps,
} from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';


export interface ProfileDialogStateToProps extends EmployeeComponentWrapperStateToProps, GroupComponentWrapperStateToProps {
}

export interface ProfileDialogDispatchToProps extends EmployeeComponentWrapperDispatchToProps, GroupComponentWrapperDispatchToProps {
    addEmployeeRole: (employee: Employee, role: Role) => void;
    removeEmployeeRole: (employee: Employee, role: Role) => void;
}

export type ProfileDialogProps = ProfileDialogStateToProps & ProfileDialogDispatchToProps & {
    open: boolean;
    employee: Employee;
    clickVacation?: boolean;
    draggableVacation?: () => void;
    onClose?: () => void;
    anchorElement?: HTMLElement;
};

export interface ProfileDialogState {
    backClick: boolean;
}

export default class ProfileDialog extends React.Component<ProfileDialogProps, ProfileDialogState> implements GroupComponentWrapper {
    public constructor(props: ProfileDialogProps) {
        super(props);

        this.state = {
            backClick: true,
        };
    }

    public componentDidUpdate(prevProps: ProfileDialogProps): void {
        if (this.props.employee && this.props.employee != prevProps.employee) {
            this.props.receiveEmployeeGroups(this.props.employee.id);
        }

        if (prevProps.clickVacation !== this.props.clickVacation && !this.props.clickVacation)
            this.setState({
                backClick: false,
            });
    }

    public render = (): React.ReactElement => {
        const isMobileScreen = window.innerWidth <= 576;
        if (this.props.open) {
            return (
                <Draggable
                    handle=".handle"
                    disabled={isMobileScreen}
                >
                    <Popover
                        classes={{paper: 'popover-profile'}}
                        open={this.props.open}
                        anchorEl={this.props.anchorElement && this.props.anchorElement}
                        anchorReference="anchorPosition"
                        anchorPosition={{top: 250, left: 1210}}
                        onBackdropClick={(): void => {
                            this.setState({backClick: true});
                            this.props.draggableVacation();
                        }}
                        disablePortal={this.props.clickVacation}
                    >

                        <Toolbar>
                            <IconButton color="inherit" aria-label="Close" onClick={this.props.onClose}>
                                <CloseIcon/>
                            </IconButton>

                            <Typography variant="h6" color="inherit" className="profile-title handle">
                                {`User Summary: ${this.props.employee.first_name} ${this.props.employee.last_name}`}
                            </Typography>
                        </Toolbar>

                        {
                            this.props.employee && !this.getEmployeeGroups(this.props.employee.id).length ?
                                <Typography className="groups-profile-dialog" variant="subtitle1">
                                    Not a member of any group
                                </Typography> :

                                <Table style={{overflow: 'auto'}}>
                                    <colgroup>
                                        <col/>
                                        <col/>
                                        <col/>
                                    </colgroup>

                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Group</TableCell>
                                            <TableCell>Group Lead</TableCell>
                                            <TableCell>Roles</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.groups && this.props.employee && this.props.groups
                                            .filter((group): boolean => group.lead.id === this.props.employee.id || this.getGroupMembers(group.id).some((employee) => this.props.employee.id === employee.id))
                                            .sort((a, b): number => a.display_name.localeCompare(b.display_name))
                                            .map((group): React.ReactElement => (
                                                <TableRow key={`group-${group.id}`}>
                                                    <TableCell>{group.display_name}</TableCell>
                                                    <TableCell>{`${group.lead.last_name} ${group.lead.first_name}`}</TableCell>
                                                    <TableCell>{group.lead.id === this.props.employee.id ? 'Lead' : 'Member'}</TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                        }
                    </Popover>
                </Draggable>
            );
        }
        return (<React.Fragment/>);
    };

    public getGroupMembers = (groupId: number): Employee[] =>
        this.props.groupMembers[groupId] || [];

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
