import {connect} from 'react-redux';
import VacationRequestsDialog, {
    VacationRequestsDialogDispatchToProps,
    VacationRequestsDialogStateToProps,
} from '../components/VacationRequestsDialog';
import {employeeComponentWrapperMapDispatchToProps, employeeComponentWrapperMapStateToProps} from '../models/Employee';
import {groupComponentWrapperMapDispatchToProps, groupComponentWrapperMapStateToProps} from '../models/Group';
import {vacationComponentWrapperMapDispatchToProps, vacationComponentWrapperMapStateToProps} from '../models/Vacation';
import AlertService from '../services/Alert';
import VacationService from '../services/Vacation';


const mapStateToProps = (state): VacationRequestsDialogStateToProps => ({
    groups: state.Group.groups,
    ...groupComponentWrapperMapStateToProps(state),
    ...vacationComponentWrapperMapStateToProps(state),
    ...employeeComponentWrapperMapStateToProps(state),
});

const mapDispatchToProps: VacationRequestsDialogDispatchToProps = {
    approveVacationRequest: VacationService.approveVacationRequest,
    rejectVacationRequest: VacationService.rejectVacationRequest,
    showAlert: AlertService.show,
    ...groupComponentWrapperMapDispatchToProps,
    ...vacationComponentWrapperMapDispatchToProps,
    ...employeeComponentWrapperMapDispatchToProps,
};

export default connect(mapStateToProps, mapDispatchToProps)(VacationRequestsDialog);
