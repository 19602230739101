import {Box, CircularProgress} from '@material-ui/core';
import React from 'react';
import Header from '../containers/Header';
import Sidenav from '../containers/Sidenav';
import Employee from '../models/Employee';
import AuthenticationService from '../services/Authentication';


export interface ContentWrapperStateToProps {
    me: Employee;
}

export interface ContentWrapperDispatchToProps {
    receiveMe: () => void;
    receiveEmployeeRoles: (employeeId: number) => void;
}

export interface ContentWrapperState {
    sidenav: {
        open: boolean;
    };
}

export type ContentWrapperProps = ContentWrapperStateToProps & ContentWrapperDispatchToProps;

export class ContentWrapper extends React.Component<ContentWrapperProps, ContentWrapperState> {
    public constructor(props) {
        super(props);

        this.state = {
            sidenav: {
                open: false,
            },
        };
    }

    public componentDidMount(): void {
        this.props.receiveMe();
    }

    public componentDidUpdate(prevProps: Readonly<ContentWrapperProps>): void {
        if (
            this.props.me &&
            this.props.me != prevProps.me
        ) {
            this.props.receiveEmployeeRoles(this.props.me.id);
        }
    }

    public toggleSidenav = (): void => {
        this.setState({
            sidenav: {
                open: !this.state.sidenav.open,
            },
        });
    };

    public render(): React.ReactElement {
        if (
            !this.props.me ||
            this.props.me.id.toString() != AuthenticationService.getEmployeeId()
        ) {
            return (
                <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress size={128}/>
                </Box>
            );
        }

        return (
            <React.Fragment>
                <Header onMenuClick={this.toggleSidenav} sidenavOpened={this.state.sidenav.open}/>

                <Sidenav open={this.state.sidenav.open} toggleSidenav={this.toggleSidenav}>
                    {this.props.children}
                </Sidenav>
            </React.Fragment>
        );
    }
}
