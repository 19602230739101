import {EmployeeStatusAction} from '../actions/EmployeeStatus';
import EmployeeStatus from '../models/EmployeeStatus';


export type EmployeeStatusReducerReceivingTarget =
    'EMPLOYEE_STATUSES';

export interface EmployeeStatusReducerState {
    employeeStatuses: EmployeeStatus[];
    receivingCounter: number;
}

const initialState: EmployeeStatusReducerState = {
    employeeStatuses: [],
    receivingCounter: 0,
};

const EmployeeStatusReducer = (state: EmployeeStatusReducerState = initialState, action: EmployeeStatusAction): EmployeeStatusReducerState => {
    switch (action.type) {
        case 'RECEIVE_EMPLOYEE_STATUSES_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_EMPLOYEE_STATUSES_FINISHED':
            state.employeeStatuses = action.employeeStatuses;
            state.receivingCounter--;
            break;
    }

    return {...state};
};

export default EmployeeStatusReducer;
