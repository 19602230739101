import {Action} from 'redux';
import Role from '../models/Role';


export type RoleActionTypes =
    'RECEIVE_ROLES_STARTED' |
    'RECEIVE_ROLES_FINISHED';

export const receiveRolesStarted = (): Action<RoleActionTypes> => ({
    type: 'RECEIVE_ROLES_STARTED',
});

export interface ReceiveRolesFinishedAction extends Action<RoleActionTypes> {
    roles: Role[];
}

export const receiveRolesFinished = (roles: Role[]): ReceiveRolesFinishedAction => ({
    type: 'RECEIVE_ROLES_FINISHED',
    roles,
});

export type RoleAction =
    ReceiveRolesFinishedAction;
