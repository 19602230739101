import {ShowAlertAction} from '../actions/Alert';
import Alert from '../models/Alert';


export interface AlertReducerState {
    alert?: Alert;
}

const initialState: AlertReducerState = {};

const AlertReducer = (state: AlertReducerState = initialState, action: ShowAlertAction): AlertReducerState => {
    switch (action.type) {
        case 'HIDE_ALERT':
            state.alert = undefined;
            break;
        case 'SHOW_ALERT': {
            state.alert = action.alert;
            break;
        }
    }

    return {...state};
};

export default AlertReducer;
