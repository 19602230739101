import {Dispatch} from 'redux';
import {addSystemNotification, clearSystemNotification} from '../actions/SystemNotification';


class SystemNotificationService {
    public static pushInfo = (systemNotificationText: string): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(addSystemNotification({
                id: (new Date()).getTime(),
                text: systemNotificationText,
                variant: 'default',
            }));
        };

    public static pushError = (systemNotificationText: string): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(addSystemNotification({
                id: (new Date()).getTime(),
                text: systemNotificationText,
                variant: 'error',
            }));
        };

    public static clear = (): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(clearSystemNotification());
        };
}

export default SystemNotificationService;
