import {Action} from 'redux';
import SystemNotification from '../models/SystemNotification';


export type SystemNotificationActionType =
    'ADD_SYSTEM_NOTIFICATION' |
    'CLEAR_SYSTEM_NOTIFICATION';

export interface AddSystemNotificationAction extends Action<SystemNotificationActionType> {
    systemNotification: SystemNotification;
}

export const addSystemNotification = (systemNotification: SystemNotification): AddSystemNotificationAction => ({
    type: 'ADD_SYSTEM_NOTIFICATION',
    systemNotification,
});

export const clearSystemNotification = (): Action<SystemNotificationActionType> => ({
    type: 'CLEAR_SYSTEM_NOTIFICATION',
});

export type SystemNotificationAction =
    AddSystemNotificationAction;
