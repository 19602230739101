import {AppBar, Fade, LinearProgress, Toolbar} from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import React, {Component} from 'react';
import '../assets/scss/header.scss';
import {Titles} from '../consts';
import NotificationsSidebar from '../containers/NotificationsSidebar';
import VacationDialog from '../containers/VacationDialog';
import history from '../history';
import Alert from '../models/Alert';
import Employee, {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationReason from '../models/VacationReason';
import VacationRequest from '../models/VacationRequest';
import EmployeeAvatar from './EmployeeAvatar';


export interface HeaderStateToProps extends EmployeeComponentWrapperStateToProps {
    vacationReasons: VacationReason[];
    isDataReceiving: boolean;
}

export interface HeaderDispatchToProps extends EmployeeComponentWrapperDispatchToProps {
    receiveVacationReasons?: () => void;
    pushSystemNotification?: (systemNotification: string) => void;
    showAlert?: (alert: Alert) => void;
    logout?: () => void;
}

export type HeaderProps = HeaderStateToProps & HeaderDispatchToProps & {
    sidenavOpened?: boolean;
    onMenuClick?: () => void;
};

export interface HeaderState {
    menuToggle: {
        toggled: boolean;
    };
    notificationsSidebar: {
        open: boolean;
    };
    vacationDialog: {
        open: boolean;
    };
}

export default class Header extends Component<HeaderProps, HeaderState> implements EmployeeComponentWrapper {
    public constructor(props: HeaderProps) {
        super(props);

        this.state = {
            menuToggle: {
                toggled: false,
            },
            vacationDialog: {
                open: false,
            },
            notificationsSidebar: {
                open: false,
            },
        };
    }

    public componentDidMount(): void {
        this.props.receiveVacationReasons();
        this.props.receiveEmployeeNotifications();
        this.props.receiveEmployeeVacationRequests();
    }

    public onLogOutClick = (): void => {
        this.props.logout();
    };

    public onAddVacationClick = (): void => {
        this.setState({
            vacationDialog: {
                open: true,
            },
        });
    };

    public onProfileClick = (): void => {
        history.push(`/profile-${this.props.me.id}`);
    };

    public onNotificationsClick = (): void => {
        this.setState({
            notificationsSidebar: {
                open: true,
            },
        });
    };

    public onCloseVacationDialog = (): void => {
        this.setState({
            vacationDialog: {
                open: false,
            },
        });
    };

    public onCloseNotificationsSidebar = (): void => {
        this.setState({
            notificationsSidebar: {
                open: false,
            },
        });
    };

    public render(): React.ReactElement {
        const vacationRequests = (this.props.me && this.getEmployeeVacationRequests()) || [];
        const notifications = (this.props.me && this.getEmployeeNotifications()) || [];
        const vacationReasons = this.props.vacationReasons || [];
        const authenticatedEmployee = this.props.me || {
            username: '',
            'first_name': '',
            'last_name': '',
        } as Employee;
        const onMenuClick = this.props.onMenuClick || (
            (): void => {
            }
        );
        const {notificationsSidebar, vacationDialog} = this.state;
        const totalNotificationsCount = vacationRequests.length + notifications.length;
        const defaultVacationReason = vacationReasons.find((reason): boolean => reason.short_name === 'vacation');
        const location = new URL(window.location.href);
        const title = (location && location.pathname && location.pathname.includes('profile')) ? Titles['/profile'] : Titles[location.pathname];

        return (
            <React.Fragment>
                <AppBar position="static">
                    <Toolbar style={{minHeight: '52px'}}>
                        <IconButton
                            disableFocusRipple
                            size="small"
                            color="inherit"
                            edge="start"
                            onClick={(): void => {
                                this.setState({
                                    menuToggle: {
                                        toggled: !this.state.menuToggle.toggled,
                                    },
                                });

                                onMenuClick();
                            }}
                            style={{marginRight: '16px', position: 'relative'}}
                        >
                            {
                                this.props.sidenavOpened ?
                                    <CloseIcon/> :
                                    <MenuIcon/>
                            }
                        </IconButton>

                        <Typography variant="h6" style={{fontSize: '18px'}}>
                            SOM{(title) ? `. ${title}` : ''}
                        </Typography>

                        <div style={{flexGrow: 1}}/>

                        <IconButton
                            disableFocusRipple
                            size="small"
                            color="inherit"
                            onClick={this.onAddVacationClick}
                            style={{marginRight: '16px'}}
                        >
                            <AddIcon/>
                        </IconButton>

                        <IconButton
                            disableFocusRipple
                            size="small"
                            color="inherit"
                            onClick={this.onNotificationsClick}
                            style={{marginRight: '16px'}}
                        >
                            <Badge badgeContent={totalNotificationsCount} color="secondary" style={{fontSize: '8px'}}>
                                <NotificationsIcon/>
                            </Badge>
                        </IconButton>

                        <IconButton
                            disableFocusRipple
                            size="small"
                            color="inherit"
                            onClick={this.onProfileClick}
                            style={{marginRight: '16px'}}
                        >
                            <EmployeeAvatar
                                employee={this.props.me}
                                style={{width: '24px', height: '24px', fontSize: '12px'}}
                            />
                        </IconButton>

                        <IconButton
                            disableFocusRipple
                            edge="end"
                            size="small"
                            color="inherit"
                            onClick={this.onLogOutClick}
                        >
                            <ExitToAppIcon/>
                        </IconButton>

                        <Fade in={this.props.isDataReceiving} timeout={500} unmountOnExit>
                            <LinearProgress
                                style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: '3px'}}
                            />
                        </Fade>
                    </Toolbar>
                </AppBar>

                <NotificationsSidebar
                    open={notificationsSidebar.open}
                    onClose={this.onCloseNotificationsSidebar}
                />

                {
                    authenticatedEmployee &&
                    defaultVacationReason &&
                    <VacationDialog
                        open={vacationDialog.open}
                        onClose={this.onCloseVacationDialog}
                    />
                }
            </React.Fragment>
        );
    }

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
