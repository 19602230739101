import axios from 'axios';
import {Dispatch} from 'redux';
import {receiveSettingsFinished, receiveSettingsStarted} from '../actions/Settings';
import Settings, {SettingsObj} from '../models/Settings';
import Utils from '../utils/Utils';
import AuthenticationService from './Authentication';
import SystemNotificationService from './SystemNotification';


class SettingsService {
    public static receive = (): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveSettingsStarted());

            axios.get(Utils.generateApiUrl('settings'), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response): void => {
                    dispatch(receiveSettingsFinished(response.data as Settings));
                })
                .catch(Utils.handleError);
        };

    public static update = (settings: SettingsObj): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            axios.post(Utils.generateApiUrl('settings'), {
                ...settings,
            }, {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((): void => {
                    SystemNotificationService.pushInfo('Settings was successfully updated')(dispatch);
                })
                .catch(Utils.handleError);
        };
}

export default SettingsService;
