import axios from 'axios';
import {Dispatch} from 'redux';
import {receiveVacationStatusesFinished, receiveVacationStatusesStarted} from '../actions/VacationStatus';
import VacationStatus from '../models/VacationStatus';
import Utils from '../utils/Utils';
import AuthenticationService from './Authentication';


class VacationStatusService {
    public static receive = (): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveVacationStatusesStarted());

            axios.get(Utils.generateApiUrl('vacation-statuses'), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response): void => {
                    dispatch(receiveVacationStatusesFinished(response.data as VacationStatus[]));
                })
                .catch(Utils.handleError);
        };
}

export default VacationStatusService;
