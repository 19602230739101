import axios from 'axios';
import {Dispatch} from 'redux';
import {receiveVacationReasonsFinished, receiveVacationReasonsStarted} from '../actions/VacationReason';
import VacationReason from '../models/VacationReason';
import Utils from '../utils/Utils';
import AuthenticationService from './Authentication';


class VacationReasonService {
    public static receive = (): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveVacationReasonsStarted());

            axios.get(Utils.generateApiUrl('vacation-reasons'), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response): void => {
                    dispatch(receiveVacationReasonsFinished(response.data as VacationReason[]));
                })
                .catch(Utils.handleError);
        };
}

export default VacationReasonService;
