import axios from 'axios';
import {Dispatch} from 'redux';
import {receiveGroupTypesFinished, receiveGroupTypesStarted} from '../actions/GroupType';
import GroupType from '../models/GroupType';
import Utils from '../utils/Utils';
import AuthenticationService from './Authentication';


class GroupTypeService {
    public static receive = (): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveGroupTypesStarted());

            axios.get(Utils.generateApiUrl('group-types'), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response): void => {
                    dispatch(receiveGroupTypesFinished(response.data as GroupType[]));
                })
                .catch(Utils.handleError);
        };
}

export default GroupTypeService;
