import axios from 'axios';
import {Dispatch} from 'redux';
import {receiveEmployeeStatusesFinished, receiveEmployeeStatusesStarted} from '../actions/EmployeeStatus';
import EmployeeStatus from '../models/EmployeeStatus';
import Utils from '../utils/Utils';
import AuthenticationService from './Authentication';


class EmployeeStatusService {
    public static receive = (): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveEmployeeStatusesStarted());

            axios.get(Utils.generateApiUrl('employee-statuses'), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response): void => {
                    dispatch(receiveEmployeeStatusesFinished(response.data as EmployeeStatus[]));
                })
                .catch(Utils.handleError);
        };
}

export default EmployeeStatusService;
