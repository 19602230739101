import {GroupStatusAction} from '../actions/GroupStatus';
import GroupStatus from '../models/GroupStatus';


export type GroupStatusReducerReceivingTarget =
    'GROUP_STATUSES';

export interface GroupStatusReducerState {
    groupStatuses: GroupStatus[];
    receivingCounter: number;
}

const initialState: GroupStatusReducerState = {
    groupStatuses: [],
    receivingCounter: 0,
};

const GroupStatusReducer = (state: GroupStatusReducerState = initialState, action: GroupStatusAction): GroupStatusReducerState => {
    switch (action.type) {
        case 'RECEIVE_GROUP_STATUSES_STARTED':
            state.receivingCounter++;
            break;
        case 'RECEIVE_GROUP_STATUSES_FINISHED':
            state.groupStatuses = action.groupStatuses;
            state.receivingCounter--;
            break;
    }

    return {...state};
};

export default GroupStatusReducer;
