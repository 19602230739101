import {
    AppBar,
    BottomNavigation,
    BottomNavigationAction,
    Dialog,
    DialogContent,
    TableBody,
    TextField,
    Toolbar,
    Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as Icons from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import {BrowserView, isBrowser, isMobile, MobileView} from 'react-device-detect';
import '../assets/scss/vacation-requests-dialog.scss';
import Alert from '../models/Alert';
import Employee, {
    EmployeeComponentWrapper,
    EmployeeComponentWrapperDispatchToProps,
    EmployeeComponentWrapperStateToProps,
} from '../models/Employee';
import Group, {
    GroupComponentWrapper,
    GroupComponentWrapperDispatchToProps,
    GroupComponentWrapperStateToProps,
} from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation, {
    VacationComponentWrapper,
    VacationComponentWrapperDispatchToProps,
    VacationComponentWrapperStateToProps,
} from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';


export interface VacationRequestsDialogStateToProps extends GroupComponentWrapperStateToProps, VacationComponentWrapperStateToProps, EmployeeComponentWrapperStateToProps {
}


export interface VacationRequestsDialogDispatchToProps extends GroupComponentWrapperDispatchToProps, VacationComponentWrapperDispatchToProps, EmployeeComponentWrapperDispatchToProps {
    approveVacationRequest: (vacationRequest) => void;
    rejectVacationRequest: (vacationRequest, reason) => void;
    showAlert: (alert: Alert) => void;
}

export type VacationRequestsDialogProps = VacationRequestsDialogStateToProps & VacationRequestsDialogDispatchToProps & {
    open: boolean;
    vacation: Vacation;
    onClose?: () => void;
};

export interface VacationRequestsDialogState {
    submitDialog: {
        open: boolean;
        title?: string;
        rejectReason?: {
            value: string;
            error?: string;
        };
        onNoClicked?: () => void;
        onYesClicked?: () => void;
    };
}

export default class VacationRequestsDialog extends React.Component<VacationRequestsDialogProps, VacationRequestsDialogState> implements GroupComponentWrapper, VacationComponentWrapper, EmployeeComponentWrapper {
    public constructor(props) {
        super(props);

        this.state = {
            submitDialog: {
                open: false,
                title: '',
            },
        };
    }

    public componentDidMount(): void {
        this.props.receiveGroups();

        if (this.props.me) {
            this.props.receiveEmployeeRoles(this.props.me.id);
        }

        if (this.props.vacation) {
            !this.props.groups.length && this.props.receiveGroups();
            this.props.receiveVacationRequests(this.props.vacation.id);
            this.props.receiveEmployeeGroups(this.props.vacation.employee.id);
        }
    }

    public componentDidUpdate(prevProps: Readonly<VacationRequestsDialogProps>): void {
        if (this.props.me && this.props.me != prevProps.me) {
            this.props.receiveEmployeeRoles(this.props.me.id);
        }

        if (this.props.vacation && this.props.vacation != prevProps.vacation) {
            !this.props.groups.length && this.props.receiveGroups();
            this.props.receiveVacationRequests(this.props.vacation.id);
            this.props.receiveEmployeeGroups(this.props.vacation.employee.id);
        }
    }

    public showSubmitDialog = (title: string, onNoClicked: () => void, onYesClicked: () => void, withRejectReason?: boolean): void => {
        this.setState({
            submitDialog: {
                open: true,
                rejectReason:
                    withRejectReason ?
                        {
                            value: '',
                        } :
                        undefined,
                title,
                onNoClicked,
                onYesClicked,
            },
        });
    };

    public hideSubmitDialog = (): void => {
        this.setState({
            submitDialog: {
                ...this.state.submitDialog,
                open: false,
            },
        });
    };

    public onUpdateVacationRequest = (vacationRequest: VacationRequest): void => {
        const isOwner =
            vacationRequest.group.lead.id === this.props.me.id;

        const isVacationRequestRejectedOrApproved = (
            vacationRequest.status.short_name === 'approved' ||
            vacationRequest.status.short_name === 'rejected'
        );

        if (
            (this.isAdmin(this.props.me.id) || isOwner) && isVacationRequestRejectedOrApproved
        ) {
            if (vacationRequest.status.short_name === 'approved') {
                this.showSubmitDialog(
                    `This action will lead to a change in the status of the request you selected. Do you agree to change the status of the request from "approved" to "rejected"?`,
                    (): void => {
                        this.hideSubmitDialog();
                    },
                    (): void => {
                        if (this.state.submitDialog.rejectReason.value.length > 0) {
                            this.props.rejectVacationRequest(vacationRequest, this.state.submitDialog.rejectReason.value);
                            this.hideSubmitDialog();
                        } else {
                            this.setState({
                                submitDialog: {
                                    ...this.state.submitDialog,
                                    rejectReason: {
                                        ...this.state.submitDialog.rejectReason,
                                        error: 'This field is required',
                                    },
                                },
                            });
                        }
                    },
                    true,
                );
            } else {
                this.showSubmitDialog(
                    `This action will lead to a change in the status of the request you selected. Do you agree to change the status of the request from "rejected" to "approved"?`,
                    (): void => {
                        this.hideSubmitDialog();
                    },
                    (): void => {
                        this.props.approveVacationRequest(vacationRequest);
                        this.hideSubmitDialog();
                    },
                );
            }
        }
    };

    public render(): React.ReactElement {
        if (
            !this.props.me ||
            !this.props.vacation
        ) {
            return <React.Fragment/>;
        }

        const vacationEmployeeGroups = this.getEmployeeGroups(this.props.vacation.employee.id);

        const hasEditableRequest = this.isAdmin(this.props.me.id) ||
            (
                this.props.vacation &&
                this.getVacationRequests(this.props.vacation.id).some(
                    (vacationRequest): boolean => vacationRequest.group.lead.id === this.props.me.id,
                )
            );

        if (
            this.props.open &&
            this.props.vacation != undefined &&
            this.props.groups != undefined
        ) {
            return (
                <React.Fragment>
                    <Dialog
                        open={this.props.open}
                        maxWidth={false}
                        onClose={this.props.onClose}
                        fullScreen={isMobile}
                    >
                        <BrowserView>
                            <DialogTitle>{this.props.vacation.reason.display_name} Status</DialogTitle>
                        </BrowserView>

                        <MobileView>
                            <AppBar position="static">
                                <Toolbar style={{minHeight: '52px'}}>
                                    <Typography variant="h6" style={{marginLeft: '16px'}}>
                                        Vacation Status
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        </MobileView>

                        <DialogContent style={{padding: 0}}>
                            <Table size={isBrowser ? 'medium' : 'small'}>
                                <TableHead>
                                    <TableRow>
                                        {isBrowser && <TableCell>Group</TableCell>}
                                        <TableCell>Lead</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Date</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        this.getVacationRequests(this.props.vacation.id)
                                            .sort((a, b): number =>
                                                b.group.vacation_priority - a.group.vacation_priority,
                                            )
                                            .filter((vacationRequest): boolean =>
                                                vacationRequest.group.lead.id != this.props.vacation.employee.id,
                                            )
                                            .map((vacationRequest): React.ReactElement => {
                                                const isVacationRequestRejectedOrApproved = (
                                                    vacationRequest.status.short_name === 'approved' ||
                                                    vacationRequest.status.short_name === 'rejected'
                                                );

                                                return (
                                                    <TableRow
                                                        key={`vacation-request-${vacationRequest.id}`}
                                                        style={
                                                            (
                                                                hasEditableRequest &&
                                                                isVacationRequestRejectedOrApproved
                                                            ) ?
                                                                {cursor: 'pointer'} :
                                                                {}
                                                        }
                                                        className={
                                                            (
                                                                hasEditableRequest &&
                                                                isVacationRequestRejectedOrApproved
                                                            ) ?
                                                                'clickable-row' :
                                                                ''
                                                        }
                                                        onClick={
                                                            (): void => {
                                                                if (
                                                                    hasEditableRequest &&
                                                                    isVacationRequestRejectedOrApproved
                                                                ) {
                                                                    this.onUpdateVacationRequest(vacationRequest);
                                                                }
                                                            }
                                                        }
                                                    >
                                                        {
                                                            isBrowser &&
                                                            <TableCell style={{wordBreak: 'break-all'}}>
                                                                {
                                                                    vacationEmployeeGroups.some(
                                                                        (vacationEmployeeGroup): boolean =>
                                                                            vacationEmployeeGroup.id != vacationRequest.group.id &&
                                                                            vacationEmployeeGroup.lead.id == vacationRequest.group.lead.id,
                                                                    ) ?
                                                                        '*' :
                                                                        vacationRequest.group.display_name
                                                                }
                                                            </TableCell>
                                                        }

                                                        <TableCell style={{wordBreak: 'break-all'}}>
                                                            {`${vacationRequest.group.lead.last_name} ${vacationRequest.group.lead.first_name}`}
                                                        </TableCell>

                                                        <TableCell>
                                                            {
                                                                vacationRequest.status.short_name.replace(
                                                                    /^\w/,
                                                                    (c): string => c.toUpperCase(),
                                                                )
                                                            }
                                                        </TableCell>

                                                        <TableCell>
                                                            {moment(vacationRequest.updated_at || vacationRequest.created_at).format('YYYY/MM/DD')}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                    }
                                </TableBody>
                            </Table>
                        </DialogContent>

                        <BrowserView>
                            <DialogActions>
                                <Button
                                    disableFocusRipple
                                    fullWidth
                                    color="primary"
                                    onClick={this.props.onClose}
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </BrowserView>
                        <MobileView>
                            <BottomNavigation showLabels>
                                <BottomNavigationAction
                                    label="Close"
                                    icon={<Icons.Cancel/>}
                                    focusRipple={false}
                                    onClick={this.props.onClose}
                                />
                            </BottomNavigation>
                        </MobileView>
                    </Dialog>

                    <Dialog
                        open={this.state.submitDialog.open}
                        fullScreen={isMobile}
                        onClose={(): void => {
                            this.state.submitDialog.onNoClicked();
                        }}
                    >
                        <DialogTitle>{this.state.submitDialog.title}</DialogTitle>

                        <DialogContent>
                            {
                                this.state.submitDialog.rejectReason &&
                                <TextField
                                    fullWidth
                                    error={!!this.state.submitDialog.rejectReason.error}
                                    helperText={this.state.submitDialog.rejectReason.error}
                                    label="Reason of Rejection"
                                    inputProps={{
                                        maxLength: 150,
                                    }}
                                    value={this.state.submitDialog.rejectReason.value}
                                    onChange={
                                        (event): void => {
                                            this.setState({
                                                submitDialog: {
                                                    ...this.state.submitDialog,
                                                    rejectReason: {
                                                        value: event.target.value,
                                                    },
                                                },
                                            });
                                        }
                                    }
                                />
                            }
                        </DialogContent>

                        <DialogActions>
                            <Button
                                fullWidth
                                disableFocusRipple
                                onClick={(): void => this.state.submitDialog.onYesClicked()}
                                color="primary"
                            >
                                Yes
                            </Button>

                            <Button
                                fullWidth
                                disableFocusRipple
                                onClick={(): void => this.state.submitDialog.onNoClicked()}
                                color="primary"
                            >
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            );
        } else {
            return (<React.Fragment/>);
        }
    }

    public getGroupMembers = (groupId: number): Employee[] =>
        this.props.groupMembers[groupId] || [];

    public getVacationRequests = (vacationId: number): VacationRequest[] =>
        this.props.vacationRequests[vacationId] || [];

    public getEmployeeRoles = (employeeId: number): Role[] =>
        this.props.employeeRoles[employeeId] || [];

    public getEmployeeNotifications = (): Notification[] =>
        this.props.employeeNotifications;

    public getEmployeeVacationRequests = (): VacationRequest[] =>
        this.props.employeeVacationRequests;

    public getEmployeeGroups = (employeeId: number): Group[] =>
        this.props.employeeGroups[employeeId] || [];

    public getEmployeeVacations = (employeeId: number): Vacation[] =>
        this.props.employeeVacations[employeeId] || [];

    public isAdmin = (employeeId: number): boolean =>
        this.getEmployeeRoles(employeeId).some(
            (role): boolean => role.short_name == 'administrator',
        );
}
