import axios, {AxiosResponse} from 'axios';
import moment from 'moment';
import {Dispatch} from 'redux';
import {
    receiveEmployeeGroupsFinished,
    receiveEmployeeGroupsStarted,
    receiveEmployeeNotificationsFinished,
    receiveEmployeeNotificationsStarted,
    receiveEmployeeRolesFinished,
    receiveEmployeeRolesStarted,
    receiveEmployeesFinished,
    receiveEmployeesFinishedWithError,
    receiveEmployeesStarted,
    receiveEmployeeVacationRequestsFinished,
    receiveEmployeeVacationRequestsStarted,
    receiveEmployeeVacationsFinished,
    receiveEmployeeVacationsStarted,
    receiveMeFinished,
    receiveMeStarted,
} from '../actions/Employee';
import {EmployeesFilterValues} from '../components/EmployeesFilter';
import Employee from '../models/Employee';
import Group from '../models/Group';
import Notification from '../models/Notification';
import Role from '../models/Role';
import Vacation from '../models/Vacation';
import VacationRequest from '../models/VacationRequest';
import Utils from '../utils/Utils';
import AuthenticationService from './Authentication';
import SystemNotificationService from './SystemNotification';


class EmployeeService {
    public static receive = (limit?: number, offset?: number, search?: string, ordering?: string, filter?: EmployeesFilterValues): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            let filterObj = {};

            if (filter) {
                if (filter.employeeSearchStr && filter.employeeSearchStr.length > 0) {
                    filterObj['search'] = filter.employeeSearchStr;
                }

                if (filter.employeeStatusesIds && filter.employeeStatusesIds.length > 0) {
                    filterObj['status__id__in'] =
                        filter.employeeStatusesIds
                            .join(',');
                }
            } else {
                filterObj = undefined;
            }

            dispatch(receiveEmployeesStarted(limit, offset, search, ordering, filter));

            axios.get(Utils.generateApiUrl('employees', {
                limit,
                offset,
                search,
                ordering,
                ...filterObj,
            }), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response: AxiosResponse): void => {
                    if (response.data.results) {
                        dispatch(receiveEmployeesFinished(response.data.results as Employee[], response.data.count));
                    } else {
                        const employees = response.data as Employee[];

                        dispatch(receiveEmployeesFinished(employees, employees.length));
                    }
                })
                .catch((error): void => {
                    dispatch(receiveEmployeesFinishedWithError());

                    Utils.handleError(error);
                });
        };

    public static addRole = (employee: Employee, role: Role): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            axios.post(Utils.generateApiUrl(`employees/${employee.id}/roles`), {
                id: role.id,
            }, {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((): void => {
                    SystemNotificationService.pushInfo(`${role.display_name} role was successfully added`)(dispatch);
                })
                .catch(Utils.handleError);
        };

    public static removeRole = (employee: Employee, role: Role): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            axios.delete(Utils.generateApiUrl(`employees/${employee.id}/roles/${role.id}`), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((): void => {
                    SystemNotificationService.pushInfo(`${role.display_name} role was successfully removed`)(dispatch);
                })
                .catch(Utils.handleError);
        };

    public static update = (employee: Employee, avatar?: File): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            const formData = new FormData();

            formData.append('first_name', employee.first_name);
            formData.append('last_name', employee.last_name);
            formData.append('mobile_phone', employee.mobile_phone);
            formData.append('email', employee.email);
            formData.append('employment_date', moment(employee.employment_date).format('YYYY-MM-DD'));
            formData.append('status', employee.status.id.toString());

            if (avatar != undefined) {
                formData.append('avatar', avatar);
            }

            axios.put(Utils.generateApiUrl(`employees/${employee.id}`),
                formData,
                {
                    headers: {
                        'Authorization': `Token ${AuthenticationService.getToken()}`,
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((): void => {
                    SystemNotificationService.pushInfo(`Profile was successfully updated`)(dispatch);
                })
                .catch(Utils.handleError);
        };

    public static receiveNotifications = (): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveEmployeeNotificationsStarted());

            axios.get(Utils.generateApiUrl(`me/notifications`), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response: AxiosResponse): void => {
                    dispatch(receiveEmployeeNotificationsFinished(
                        (response.data as Notification[]).filter((notification): boolean => !notification.seen)),
                    );
                })
                .catch((error): void => {
                    dispatch(receiveEmployeesFinishedWithError());

                    Utils.handleError(error);
                });
        };

    public static hideNotification = (notification: Notification): ((dispatch) => void) =>
        (): void => {
            axios.delete(Utils.generateApiUrl(`me/notifications/${notification.id}`), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .catch(Utils.handleError);
        };

    public static receiveVacationRequests = (): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveEmployeeVacationRequestsStarted());

            axios.get(Utils.generateApiUrl(`me/vacation-requests`), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response: AxiosResponse): void => {
                    dispatch(receiveEmployeeVacationRequestsFinished(response.data as VacationRequest[]));
                })
                .catch((error): void => {
                    dispatch(receiveEmployeesFinishedWithError());

                    Utils.handleError(error);
                });
        };

    public static receiveVacations = (employeeId: number): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveEmployeeVacationsStarted(employeeId));

            axios.get(Utils.generateApiUrl(`vacations`, {
                'employee': employeeId,
            }), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response: AxiosResponse): void => {
                    dispatch(receiveEmployeeVacationsFinished(employeeId, response.data as Vacation[]));
                })
                .catch((error): void => {
                    dispatch(receiveEmployeesFinishedWithError());

                    Utils.handleError(error);
                });
        };

    public static receiveRoles = (employeeId: number): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveEmployeeRolesStarted(employeeId));

            axios.get(Utils.generateApiUrl(`employees/${employeeId}/roles`), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response: AxiosResponse): void => {
                    dispatch(receiveEmployeeRolesFinished(employeeId, response.data as Role[]));
                })
                .catch((error): void => {
                    dispatch(receiveEmployeesFinishedWithError());

                    Utils.handleError(error);
                });
        };

    public static receiveGroups = (employeeId: number): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveEmployeeGroupsStarted(employeeId));

            axios.get(Utils.generateApiUrl(`employees/${employeeId}/groups`), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response: AxiosResponse): void => {
                    dispatch(receiveEmployeeGroupsFinished(employeeId, response.data as Group[]));
                })
                .catch((error): void => {
                    dispatch(receiveEmployeesFinishedWithError());

                    Utils.handleError(error);
                });
        };

    public static receiveMe = (): ((dispatch) => void) =>
        (dispatch: Dispatch): void => {
            dispatch(receiveMeStarted());

            axios.get(Utils.generateApiUrl('me'), {
                headers: {
                    'Authorization': `Token ${AuthenticationService.getToken()}`,
                },
            })
                .then((response: AxiosResponse): void => {
                    dispatch(receiveMeFinished(response.data as Employee));
                })
                .catch((error): void => {
                    dispatch(receiveEmployeesFinishedWithError());

                    Utils.handleError(error);
                });
        };
}

export default EmployeeService;
