import {Action} from 'redux';
import VacationReason from '../models/VacationReason';


export type VacationReasonActionTypes =
    'RECEIVE_VACATION_REASONS_STARTED' |
    'RECEIVE_VACATION_REASONS_FINISHED';

export const receiveVacationReasonsStarted = (): Action<VacationReasonActionTypes> => ({
    type: 'RECEIVE_VACATION_REASONS_STARTED',
});

export interface ReceiveVacationReasonsFinishedAction extends Action<VacationReasonActionTypes> {
    vacationReasons: VacationReason[];
}

export const receiveVacationReasonsFinished = (vacationReasons: VacationReason[]): ReceiveVacationReasonsFinishedAction => ({
    type: 'RECEIVE_VACATION_REASONS_FINISHED',
    vacationReasons,
});

export type VacationReasonAction =
    ReceiveVacationReasonsFinishedAction;
