export const URLs = {
    home: '/home',
    annual: '/annual',
    groups: '/groups',
    employees: '/users',
    logs: '/logs',
};

export const Titles = {
    '/home': 'Monthly Calendar',
    '/annual': 'Annual Calendar',
    '/groups': 'Groups',
    '/users': 'Users',
    '/logs': 'Logs',
    '/settings': 'Settings',
    '/profile': 'Profile',
};
