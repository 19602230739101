import {applyMiddleware, combineReducers, createStore} from 'redux';
import LoggerMiddleware from 'redux-logger';
import ThunkMiddleware from 'redux-thunk';
import AlertReducer from './reducers/Alert';
import EmployeeReducer from './reducers/Employee';
import EmployeeStatusReducer from './reducers/EmployeeStatus';
import GroupReducer from './reducers/Group';
import GroupStatusReducer from './reducers/GroupStatus';
import GroupTypeReducer from './reducers/GroupType';
import LogReducer from './reducers/Log';
import LogLevelReducer from './reducers/LogLevel';
import RoleReducer from './reducers/Role';
import SettingsReducer from './reducers/Settings';
import SystemNotificationReducer from './reducers/SystemNotification';
import VacationReducer from './reducers/Vacation';
import VacationReasonReducer from './reducers/VacationReason';
import VacationStatusReducer from './reducers/VacationStatus';


const rootReducer = combineReducers(
    {
        Group: GroupReducer,
        Vacation: VacationReducer,
        VacationReason: VacationReasonReducer,
        VacationStatus: VacationStatusReducer,
        Employee: EmployeeReducer,
        EmployeeStatus: EmployeeStatusReducer,
        GroupType: GroupTypeReducer,
        GroupStatus: GroupStatusReducer,
        Role: RoleReducer,
        SystemNotification: SystemNotificationReducer,
        LogLevel: LogLevelReducer,
        Log: LogReducer,
        Alert: AlertReducer,
        Settings: SettingsReducer,
    },
);

const store = createStore(
    rootReducer,
    (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
        applyMiddleware(
            ThunkMiddleware,
            LoggerMiddleware,
        ) :
        applyMiddleware(
            ThunkMiddleware,
        ),
);

export default store;
